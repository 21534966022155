var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"pa-0",attrs:{"color":"primary"}},[_c('v-fade-transition',[_c('Message',{staticClass:"no-print mb-3",attrs:{"message":_vm.message}})],1),_vm._v(" "),_c('v-expand-transition',[_c('v-row',{class:_vm.message.status !== '' ? 'mt-2' : 'mt-0',attrs:{"dense":""}},[(
            _vm.id &&
            _vm.supplier_employee !== null &&
            (_vm.inPage === false ||
              (_vm.inPage === true && _vm.actions.editDialog === false))
          )?_c('v-col',{staticClass:"col-pdf col-12",attrs:{"md":_vm.inPage ? 12 : 6}},[_c('v-card',[_c('FullInformationCard',{attrs:{"item":_vm.supplier_employee,"title-only":(_vm.inPage === true || _vm.$vuetify.breakpoint.smAndDown) &&
                _vm.actions.editDialog === true},scopedSlots:_vm._u([{key:"editDelete",fn:function(){return [(
                    _vm.closeView !== true &&
                    _vm.newRecord !== true &&
                    _vm.id &&
                    _vm.supplier_employee !== null &&
                    _vm.$auth.user.permissions[_vm.entity] &&
                    (_vm.$auth.user.permissions[_vm.entity].edit ||
                      _vm.$auth.user.permissions[_vm.entity].delete) &&
                    (_vm.$auth.user.permissions[_vm.entity].edit === true ||
                      _vm.$auth.user.permissions[_vm.entity].delete === true)
                  )?_c('v-card',{staticClass:"pa-0",attrs:{"flat":""}},[_c('EditDeleteButtons',_vm._b({attrs:{"entity":_vm.entity,"deletable":_vm.deletable,"delete-items":_vm.deleteItems}},'EditDeleteButtons',_vm.actions,false,true))],1):_vm._e()]},proxy:true}],null,false,3417763807)})],1),_vm._v(" "),(
              _vm.supplier_employee !== null &&
              _vm.closeView !== true &&
              _vm.newRecord !== true &&
              _vm.inPage === false &&
              Object.keys(_vm.panelItems).length !== 0 &&
              _vm.actions.editDialog === false
            )?_c('v-select',{staticClass:"mt-3",attrs:{"items":Object.keys(_vm.panelItems).map((key) => ({
                entity: key,
                label: _vm.panelItems[key],
              })),"item-text":"label","item-value":"entity","label":"Choose View","outlined":"","standalone":"","hide-details":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.actions.editDialog && (_vm.id === null || _vm.inPage === true))?_c('v-col',{staticClass:"col-pdf col-12",attrs:{"md":_vm.inPage || _vm.id === null ? 12 : 6}},[_c('v-fade-transition',[_c('SupplierEmployeeForm',{attrs:{"id":_vm.id,"parent-key":_vm.parentKey,"parent-value":_vm.parentValue,"dialog":_vm.actions.editDialog,"close-view":_vm.closeView,"new-record":_vm.newRecord,"in-page":_vm.inPage},on:{"update:id":function($event){_vm.id=$event},"update:dialog":function($event){return _vm.$set(_vm.actions, "editDialog", $event)},"update:closeView":function($event){_vm.closeView=$event},"update:close-view":function($event){_vm.closeView=$event},"update:newRecord":function($event){_vm.newRecord=$event},"update:new-record":function($event){_vm.newRecord=$event}}})],1)],1):(_vm.actions.editDialog)?_c('v-col',{staticClass:"col-pdf col-12",attrs:{"md":_vm.inPage ? 12 : 6}},[_c('v-card',[_c('InPageContent',{attrs:{"dialog":true,"form-type":"edit"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('common.edit'))+" ")]},proxy:true}])},[_vm._v(" "),_c('v-fade-transition',[_c('SupplierEmployeeForm',{attrs:{"id":_vm.id,"parent-key":_vm.parentKey,"parent-value":_vm.parentValue,"dialog":_vm.actions.editDialog,"close-view":_vm.closeView,"new-record":_vm.newRecord,"in-page":_vm.inPage},on:{"update:dialog":function($event){return _vm.$set(_vm.actions, "editDialog", $event)},"update:closeView":function($event){_vm.closeView=$event},"update:close-view":function($event){_vm.closeView=$event},"update:newRecord":function($event){_vm.newRecord=$event},"update:new-record":function($event){_vm.newRecord=$event}}})],1)],1)],1)],1):_vm._e(),_vm._v(" "),(
            _vm.supplier_employee &&
            _vm.supplier_employee.payment_requests &&
            _vm.panel === 'payment_requests' &&
            !_vm.actions.editDialog
          )?_c('v-col',{ref:"payment_requests.panel",staticClass:"col-pdf col-12",attrs:{"md":_vm.inPage ? 12 : 6}},[_c('v-card',{attrs:{"color":"primary"}},[_c('InPageContent',{attrs:{"dialog":true,"form-type":"view","borderless":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n                "+_vm._s(_vm.panelItems.payment_requests)+"\n              ")]},proxy:true}],null,false,800099113)},[_vm._v(" "),_c('v-card',{staticClass:"mt-2 transparent",attrs:{"flat":""}},[_c('PaymentRequestTable',{attrs:{"items":_vm.supplier_employee.payment_requests,"mini":true,"parent-key":"supplier_employee_id","parent-value":_vm.id},scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c('nuxt-child')]}}],null,false,2254000825)})],1)],1)],1)],1):_vm._e()],1)],1)],1),_vm._v(" "),(_vm.actions.deleteDialog === true)?_c('Dialog',{attrs:{"dialog":_vm.actions.deleteDialog,"title":`${_vm.$tc('entity.' + _vm.entity, 1)}`,"name":`delete-${_vm.id}`,"form-type":"delete","max-width":"600px"},on:{"update:dialog":function($event){return _vm.$set(_vm.actions, "deleteDialog", $event)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.$tc('entity.' + _vm.entity, 1))+"\n    ")]},proxy:true}],null,false,211198554)},[_vm._v(" "),_c('ConfirmDeleteForm',{attrs:{"id":_vm.id,"entity-name":_vm.entity,"dialog":_vm.actions.deleteDialog},on:{"update:dialog":function($event){return _vm.$set(_vm.actions, "deleteDialog", $event)}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }