<template>
  <v-sheet height="100%" color="grey lighten-3">
    <!-- <v-toolbar flat dark dense color="indigo lighten-1">
      <v-toolbar-title class="overflow-x-hidden">
        <v-breadcrumbs large divider=">"></v-breadcrumbs>
      </v-toolbar-title>
    </v-toolbar> -->
    <slot name="sync" />
    <v-container
      :class="
        $vuetify.breakpoint.xsOnly
          ? 'pa-0 d-block  remove-max-width'
          : 'pa-6 d-block  remove-max-width'
      "
    >
      <slot />
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: 'Content',

  /* props: {
    breadcrumbItems: {
      type: Array,
      required: true,
    },
  }, */
};
</script>
