import { Model } from '@vuex-orm/core';
import PaymentRequestFile from './payment_request_file';
import DocumentType from './document_type';
import Step from './step';

export default class PaymentRequestRequiredAttachment extends Model {
  static entity = 'payment_request_required_attachment';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      document_type_id: this.number(null).nullable(),
      step_id: this.number(null).nullable(),
      attachment_number: this.number(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      document_type: this.belongsTo(DocumentType, 'document_type_id'),
      payment_request_files: this.hasMany(
        PaymentRequestFile,
        'payment_request_required_attachment_id'
      ),
      step: this.belongsTo(Step, 'step_id'),
    };
  }
}
