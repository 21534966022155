import { Model } from '@vuex-orm/core';
import SupplierBankAccount from './supplier_bank_account';

export default class Bank extends Model {
  static entity = 'bank';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      name: this.string(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      supplier_bank_accounts: this.hasMany(SupplierBankAccount, 'bank_id'),
    };
  }
}
