import { Model } from '@vuex-orm/core';
import Supplier from './supplier';
import Address from './address';
import Company from './company';

export default class Branch extends Model {
  static entity = 'branch';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      company_id: this.number(null).nullable(),
      name: this.string(null).nullable(),
      address_line_0: this.string(null).nullable(),
      address_id: this.number(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      address: this.belongsTo(Address, 'address_id'),
      supplier: this.hasOne(Supplier, 'branch_id'),
      company: this.belongsTo(Company, 'company_id'),
    };
  }

  get description() {
    return `${this.company ? this.company.name : ''} (${this.name})`;
  }
}
