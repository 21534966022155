import { Model } from '@vuex-orm/core';
import PaymentRequestFile from './payment_request_file';
import PersonFile from './person_file';

export default class File extends Model {
  static entity = 'file';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      name: this.string(null).nullable(),
      description: this.string(null).nullable(),
      path: this.string(null).nullable(),
      filename: this.string(null).nullable(),
      file_extension: this.string(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      payment_request_files: this.hasMany(PaymentRequestFile, 'file_id'),
      person_files: this.hasMany(PersonFile, 'file_id'),
    };
  }

  get filePath() {
    return `${this.path}${this.filename}`;
  }
}
