import { Model } from '@vuex-orm/core';
import Branch from './branch';
import Company from './company';
import AddressType from './address_type';
import Country from './country';

export default class Address extends Model {
  static entity = 'address';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      line_1: this.string(null).nullable(),
      line_2: this.string(null).nullable(),
      line_3: this.string(null).nullable(),
      city: this.string(null).nullable(),
      state_province_county: this.string(null).nullable(),
      postcode_zip: this.string(null).nullable(),
      country_id: this.number(null).nullable(),
      address_type_id: this.number(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      branches: this.hasMany(Branch, 'address_id'),
      companies: this.hasMany(Company, 'address_id'),
      address_type: this.belongsTo(AddressType, 'address_type_id'),
      country: this.belongsTo(Country, 'country_id'),
    };
  }

  get fullAddress() {
    let addressString = `${this.line_1}, \n`;

    if (this.line_2 !== '' && this.line_2 !== null) {
      addressString += `${this.line_2}, \n`;
    }
    if (this.line_3 !== '' && this.line_3 !== null) {
      addressString += `${this.line_3}, \n`;
    }
    if (this.city !== '' && this.city !== null) {
      addressString += `${this.city}, \n`;
    }
    addressString += `${this.state_province_county}, \n`;
    addressString += `${this.postcode_zip}`;

    return addressString;
  }

  get fullAddressLine() {
    let addressString = `${this.line_1} `;

    if (this.line_2 !== '' && this.line_2 !== null) {
      addressString += `${this.line_2} `;
    }
    if (this.line_3 !== '' && this.line_3 !== null) {
      addressString += `${this.line_3} `;
    }
    if (this.city !== '' && this.city !== null) {
      addressString += `${this.city} `;
    }
    addressString += `${this.state_province_county} `;
    addressString += `${this.postcode_zip}`;

    return addressString;
  }
}
