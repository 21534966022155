import { Model } from '@vuex-orm/core';
import Role from './role';
import UserRole from './user_role';
import Employee from './employee';

export default class User extends Model {
  static entity = 'user';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      employee_id: this.number(null).nullable(),
      username: this.string(null).nullable(),
      password_hash: this.string(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      roles: this.belongsToMany(Role, UserRole, 'user_id', 'role_id'),
      employee: this.belongsTo(Employee, 'employee_id'),
    };
  }
}
