<template>
  <!-- <v-card v-resize:initial="setTableHeight" flat color="transparent"> :height="tableHeight" -->
  <v-container flat color="transparent" class="pa-0">
    <v-row v-if="!mini" dense justify="start">
      <slot name="menu" />
      <v-col
        v-if="entity !== 'payment_request'"
        cols="6"
        md="4"
        lg="3"
        :class="dateRangeFilterActive ? 'd-none' : ''"
      >
        <v-card>
          <v-text-field
            v-model="search"
            dense
            :append-icon="$vuetify.breakpoint.smAndUp ? 'mdi-magnify' : ''"
            :label="$t('common.search')"
            color="accent"
            :disabled="form.fields.dateRange.editedValue.length === 2"
            flat
            hide-details
            single-line
            solo
            text
            clearable
          >
          </v-text-field>
        </v-card>
      </v-col>
      <v-col v-if="entity === 'payment_request'" :cols="6" :md="4">
        <CompanySelector
          v-bind.sync="form.fields.companyCode"
          :items="items"
          :editable="true"
        />
      </v-col>
      <v-col v-if="entity === 'payment_request'" :cols="6" :md="4">
        <DatePickerRange v-bind.sync="form.fields.dateRange" :editable="true" />
        <!-- <v-card>
          <v-select
            v-if="groups.length > 0"
            v-model="groupBy"
            :items="groups"
            return-object
            dense
            :label="$t('common.group_by')"
            color="accent"
            item-color="green"
            flat
            hide-details
            single-line
            solo
            text
            disable-lookup
          >
            <template #selection="{ attrs, item, selected: isSelected }">
              <v-chip
                v-if="groupBy.text !== null"
                v-bind="attrs"
                color="green"
                dark
                :input-value="isSelected"
                label
                small
              >
                <span class="pr-2">
                  {{ item.text }}
                </span>
                <v-icon small class="mr-1" @click.stop="resetGroupBy()">
                  mdi-close-circle
                </v-icon>
              </v-chip>
            </template>
            <template #item="{ item }">
              <v-chip color="green" dark label small>
                {{ item.text }}
              </v-chip>
            </template>
          </v-select>
        </v-card> -->
      </v-col>
      <v-spacer />
      <v-col
        v-if="
          entity === 'payment_request' &&
          (workflow === null || isAuthorizedGroup(1))
        "
        cols="12"
        md="4"
      >
        <GeneratePaymentRequestViewerReport
          :date-range="form.fields.dateRange.editedValue"
          :company-code="form.fields.companyCode.editedValue"
          :disabled="companyAndDateRangeFilterActive === false"
        />
      </v-col>
      <!-- <v-col
        v-if="
          entity === 'inventory_check' &&
          (workflow === null || isAuthorizedGroup(1))
        "
        cols="6"
        md="2"
        lg="3"
      >
        <v-card v-if="editable" block height="100%">
          <v-btn
            block
            text
            dark
            height="38px"
            color="success"
            aria-label="Initialize Stock Check"
            class="rounded-b-0"
            @click.native.stop
            @click="
              setAddForm();
              dialog.addStockCheck = true;
            "
          >
            <v-icon color="success darken-2" height="100%">mdi-plus</v-icon>
            <template v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xl">
              {{
                $t('button.new_entity', { entity: $tc(`entity.${entity}`, 1) })
              }}
            </template>
            <template v-else>
              {{ $t('button.new') }}
            </template>
          </v-btn>
        </v-card>
      </v-col>
      <v-col v-if="entity === 'part_request'" md="2" lg="3">
        <v-card v-if="editable" height="100%">
          <v-btn
            block
            text
            dark
            height="38px"
            color="success"
            aria-label="Create Part Request"
            class="rounded-b-0"
            @click.native.stop
            @click="
              setAddForm();
              dialog.addPartRequest = true;
            "
          >
            <v-icon color="success darken-2" height="100%">mdi-plus</v-icon>
            <template v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xl">
              {{
                $t('button.new_entity', { entity: $tc(`entity.${entity}`, 1) })
              }}
            </template>
            <template v-else>
              {{ $t('button.new') }}
            </template>
          </v-btn>
        </v-card>
      </v-col> -->
      <v-col
        v-if="
          entity !== 'maintenance_job' &&
          entity !== 'inventory_check' &&
          entity !== 'payment_request' &&
          entity !== '' &&
          (workflow === null || isAuthorizedGroup(1))
        "
        cols="6"
        md="2"
        lg="3"
      >
        <v-card>
          <!-- <v-btn
            v-if="
              entity === 'payment_request' &&
              editable &&
              $auth.user.permissions[entity] &&
              $auth.user.permissions[entity].create === true
            "
            block
            text
            dark
            height="38px"
            color="success"
            aria-label="New"
            class="rounded-b-0"
            nuxt-link
            :to="`${$route.fullPath}/new_payment_request`"
          >
            <v-icon color="success darken-2" height="100%">mdi-plus</v-icon>
            <template v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xl">
              {{
                $t('button.new_entity', { entity: $tc(`entity.${entity}`, 1) })
              }}
            </template>
            <template v-else>
              {{ $t('button.new') }}
            </template>
          </v-btn> -->
          <v-btn
            v-if="
              editable &&
              $auth.user.permissions[entity] &&
              $auth.user.permissions[entity].create === true
            "
            block
            text
            dark
            height="38px"
            color="success"
            aria-label="New"
            class="rounded-b-0"
            @click="
              setCreateForm();
              dialog.create = true;
            "
          >
            <v-icon color="success darken-2" height="100%">mdi-plus</v-icon>
            <template v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xl">
              {{
                $t('button.new_entity', { entity: $tc(`entity.${entity}`, 1) })
              }}
            </template>
            <template v-else>
              {{ $t('button.new') }}
            </template>
          </v-btn>
          <!-- <v-btn
            v-else
            block
            text
            disabled
            color="success"
            aria-label="New"
            class="rounded-b-0"
          >
            <v-icon color="success darken-2" height="100%">mdi-plus</v-icon>
            {{ $t('button.add_entity', { entity: $t('entity.payment_request', 1) }) }}
          </v-btn> -->
        </v-card>
      </v-col>
    </v-row>
    <v-row dense height="100%">
      <v-col cols="12">
        <div id="table">
          <v-card
            flat
            :outlined="!mini"
            height="100%"
            :class="mini ? 'rounded-t-0' : ''"
          >
            <v-data-table
              v-model="selected"
              :show-select="showSelect"
              :headers="mini ? headers.small : headers.full"
              :items="items"
              item-key="id"
              :items-per-page="itemsPerPage"
              :page.sync="page"
              :search="search"
              :custom-filter="
                companyAndDateRangeFilterActive === true
                  ? filterCompanyAndDateRange
                  : companyFilterActive === true
                  ? filterCompany
                  : dateRangeFilterActive === true
                  ? filterDateRange
                  : defaultFilter
              "
              :group-by="groupBy.value"
              group-key="gender.id"
              hide-default-footer
              :hide-default-header="mini"
              mobile-breakpoint="280"
              @page-count="pageCount = $event"
              @click:row="viewItem"
            >
              <template
                #[`group.header`]="{
                  group,
                  items: groupItems,
                  isOpen,
                  toggle,
                  remove,
                }"
              >
                <td colspan="12">
                  <v-btn icon @click="toggle">
                    <v-icon v-if="isOpen"> mdi-minus </v-icon>
                    <v-icon v-else> mdi-plus </v-icon>
                  </v-btn>
                  {{ groupBy.text }}: {{ group }} ({{ groupItems.length }}
                  {{ $tc('misc.record', groupItems.length) }})
                  <v-btn
                    icon
                    aria-label="Reset Grouping"
                    @click.stop="remove, resetGroupBy()"
                  >
                    <v-icon> mdi-close-circle </v-icon>
                  </v-btn>
                </td>
              </template>

              <template #[`item.files`]="{ item }">
                <v-chip small>
                  {{
                    item.payment_request_files
                      ? item.payment_request_files.length
                      : ''
                  }}
                  {{
                    ` ${$tc('entity.file', item.payment_request_files.length)}`
                  }}
                </v-chip>
              </template>

              <template #[`item.step_number`]="{ item }">
                {{ item.step }}
              </template>
              <template #[`item.status_name`]="{ item }">
                {{ item.status }}
              </template>
              <template #[`item.step`]="{ item }">
                <v-chip
                  v-if="item.step"
                  small
                  :dark="!item.step?.is_final"
                  :color="
                    item.step.step === 2
                      ? 'red darken-4'
                      : item.step.step === 3
                      ? 'deep-orange darken-4'
                      : item.step.step === 4
                      ? 'brown'
                      : item.step.step === 5
                      ? 'purple darken-4'
                      : item.step.step === 6
                      ? 'indigo darken-4'
                      : item.step.step === 7
                      ? 'blue darken-4'
                      : 'success darken-2'
                  "
                  :outlined="!item.step?.is_final"
                  class="d-inline-block mb-2 mt-1"
                >
                  <template
                    v-if="item.payment_complete && item.step.step === 8"
                  >
                    <v-icon left small>mdi-checkbox-marked-circle</v-icon>
                    {{ $t('data_label.payment_complete') }}
                  </template>
                  <template v-else>
                    <v-icon left small>
                      mdi-circle-slice-{{ item.step.step }}
                    </v-icon>
                    <b>
                      {{ $t('data_label.step') }}
                      {{ item.step.step }}:&nbsp;
                    </b>
                    {{ item.step.status }}
                  </template>
                </v-chip>
              </template>

              <template #[`item.document_status`]="{ item }">
                <tr>
                  <td class="pt-2 pb-1">
                    <v-card flat class="d-inline-block pa-1 ma-0">
                      <h3 class="d-inline">
                        {{ item.document_code }}
                      </h3>
                    </v-card>
                    <v-chip
                      v-if="item.status"
                      small
                      :dark="item.status.id !== 2 ? true : false"
                      :color="
                        item.status.id === 1
                          ? 'error darken-2'
                          : item.status.id === 2
                          ? ''
                          : item.status.id === 3
                          ? 'accent'
                          : item.status.id === 4
                          ? 'yellow darken-4'
                          : item.status.id === 5
                          ? 'indigo darken-4'
                          : 'success'
                      "
                      :outlined="item.status.id === 2 ? true : false"
                      class="d-inline-block my-1"
                    >
                      <template v-if="item.completion_date">
                        <v-icon v-if="item.status_id === 8" left small
                          >mdi-checkbox-marked-circle</v-icon
                        >
                        <v-icon v-else left small>mdi-cancel</v-icon>
                        {{ $d(new Date(item.completion_date), 'short', 'th') }}
                      </template>
                      <template v-else
                        ><v-icon left small
                          >mdi-circle-slice-{{ item.step }}</v-icon
                        >
                        {{ item.status.name }}
                      </template>
                    </v-chip>
                    <v-chip
                      v-if="item.maintenance_type"
                      small
                      class="d-inline-block my-1"
                    >
                      {{ item.maintenance_type.type }}
                    </v-chip>
                    <v-chip
                      v-else-if="
                        item.maintenance_request &&
                        item.maintenance_request.maintenance_type
                      "
                      small
                      class="d-inline-block my-1"
                    >
                      {{ item.maintenance_request.maintenance_type.type }}
                    </v-chip>
                    <template
                      v-if="item.maintenance_request && !item.completion_date"
                    >
                      <v-chip
                        v-if="item.repair_date"
                        small
                        color="yellow darken-1"
                        class="d-inline-block my-1"
                      >
                        <v-icon left small>mdi-wrench-clock</v-icon>
                        {{ $d(new Date(item.repair_date), 'short', 'th') }}
                      </v-chip>
                      <v-chip
                        v-else
                        small
                        color="yellow lighten-4"
                        class="d-inline-block my-1"
                      >
                        <v-icon left small>mdi-wrench-clock</v-icon>
                        {{ $t('misc.date_not_set') }}
                      </v-chip>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td
                    v-if="item.vehicle && item.vehicle.range_model"
                    class="pb-2"
                  >
                    <label
                      style="border: 1px solid black; border-radius: 4px"
                      class="d-inline-block pa-1 mb-1 mr-1"
                    >
                      <b>{{ item.vehicle.registration_number }}</b>
                    </label>
                    <div class="d-inline-block">
                      {{ item.vehicle.range_model.description }}
                    </div>
                  </td>
                  <td v-else-if="item.problem" class="pb-2">
                    <label
                      v-if="
                        item.maintenance_request &&
                        item.maintenance_request.vehicle
                      "
                      style="border: 1px solid black; border-radius: 4px"
                      class="d-inline-block pa-1 mb-1 mr-1"
                    >
                      <b>{{
                        item.maintenance_request.vehicle.registration_number
                      }}</b>
                    </label>
                    <div class="d-inline-block">
                      {{ item.problem.detail }}
                    </div>
                  </td>
                </tr>
              </template>

              <template #[`item.maintenance_type`]="{ item }">
                <v-chip v-if="item.maintenance_type" small>{{
                  item.maintenance_type.type
                }}</v-chip>
              </template>

              <template #[`item.date_of_birth`]="{ item }">
                <template v-if="item.date_of_birth">
                  {{ $d(new Date(item.date_of_birth), 'short', 'th') }}
                </template>
                <template v-else>-</template>
              </template>

              <template #[`item.document_code`]="{ item }">
                <h3>
                  {{ item.document_code }}
                </h3>
                <span v-if="item.company">
                  {{ item.company.name }}
                </span>
                <v-card flat color="transparent">
                  <v-chip
                    v-if="item.step"
                    small
                    :dark="!item.step?.is_final"
                    :color="
                      item.step.step === 2
                        ? 'accent'
                        : item.step.step === 3
                        ? 'orange darken-4'
                        : item.step.step === 4
                        ? 'yellow darken-4'
                        : item.step.step === 5
                        ? 'indigo darken-4'
                        : 'success'
                    "
                    :outlined="!item.step?.is_final"
                    class="d-inline-block mb-2 mt-1"
                  >
                    <template
                      v-if="item.payment_complete && item.step.step === 6"
                    >
                      <v-icon left small>mdi-checkbox-marked-circle</v-icon>
                      {{ $t('data_label.payment_complete') }}
                    </template>
                    <template v-else>
                      <v-icon left small>
                        mdi-circle-slice-{{
                          Math.round((8 / 6) * item.step.step)
                        }}
                      </v-icon>
                      <b>
                        {{ $t('data_label.step') }}
                        {{ item.step.step }}:&nbsp;
                      </b>
                      {{ item.step.status }}
                    </template>
                  </v-chip>
                </v-card>
              </template>

              <template #[`item.quantity_calculated`]="{ item }">
                <!-- <v-chip
                    v-if="item.quantity_calculated"
                    small
                    dark
                    :color="
                      item.quantity_counted !== null &&
                      item.quantity_counted !== item.quantity_calculated &&
                      item.snapshot_transaction_created !== true
                        ? 'error'
                        : 'success'
                    "
                    class="d-md-none mr-1 mb-1"
                  >
                    <v-icon small left dark class="mr-1"
                      >{{
                        item.quantity_counted !== null &&
                        item.quantity_counted !== item.quantity_calculated &&
                        item.snapshot_transaction_created !== true
                          ? 'mdi-store-alert'
                          : 'mdi-store-check'
                      }}
                    </v-icon>
                    {{
                      item.quantity_counted !== null &&
                      item.quantity_counted !== item.quantity_calculated &&
                      item.snapshot_transaction_created !== true
                        ? item.quantity_counted + '/'
                        : ''
                    }}{{ item.quantity_calculated }}
                  </v-chip> -->
                <v-chip
                  v-if="item.quantity_calculated"
                  dark
                  :color="
                    item.quantity_counted !== null &&
                    item.quantity_counted !== item.quantity_calculated &&
                    item.snapshot_transaction_created !== true
                      ? 'error'
                      : 'success'
                  "
                >
                  <v-icon small left dark class="mr-2">
                    {{
                      item.quantity_counted !== null &&
                      item.quantity_counted !== item.quantity_calculated &&
                      item.snapshot_transaction_created !== true
                        ? 'mdi-store-alert'
                        : 'mdi-store-check'
                    }}
                  </v-icon>
                  {{
                    item.quantity_counted !== null &&
                    item.quantity_counted !== item.quantity_calculated &&
                    item.snapshot_transaction_created !== true
                      ? item.quantity_counted + '/'
                      : ''
                  }}{{ item.quantity_calculated }}
                </v-chip>
                <v-chip
                  v-else-if="item.quantity_calculated === 0"
                  dark
                  color="error"
                >
                  <v-icon small left dark class="mr-2">
                    mdi-store-remove
                  </v-icon>
                  {{ $t('status.out_of_stock') }}
                </v-chip>
                <v-chip v-else outlined color="error">
                  <v-icon small left class="mr-1">mdi-store-alert </v-icon>
                  {{
                    $t('status.attribute_not_set', {
                      attribute: $t('data_label.quantity'),
                    })
                  }}
                </v-chip>
              </template>

              <template #[`item.locationDescription`]="{ item }">
                <v-chip v-if="item.storage_area_id" dark color="accent">
                  <v-icon small dark left class="mr-1">mdi-map-marker </v-icon>
                  {{ item.locationDescription }}
                </v-chip>
                <v-chip v-else outlined color="error">
                  <v-icon small left class="mr-1"> mdi-map-marker </v-icon>
                  {{
                    $t('status.attribute_not_set', {
                      attribute: $t('data_label.storage_area'),
                    })
                  }}
                </v-chip>
              </template>

              <template #[`item.inventoryCheckItem`]="{ item }">
                <div v-if="item.store" class="d-inline-block py-1">
                  <h4>
                    {{ item.store ? item.store.name : '' }}
                    {{ $t(`month_name.${item.month}`) }}- {{ item.year }}
                    <v-chip
                      small
                      :dark="item.finish_date !== null"
                      :outlined="item.finish_date === null"
                      :color="item.finish_date === null ? 'accent' : 'success'"
                      :class="
                        item.finish_date !== null
                          ? 'mr-1 my-1 pl-1'
                          : 'mr-1 my-1'
                      "
                    >
                      <template v-if="item.finish_date !== null">
                        <v-icon small class="my-1 mr-1">
                          mdi-check-circle
                        </v-icon>
                        {{ $t('data_label.complete') }}
                      </template>
                      <template v-else>
                        {{ item.item_total - item.group_1_remaining }}/{{
                          item.item_total
                        }}
                      </template>
                    </v-chip>
                  </h4>
                  <v-icon small>mdi-store</v-icon>
                  {{ item.store.code }}
                  <v-card flat class="d-inline-block transparent">
                    <v-icon small>mdi-calendar-start</v-icon>
                    {{ item.start_date }}
                    <v-icon v-if="item.finish_date" small color="success">
                      mdi-calendar-check
                    </v-icon>
                    <v-icon v-else small color="error"
                      >mdi-calendar-clock</v-icon
                    >
                    {{ item.finish_date }}
                  </v-card>
                  <v-card flat class="d-block d-sm-none transparent">
                    <v-chip
                      small
                      dark
                      :color="item.finish_date === null ? 'accent' : 'success'"
                      class="mr-1 my-1 pl-1"
                    >
                      <v-icon small class="mr-1">{{
                        item.finish_date === null
                          ? 'mdi-numeric-1-circle'
                          : 'mdi-check-circle'
                      }}</v-icon>
                      {{ item.group_1.name }}
                    </v-chip>
                    <v-chip
                      v-if="item.group_2_id && item.group_2_id !== null"
                      small
                      dark
                      :color="item.finish_date === null ? 'accent' : 'success'"
                      class="mr-1 my-1 pl-1"
                    >
                      <v-icon small class="mr-1">{{
                        item.finish_date === null
                          ? 'mdi-numeric-2-circle'
                          : 'mdi-check-circle'
                      }}</v-icon>
                      {{ item.group_2.name }}
                    </v-chip>
                    <v-chip
                      v-else
                      small
                      outlined
                      color="warning darken-2"
                      class="mr-1 my-1 pl-1"
                    >
                      <v-icon small class="mr-1">mdi-close-circle</v-icon>
                      {{ $t('misc.not_required') }}
                    </v-chip>
                  </v-card>
                </div>
              </template>

              <template #[`item.group_1.name`]="{ item }">
                <v-chip
                  small
                  dark
                  :color="item.finish_date === null ? 'accent' : 'success'"
                  class="mr-1 my-1 pl-1"
                >
                  <v-icon small class="mr-1">{{
                    item.finish_date === null
                      ? 'mdi-numeric-1-circle'
                      : 'mdi-check-circle'
                  }}</v-icon>
                  {{ item.group_1.name }}
                </v-chip>
              </template>

              <template #[`item.group_2.name`]="{ item }">
                <v-chip
                  v-if="item.group_2_id && item.group_2_id !== null"
                  small
                  dark
                  :color="item.finish_date === null ? 'accent' : 'success'"
                  class="mr-1 my-1 pl-1"
                >
                  <v-icon small class="mr-1">
                    {{
                      item.finish_date === null
                        ? 'mdi-numeric-2-circle'
                        : 'mdi-check-circle'
                    }}
                  </v-icon>
                  {{ item.group_2.name }}
                </v-chip>
                <v-chip
                  v-else
                  small
                  outlined
                  color="warning darken-2"
                  class="mr-1 my-1 pl-1"
                >
                  <v-icon small class="mr-1">mdi-close-circle</v-icon>
                  {{ $t('misc.not_required') }}
                </v-chip>
              </template>

              <template #[`item.transactionItem`]="{ item }">
                <v-card flat class="my-1">
                  <h4 class="d-inline-block">
                    {{ item.inventory_transaction_type.type }}
                  </h4>
                  <div
                    v-if="
                      item.inventory_snapshot_transaction &&
                      item.inventory_snapshot_transaction.inventory_snapshot &&
                      item.inventory_snapshot_transaction.inventory_snapshot
                        .inventory_check &&
                      item.inventory_snapshot_transaction.inventory_snapshot
                        .inventory_check.store
                    "
                    class="d-inline-block"
                  >
                    <v-icon small class="mr-1">mdi-store-check</v-icon>

                    {{
                      item.inventory_snapshot_transaction.inventory_snapshot
                        .inventory_check.store.name
                    }}
                    {{
                      $t(
                        `month_name.${item.inventory_snapshot_transaction.inventory_snapshot.inventory_check.month}`
                      )
                    }}-
                    {{
                      item.inventory_snapshot_transaction.inventory_snapshot
                        .inventory_check.year
                    }}
                  </div>
                  <div class="d-inline-block">
                    <v-icon small class="mr-1">mdi-tag</v-icon>
                    {{ item.inventory.code }}
                  </div>

                  <div class="d-inline-block mr-1">
                    <v-icon small class="mr-1">mdi-account</v-icon>
                    {{ item.employee.person.full_name }}
                  </div>
                  <div class="d-inline-block">
                    <v-icon small>mdi-calendar</v-icon>
                    {{ $d(new Date(item.updated_at), 'short', 'th') }}
                  </div>
                  <v-chip
                    v-if="item.batch"
                    small
                    flat
                    color="warning darken-2"
                    class="pl-1"
                  >
                    <v-icon small class="mx-1">mdi-package-variant</v-icon>
                    {{ item.batch }}
                  </v-chip>
                  <template
                    v-if="
                      item.inventory_transaction_type_id === 1 ||
                      item.inventory_transaction_type_id === 2
                    "
                  >
                    <v-chip
                      small
                      dark
                      color="success"
                      class="d-inline-block mr-1 pl-1"
                    >
                      <v-icon small class="mr-1">mdi-plus-circle</v-icon>
                      {{ item.quantity }}
                    </v-chip>
                    <v-chip
                      small
                      dark
                      outlined
                      :color="item.quantity_remaining > 0 ? 'accent' : 'error'"
                      :class="
                        item.quantity_remaining > 0
                          ? 'd-inline-block mr-1 pl-1'
                          : 'd-inline-block mr-1'
                      "
                    >
                      <v-icon
                        v-if="item.quantity_remaining > 0"
                        small
                        class="mr-1"
                        >mdi-check-circle</v-icon
                      >
                      <template v-if="item.quantity_remaining === 0">
                        {{ $t('status.all_used') }}
                      </template>
                      <template v-else>
                        {{ item.quantity_remaining }}
                        {{ $t('status.remaining') }}
                      </template>
                    </v-chip>
                  </template>
                  <v-chip
                    v-else-if="item.quantity_remaining > 0"
                    small
                    dark
                    :color="item.quantity_remaining > 0 ? 'success' : 'error'"
                    class="d-inline-block mr-1 pl-1"
                  >
                    <v-icon small class="mr-1">{{
                      item.quantity_remaining > 0
                        ? 'mdi-check-circle'
                        : 'mdi-close-circle'
                    }}</v-icon>
                    {{ item.quantity_remaining }}/{{ item.quantity }}
                  </v-chip>
                  <v-chip
                    v-else
                    small
                    dark
                    :color="item.quantity >= 0 ? 'success' : 'error'"
                    class="d-inline-block mr-1 pl-1"
                  >
                    <v-icon small class="mr-1">{{
                      item.quantity >= 0
                        ? 'mdi-plus-circle'
                        : 'mdi-minus-circle'
                    }}</v-icon>
                    {{ item.quantity }}
                  </v-chip>
                </v-card>
              </template>

              <template #[`item.snapshotItem`]="{ item }">
                <div
                  v-if="item.inventory && item.inventory.product_variation"
                  class="d-inline-block py-1"
                >
                  <h4>
                    {{
                      item.inventory_check && item.inventory_check.store
                        ? item.inventory_check.store.name
                        : ''
                    }}
                    {{ $t(`month_name.${item.inventory_check.month}`) }}-
                    {{ item.inventory_check.year }} ({{ item.group.name }})
                  </h4>
                  <v-card flat class="transparent">
                    {{ item.inventory.product_variation.description }}
                  </v-card>
                  <v-card flat class="transparent">
                    <v-icon small>mdi-tag</v-icon>
                    {{ item.inventory.code }}
                  </v-card>
                  <template v-if="item.quantity_discrepancy === null">
                    <v-chip
                      small
                      dark
                      color="warning darken-2"
                      class="mr-1 mb-1 pl-1"
                    >
                      <v-icon small class="mr-1">mdi-exclamation-thick</v-icon>
                      Not checked
                    </v-chip>
                  </template>
                  <template
                    v-else-if="
                      item.complete === false &&
                      item.quantity_discrepancy !== 0 &&
                      item.quantity_discrepancy_recheck === null
                    "
                  >
                    <v-chip small dark color="error" class="mr-1 mb-1 pl-1">
                      <v-icon small class="mr-1">mdi-close-circle</v-icon>
                      #1 - {{ item.quantity_counted }}/{{
                        item.quantity_calculated
                      }}
                    </v-chip>
                    <v-chip
                      small
                      outlined
                      color="warning darken-2"
                      class="mr-1 mb-1 pl-1"
                    >
                      <v-icon small class="mr-1">mdi-exclamation-thick</v-icon>
                      #2 - Need Recheck
                    </v-chip>
                  </template>
                  <template
                    v-else-if="
                      item.complete === false &&
                      item.quantity_discrepancy !== 0 &&
                      item.quantity_discrepancy_recheck === 0
                    "
                  >
                    <v-chip small dark color="error" class="mr-1 mb-1 pl-1">
                      <v-icon small class="mr-1">mdi-close-circle</v-icon>
                      #1 -&nbsp;
                      <b>
                        {{ item.quantity_counted }}/{{
                          item.quantity_calculated
                        }}
                      </b>
                    </v-chip>
                    <v-chip small dark color="success" class="mr-1 mb-1 pl-1">
                      <v-icon small class="mr-1">mdi-check-circle</v-icon>
                      #2 -&nbsp;
                      <b>
                        {{ item.quantity_counted_recheck }}/{{
                          item.quantity_calculated
                        }}
                      </b>
                    </v-chip>
                  </template>
                  <template
                    v-else-if="
                      item.complete === false &&
                      item.quantity_discrepancy !== 0 &&
                      item.quantity_discrepancy_recheck !== 0
                    "
                  >
                    <v-chip small dark color="error" class="mr-1 mb-1 pl-1">
                      <v-icon small class="mr-1">mdi-close-circle</v-icon>
                      #1 -&nbsp;
                      <b>
                        {{ item.quantity_counted }}/{{
                          item.quantity_calculated
                        }}
                      </b>
                    </v-chip>
                    <v-chip
                      small
                      outlined
                      color="warning darken-2"
                      class="mr-1 mb-1 pl-1"
                    >
                      <v-icon small class="mr-1">mdi-help</v-icon>
                      #2 -&nbsp;
                      <b>
                        {{ item.quantity_counted_recheck }}/{{
                          item.quantity_calculated
                        }}
                      </b>
                    </v-chip>
                  </template>
                  <template
                    v-else-if="
                      item.complete === false &&
                      item.quantity_discrepancy === 0 &&
                      item.quantity_discrepancy_recheck === null
                    "
                  >
                    <v-chip
                      small
                      dark
                      color="warning darken-2"
                      class="mr-1 mb-1 pl-1"
                    >
                      <v-icon small class="mr-1">mdi-check-circle</v-icon>
                      <b>
                        {{ item.quantity_counted }}/{{
                          item.quantity_calculated
                        }}
                      </b>
                    </v-chip>
                    <v-chip
                      small
                      outlined
                      color="warning darken-2"
                      class="mr-1 mb-1 pl-1"
                    >
                      <v-icon small class="mr-1">mdi-exclamation-thick</v-icon>
                      #2 - Need Recheck
                    </v-chip>
                  </template>
                  <template
                    v-else-if="
                      item.complete === true &&
                      item.quantity_discrepancy === 0 &&
                      item.quantity_discrepancy_recheck === null
                    "
                  >
                    <v-chip small dark color="success" class="mr-1 mb-1 pl-1">
                      <v-icon small class="mr-1">mdi-check-circle</v-icon>
                      <b>
                        {{ item.quantity_counted }}/{{
                          item.quantity_calculated
                        }}
                      </b>
                    </v-chip>
                  </template>
                  <template
                    v-else-if="
                      item.complete === true &&
                      item.quantity_discrepancy_recheck !== null
                    "
                  >
                    <v-chip small dark color="error" class="mr-1 mb-1 pl-1">
                      <v-icon small class="mr-1">mdi-close-circle</v-icon>
                      #1 -&nbsp;
                      <b>
                        {{ item.quantity_counted }}/{{
                          item.quantity_calculated
                        }}
                      </b>
                    </v-chip>
                    <v-chip small dark color="success" class="mr-1 mb-1 pl-1">
                      <v-icon small class="mr-1">mdi-check-circle</v-icon>
                      #2 -&nbsp;
                      <b>
                        {{ item.quantity_counted_recheck }}/{{
                          item.quantity_calculated
                        }}
                      </b>
                    </v-chip>
                  </template>
                </div>
              </template>

              <template #[`item.item`]="{ item, index }">
                <div v-if="item.product_variation" class="d-inline-block py-1">
                  <h4>{{ item.product_variation.description }}</h4>
                  <v-card flat class="transparent">
                    <v-icon small>mdi-tag</v-icon>
                    {{ item.code }}
                  </v-card>

                  <v-chip
                    v-if="
                      item.storage_area_id === null ||
                      item.quantity_calculated === null
                    "
                    small
                    dark
                    color="warning darken-2"
                    class="mr-1 my-1"
                  >
                    <v-icon small dark left class="mr-1">
                      mdi-exclamation-thick
                    </v-icon>
                    {{ $t('status.draft') }}
                  </v-chip>
                  <v-chip
                    v-if="item.quantity_calculated"
                    small
                    dark
                    :color="
                      item.quantity_counted !== null &&
                      item.quantity_counted !== item.quantity_calculated &&
                      item.snapshot_transaction_created !== true
                        ? 'error'
                        : 'success'
                    "
                    class="d-md-none mr-1 my-1"
                  >
                    <v-icon small left dark class="mr-2">
                      {{
                        item.quantity_counted !== null &&
                        item.quantity_counted !== item.quantity_calculated &&
                        item.snapshot_transaction_created !== true
                          ? 'mdi-store-alert'
                          : 'mdi-store-check'
                      }}
                    </v-icon>
                    {{
                      item.quantity_counted !== null &&
                      item.quantity_counted !== item.quantity_calculated &&
                      item.snapshot_transaction_created !== true
                        ? item.quantity_counted + '/'
                        : ''
                    }}{{ item.quantity_calculated }}
                  </v-chip>
                  <v-chip
                    v-else-if="item.quantity_calculated === 0"
                    small
                    dark
                    color="error"
                    class="d-md-none mr-1 my-1"
                  >
                    <v-icon small left dark class="mr-2">
                      mdi-store-remove
                    </v-icon>
                    {{ $t('status.out_of_stock') }}
                  </v-chip>
                  <v-chip
                    v-else
                    small
                    outlined
                    color="error"
                    class="d-md-none mr-1 my-1"
                  >
                    <v-icon small left class="mr-1">mdi-store-alert </v-icon>
                    {{
                      $t('status.attribute_not_set', {
                        attribute: $t('data_label.quantity'),
                      })
                    }}
                  </v-chip>
                  <v-chip
                    v-if="item.storage_area_id"
                    small
                    dark
                    color="accent"
                    class="d-lg-none mr-1 my-1"
                  >
                    <v-icon small dark left class="mr-1"
                      >mdi-map-marker
                    </v-icon>
                    {{ item.locationDescription }}
                  </v-chip>
                  <v-chip
                    v-else
                    small
                    outlined
                    color="error"
                    class="d-lg-none mr-1 my-1"
                  >
                    <v-icon small left class="mr-1">mdi-map-marker </v-icon>
                    {{
                      $t('status.attribute_not_set', {
                        attribute: $t('data_label.storage_area'),
                      })
                    }}
                  </v-chip>
                </div>
                <div v-else class="d-inline-block">
                  {{ item.description }}
                  <h4 v-if="item.code">
                    {{ item.code }}
                  </h4>
                  <v-card flat color="transparent">
                    <v-chip
                      v-for="(inventory, inventoryIndex) in item.inventories"
                      :key="inventoryIndex"
                      small
                      :dark="inventory.quantity_calculated"
                      :color="inventory.quantity_calculated ? 'accent' : ''"
                      class="mr-1 mb-1"
                    >
                      <v-icon
                        small
                        left
                        :dark="inventory.quantity_calculated"
                        class="mr-1"
                        >mdi-store
                      </v-icon>
                      {{ inventory.code }}
                      <v-chip
                        v-if="inventory.quantity_calculated"
                        x-small
                        right
                        color="white"
                        class="ml-1 mr-0 pl-1"
                      >
                        <v-icon
                          x-small
                          :dark="inventory.quantity_calculated"
                          class="mr-1"
                          >mdi-check-circle
                        </v-icon>
                        {{ inventory.quantity_calculated }}
                      </v-chip>
                    </v-chip>
                    <v-chip
                      v-if="item.inventories && !item.inventories.length"
                      dark
                      color="success darken-2"
                      class="d-md-none mr-1 mb-1"
                      @click.native.stop
                      @click="
                        setAddForm();
                        dialog.addInventory = true;
                        selectedId = item.id;
                        selectedIndex = index;
                      "
                    >
                      <v-icon small left dark class="mr-1"
                        >mdi-store-plus
                      </v-icon>
                      Add to Store
                    </v-chip>
                  </v-card>
                </div>
              </template>

              <template #[`item.created_at`]="{ item }">
                {{ $d(new Date(item.created_at), 'short', 'th') }}
              </template>

              <template #[`item.payment_due_date`]="{ item }">
                {{ $d(new Date(item.payment_due_date), 'short', 'th') }}
              </template>

              <template #[`item.status`]="{ item }">
                <v-chip
                  v-if="item.status"
                  small
                  :color="
                    item.status.name === 'Complete'
                      ? 'success'
                      : item.status.name === 'Rejected'
                      ? 'error'
                      : 'accent'
                  "
                  :outlined="item.status.name === 'Draft' ? true : false"
                >
                  {{ item.status.name }}
                  <v-icon v-if="item.status_id === 6" right small>
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon v-else right small
                    >mdi-circle-slice-{{ item.step }}</v-icon
                  ></v-chip
                >
              </template>

              <template #[`item.name_address`]="{ item }">
                <v-card flat class="py-1 transparent">
                  <v-card flat class="ma-0 transparent">
                    <b>{{ item.description }}</b>
                  </v-card>
                  <v-card flat class="ma-0 transparent">
                    {{ item.address_line_0 }}
                  </v-card>
                  <v-card flat class="d-inline-block ma-0 transparent">
                    {{ item.address.fullAddress }}
                  </v-card>
                </v-card>
              </template>

              <template #[`item.address`]="{ item }">
                <v-card flat class="py-1 transparent">
                  <v-card flat class="ma-0 transparent">
                    {{ item.address_line_0 }}
                  </v-card>
                  <v-card flat class="d-inline-block ma-0 transparent">
                    {{ item.address.fullAddress }}
                  </v-card>
                </v-card>
              </template>

              <template #[`item.profile`]="{ item }">
                <v-card flat class="py-1 transparent">
                  <v-card flat class="d-inline-block ma-0 transparent">
                    <h3>
                      {{ item.full_name }}
                      <v-icon v-if="item.gender && item.gender.id === 1" small>
                        mdi-gender-male
                      </v-icon>
                      <v-icon
                        v-else-if="item.gender && item.gender.id === 2"
                        small
                      >
                        mdi-gender-female
                      </v-icon>
                    </h3>
                  </v-card>
                  <v-card flat class="d-block transparent">
                    <v-icon small>mdi-cake</v-icon>
                    {{ $d(new Date(item.date_of_birth), 'short', 'th') }}
                  </v-card>
                  <v-card flat class="d-inline-block transparent mr-1">
                    <v-icon small>mdi-account</v-icon>
                    {{ item.preferred_name }}
                  </v-card>
                  <v-card flat class="d-inline-block transparent">
                    <v-icon small>mdi-phone</v-icon>
                    {{ item.telephone }}
                  </v-card>
                </v-card>
              </template>

              <template #[`item.payment_request`]="{ item }">
                <v-card flat class="py-1 transparent">
                  <v-card flat class="d-inline-block ma-0 transparent">
                    <h3>
                      {{ item.document_code }}
                    </h3>
                  </v-card>
                  <v-card flat class="d-block transparent">
                    {{ item.company?.name }}
                  </v-card>
                  <v-card
                    v-if="$vuetify.breakpoint.smAndDown || mini"
                    flat
                    class="d-block transparent"
                  >
                    <v-icon small>mdi-store</v-icon>
                    {{ item.supplier ? item.supplier.description : '' }}
                  </v-card>
                  <!-- <v-card flat class="d-inline-block mr-2 transparent">
                    <v-icon small>mdi-file</v-icon>
                    {{
                      item.payment_request_files
                        ? item.payment_request_files.length
                        : ''
                    }}
                    {{
                      ` ${$tc(
                        'entity.file',
                        item.payment_request_files.length
                      )}`
                    }}
                  </v-card> -->
                  <v-card
                    v-if="$vuetify.breakpoint.xs || mini"
                    flat
                    class="d-inline-block transparent"
                  >
                    <v-icon small>mdi-calendar-check</v-icon>
                    {{ $d(new Date(item.created_at), 'short', 'th') }}
                  </v-card>
                  <v-card
                    v-if="$vuetify.breakpoint.xs || mini"
                    flat
                    class="d-inline-block transparent"
                  >
                    <v-icon small>mdi-calendar-question</v-icon>
                    {{ $d(new Date(item.payment_due_date), 'short', 'th') }}
                  </v-card>
                  <v-card
                    v-if="$vuetify.breakpoint.mdAndDown || mini"
                    flat
                    color="transparent"
                  >
                    <v-chip
                      v-if="item.step"
                      small
                      :dark="!item.step?.is_final"
                      :color="
                        item.step.step === 2
                          ? 'red darken-4'
                          : item.step.step === 3
                          ? 'deep-orange darken-4'
                          : item.step.step === 4
                          ? 'brown'
                          : item.step.step === 5
                          ? 'purple darken-4'
                          : item.step.step === 6
                          ? 'indigo darken-4'
                          : item.step.step === 7
                          ? 'blue darken-4'
                          : 'success darken-2'
                      "
                      :outlined="!item.step?.is_final"
                      class="d-inline-block mb-2 mt-1"
                    >
                      <template
                        v-if="item.payment_complete && item.step.step === 8"
                      >
                        <v-icon left small>mdi-checkbox-marked-circle</v-icon>
                        {{ $t('data_label.payment_complete') }}
                      </template>
                      <template v-else>
                        <v-icon left small>
                          mdi-circle-slice-{{ item.step.step }}
                        </v-icon>
                        <b>
                          {{ $t('data_label.step') }}
                          {{ item.step.step }}:&nbsp;
                        </b>
                        {{ item.step.status }}
                      </template>
                    </v-chip>
                  </v-card>
                </v-card>
              </template>

              <template #[`item.entity`]="{ item }">
                <v-card flat class="py-1 transparent">
                  {{ $tc(`entity.${item.sync_entity}`, 1) }}
                </v-card>
              </template>

              <template #[`item.view`]="{ item }">
                <v-chip small class="pa-1" color="success">
                  <v-icon small> mdi-eye </v-icon>
                </v-chip>

                <v-icon
                  v-if="item.view === true"
                  color="success"
                  small
                  class="mx-1"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-else-if="item.view === false"
                  color="error"
                  small
                  class="mx-1"
                >
                  mdi-close
                </v-icon>
              </template>

              <template #[`item.create`]="{ item }">
                <v-chip small color="accent" class="pa-1">
                  <v-icon small> mdi-plus </v-icon>
                </v-chip>
                <v-icon
                  v-if="item.create === true"
                  color="success"
                  small
                  class="mx-1"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-else-if="item.create === false"
                  color="error"
                  small
                  class="mx-1"
                >
                  mdi-close
                </v-icon>
              </template>

              <template #[`item.edit`]="{ item }">
                <v-chip small color="warning darken-2" class="pa-1">
                  <v-icon small> mdi-pencil </v-icon>
                </v-chip>
                <v-icon
                  v-if="item.edit === true"
                  color="success"
                  small
                  class="mx-1"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-else-if="item.edit === false"
                  color="error"
                  small
                  class="mx-1"
                >
                  mdi-close
                </v-icon>
              </template>

              <template #[`item.delete`]="{ item }">
                <v-chip small color="error" class="pa-1">
                  <v-icon small> mdi-delete </v-icon>
                </v-chip>
                <v-icon
                  v-if="item.delete === true"
                  color="success"
                  small
                  class="mx-1"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-else-if="item.delete === false"
                  color="error"
                  small
                  class="mx-1"
                >
                  mdi-close
                </v-icon>
              </template>

              <template #[`item.employee`]="{ item }">
                <tr>
                  <td class="pt-2">
                    <v-card flat class="d-inline-block pa-1 ma-0">
                      <h3 class="d-inline">
                        {{ item.employee_number }}
                      </h3>
                    </v-card>

                    <div v-if="item.person" class="d-inline-block">
                      {{ item.person.full_name }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pb-2">
                    <template v-if="item.groups && item.groups.length">
                      <v-chip
                        v-for="group in item.groups"
                        :key="group.id"
                        small
                        outlined
                        color="accent"
                        class="d-inline-block my-1 mr-1"
                      >
                        {{ group.name }}
                      </v-chip>
                    </template>
                    <v-chip
                      v-else
                      small
                      outlined
                      color="error darken-2"
                      class="d-inline-block my-1 mr-1 pl-1"
                    >
                      <v-icon small class="mr-1">mdi-close-circle</v-icon>
                      {{
                        $t('misc.no_entity_set', {
                          entity: $tc('entity.group', 0),
                        })
                      }}
                    </v-chip>
                    <template v-if="item.companies && item.companies.length">
                      <v-chip
                        v-for="company in item.companies"
                        :key="company.id + '-' + company.name"
                        small
                        outlined
                        color="success"
                        class="d-inline-block my-1 mr-1"
                      >
                        {{ company.name }}
                      </v-chip>
                    </template>
                    <v-chip
                      small
                      outlined
                      color="error darken-2"
                      class="d-inline-block my-1 mr-1 pl-1"
                    >
                      <v-icon small class="mr-1">mdi-close-circle</v-icon>
                      {{
                        $t('misc.no_entity_set', {
                          entity: $tc('entity.company', 0),
                        })
                      }}
                    </v-chip>
                  </td>
                </tr>
              </template>

              <template #[`item.employee_with_groups`]="{ item }">
                <tr>
                  <td class="pt-2">
                    <div v-if="item.person" class="d-inline-block">
                      {{ item.person.full_name }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pb-2">
                    <template v-if="item.groups && item.groups.length">
                      <v-chip
                        v-for="group in item.groups"
                        :key="group.id"
                        small
                        outlined
                        color="accent"
                        class="d-inline-block my-1 mr-1"
                      >
                        {{ group.name }}
                      </v-chip>
                    </template>
                    <v-chip
                      v-else
                      small
                      outlined
                      color="error darken-2"
                      class="d-inline-block my-1 mr-1 pl-1"
                    >
                      <v-icon small class="mr-1">mdi-close-circle</v-icon>
                      {{
                        $t('misc.no_entity_set', {
                          entity: $tc('entity.group', 0),
                        })
                      }}
                    </v-chip>
                    <template v-if="item.companies && item.companies.length">
                      <v-chip
                        v-for="company in item.companies"
                        :key="company.id + '-' + company.name"
                        small
                        outlined
                        color="success darken-2"
                        class="d-inline-block my-1 mr-1"
                      >
                        {{ company.name }}
                      </v-chip>
                    </template>
                    <v-chip
                      v-else
                      small
                      outlined
                      color="error darken-2"
                      class="d-inline-block my-1 mr-1 pl-1"
                    >
                      <v-icon small class="mr-1">mdi-close-circle</v-icon>
                      {{
                        $t('misc.no_entity_set', {
                          entity: $tc('entity.company', 0),
                        })
                      }}
                    </v-chip>
                  </td>
                </tr>
              </template>

              <template #[`item.user`]="{ item }">
                <tr>
                  <td class="pt-2">
                    <v-card flat class="d-inline-block pa-1 ma-0">
                      <h3 class="d-inline">
                        {{ item.username }}
                      </h3>
                    </v-card>

                    <div
                      v-if="item.employee && item.employee.person"
                      class="d-inline-block"
                    >
                      {{ item.employee.person.full_name }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pb-2">
                    <template v-if="item.roles && item.roles.length">
                      <v-chip
                        v-for="role in item.roles"
                        :key="role.id"
                        small
                        outlined
                        color="accent"
                        class="d-inline-block my-1 mr-1"
                      >
                        {{ role.name }}
                      </v-chip>
                    </template>
                    <v-chip
                      v-else
                      small
                      outlined
                      color="error darken-2"
                      class="d-inline-block my-1 mr-1 pl-1"
                    >
                      <v-icon small class="mx-1">mdi-close-circle</v-icon>
                      {{
                        $t('misc.no_entity_set', {
                          entity: $tc('entity.role', 0),
                        })
                      }}
                    </v-chip>
                  </td>
                </tr>
              </template>

              <template #[`item.user_with_roles`]="{ item }">
                <tr>
                  <td class="pt-2">
                    <div
                      v-if="item.employee && item.employee.person"
                      class="d-inline-block"
                    >
                      {{ item.employee.person.full_name }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="pb-2">
                    <template v-if="item.roles && item.roles.length">
                      <v-chip
                        v-for="role in item.roles"
                        :key="role.id"
                        small
                        outlined
                        color="accent"
                        class="d-inline-block my-1 mr-1"
                      >
                        {{ role.name }}
                      </v-chip>
                    </template>
                    <v-chip
                      v-else
                      small
                      outlined
                      color="error darken-2"
                      class="d-inline-block my-1 mr-1 pl-1"
                    >
                      <v-icon small class="mr-1">mdi-close-circle</v-icon>
                      {{
                        $t('misc.no_entity_set', {
                          entity: $tc('entity.role', 0),
                        })
                      }}
                    </v-chip>
                  </td>
                </tr>
              </template>

              <template #[`item.image`]="{ item }">
                <td :class="$vuetify.breakpoint.xs || mini ? 'pa-0' : ''">
                  <Thumbnail
                    v-if="
                      item.product_variation_images &&
                      item.product_variation_images.length > 0 &&
                      item.product_variation_images[0].image &&
                      item.product_variation_images[0].image.filePath
                    "
                    width="80"
                    height="80"
                    :thumb-path="`${item.product_variation_images[0].image.filePath}?updated_at=${item.product_variation_images[0].image.updated_at}`"
                  />
                  <Thumbnail
                    v-else-if="
                      item.problem_images &&
                      item.problem_images.length > 0 &&
                      item.problem_images[0].image &&
                      item.problem_images[0].image.filePath
                    "
                    width="80"
                    height="80"
                    :thumb-path="item.problem_images[0].image.filePath"
                  />
                  <Thumbnail
                    v-else-if="
                      item.action_images &&
                      item.action_images.length > 0 &&
                      item.action_images[0].image &&
                      item.action_images[0].image.filePath
                    "
                    width="80"
                    height="80"
                    :thumb-path="item.action_images[0].image.filePath"
                  />
                  <Thumbnail
                    v-else-if="
                      item.vehicle_images &&
                      item.vehicle_images.length > 0 &&
                      item.vehicle_images[0].image &&
                      item.vehicle_images[0].image.filePath
                    "
                    width="80"
                    height="80"
                    :thumb-path="item.vehicle_images[0].image.filePath"
                  />
                  <Thumbnail
                    v-else-if="
                      item.person_images &&
                      item.person_images.length > 0 &&
                      item.person_images[0].image &&
                      item.person_images[0].image.filePath
                    "
                    width="80"
                    height="80"
                    :thumb-path="item.person_images[0].image.filePath"
                  />
                  <Thumbnail
                    v-else-if="
                      item.person &&
                      item.person.person_images &&
                      item.person.person_images.length > 0 &&
                      item.person.person_images[0].image &&
                      item.person.person_images[0].image.filePath
                    "
                    width="80"
                    height="80"
                    :thumb-path="item.person.person_images[0].image.filePath"
                  />
                  <Thumbnail
                    v-else-if="
                      item.employee &&
                      item.employee.person &&
                      item.employee.person.person_images &&
                      item.employee.person.person_images.length > 0 &&
                      item.employee.person.person_images[0].image &&
                      item.employee.person.person_images[0].image.filePath
                    "
                    width="80"
                    height="80"
                    :thumb-path="
                      item.employee.person.person_images[0].image.filePath
                    "
                  />
                  <Thumbnail
                    v-else-if="
                      item.product_variation &&
                      item.product_variation.product_variation_images &&
                      item.product_variation.product_variation_images.length >
                        0 &&
                      item.product_variation.product_variation_images[0]
                        .image &&
                      item.product_variation.product_variation_images[0].image
                        .filePath
                    "
                    width="80"
                    height="80"
                    :thumb-path="
                      item.product_variation.product_variation_images[0].image
                        .filePath
                    "
                  />
                  <Thumbnail
                    v-else-if="item.image && item.image.filePath"
                    :thumb-path="item.image.filePath"
                    width="80"
                    height="80"
                  />
                  <Thumbnail
                    v-else
                    width="80"
                    height="80"
                    :thumb-path="`/no_image.jpg`"
                  />
                </td>
              </template>

              <template #[`item.file`]="{ item }">
                <td :class="$vuetify.breakpoint.xs || mini ? 'pa-0' : ''">
                  <Thumbnail
                    v-if="
                      item.person_files &&
                      item.person_files.length > 0 &&
                      item.person_files[0].file &&
                      item.person_files[0].file.filePath
                    "
                    width="80"
                    height="80"
                    :thumb-path="`${item.person_files[0].file.filePath}?updated_at=${item.person_files[0].file.updated_at}`"
                  />
                  <Thumbnail
                    v-else-if="
                      item.person &&
                      item.person.person_files &&
                      item.person.person_files.length > 0 &&
                      item.person.person_files[0].file &&
                      item.person.person_files[0].file.filePath
                    "
                    width="80"
                    height="80"
                    :thumb-path="`${item.person.person_files[0].file.filePath}?updated_at=${item.person.person_files[0].file.updated_at}`"
                  />
                  <Thumbnail
                    v-else-if="
                      item.employee &&
                      item.employee.person &&
                      item.employee.person.person_files &&
                      item.employee.person.person_files.length > 0 &&
                      item.employee.person.person_files[0].file &&
                      item.employee.person.person_files[0].file.filePath
                    "
                    width="80"
                    height="80"
                    :thumb-path="`${item.employee.person.person_files[0].file.filePath}?updated_at=${item.employee.person.person_files[0].file.updated_at}`"
                  />
                  <Thumbnail
                    v-else-if="item.file && item.file.filePath"
                    :thumb-path="`${item.file.filePath}?updated_at=${item.file.updated_at}`"
                    width="80"
                    height="80"
                  />
                  <v-img v-else src="/no_image.jpg" width="80" height="80" />
                  <!-- <Thumbnail
                    v-else
                    width="80"
                    height="80"
                    :thumb-path="`/no_image.jpg`"
                  /> -->
                </td>
              </template>

              <template #[`item.inventory_actions`]="{ item, index }">
                <v-chip
                  v-if="
                    editable && item.inventories && !item.inventories.length
                  "
                  dark
                  color="success darken-2"
                  class="mr-1 mb-1"
                  @click.native.stop
                  @click="
                    setAddForm();
                    dialog.addInventory = true;
                    selectedId = item.id;
                    selectedIndex = index;
                  "
                >
                  <v-icon small left dark class="mr-1">mdi-store-plus </v-icon>
                  Add to Store
                </v-chip>
              </template>

              <template #[`item.actions`]="{ item, index }">
                <v-icon
                  v-if="item.finish_date && item.finish_date !== null"
                  color="success"
                  class="mr-1"
                  >mdi-check</v-icon
                >
                <v-btn
                  v-else-if="
                    editable &&
                    !(item.status_id === 1 && item.completion_date !== null) &&
                    isAuthorizedGroup(
                      item.step
                    ) /* === $auth.user.employee_id */
                  "
                  small
                  icon
                  dark
                  color="accent"
                  aria-label="Continue Workflow"
                  @click.native.stop
                  @click="
                    setForm();
                    dialog.edit = true;
                    selectedId = item.id;
                    selectedIndex = index;
                  "
                >
                  <v-icon aria-label="Action"> mdi-file-document-edit </v-icon>
                </v-btn>
                <v-icon
                  v-else-if="
                    editable &&
                    item.status_id &&
                    !(item.status_id === 1 && item.completion_date !== null) &&
                    !isAuthorizedGroup(
                      item.step
                    ) /* === $auth.user.employee_id */
                  "
                  aria-label="Action"
                  disabled
                >
                  mdi-store-clock
                </v-icon>
              </template>

              <template #[`item.payment_request_actions`]="{ item, index }">
                <v-icon
                  v-if="item.payment_complete === true && item.step?.is_final"
                  color="success"
                  class="mr-1"
                >
                  mdi-check
                </v-icon>
                <v-btn
                  v-else-if="
                    editable &&
                    isStepAuthorizedGroup(
                      item.step
                    ) /* === $auth.user.employee_id */
                  "
                  small
                  icon
                  dark
                  color="accent"
                  aria-label="Continue Workflow"
                  @click.native.stop
                  @click="
                    setForm();
                    dialog.edit = true;
                    selectedId = item.id;
                    selectedIndex = index;
                  "
                >
                  <v-icon aria-label="Action"> mdi-file-document-edit </v-icon>
                </v-btn>
                <v-icon v-else-if="editable" aria-label="Action" disabled>
                  mdi-file-clock
                </v-icon>
              </template>
            </v-data-table>
            <v-divider
              v-if="
                (mini &&
                  editable &&
                  $auth.user.permissions[entity] &&
                  $auth.user.permissions[entity].create === true) ||
                pageCount > 1
              "
            />
            <v-card
              v-if="
                mini &&
                editable &&
                $auth.user.permissions[entity] &&
                $auth.user.permissions[entity].create === true
              "
              flat
              class="pa-2"
              height="100%"
            >
              <v-btn
                v-if="entity === 'payment_request'"
                block
                color="accent"
                aria-label="New"
                @click="
                  setAddForm();
                  dialog.addPaymentRequest = true;
                "
              >
                <v-icon>mdi-plus</v-icon>
                {{
                  $t('button.new_entity', {
                    entity: $tc(`entity.${entity}`, 1),
                  })
                }}
              </v-btn>
              <v-btn
                v-else
                block
                color="accent"
                aria-label="New"
                @click="
                  setCreateForm();
                  dialog.create = true;
                "
              >
                <v-icon>mdi-plus</v-icon>
                {{
                  $t('button.new_entity', {
                    entity: $tc(`entity.${entity}`, 1),
                  })
                }}
              </v-btn>
            </v-card>
            <Dialog
              v-if="dialog.create === true"
              :dialog.sync="dialog.create"
              :title="`${$tc('entity.' + entity, 1)}`"
              :name="`create`"
              form-type="create"
              :can-download-pdf="canDownloadPdf"
            >
              <template #title>
                {{ $tc('entity.' + entity, 1) }}
              </template>
              <CreateForm
                :editable="true"
                :model="model"
                :dialog.sync="dialog.create"
                :parent-key="parentKey !== null ? [parentKey] : parentKey"
                :parent-value="
                  parentValue !== null ? [parentValue] : parentValue
                "
                closable
                max-width="500px"
              />
            </Dialog>
            <Dialog
              v-if="dialog.edit === true"
              :dialog.sync="dialog.edit"
              :title="`${$tc('entity.' + entity, 1)}: ${selectedId}`"
              :name="`edit-${selectedId}`"
              :color="color"
              form-type="view"
              :can-download-pdf="canDownloadPdf"
              closable
              :max-width="$vuetify.breakpoint.mdAndUp ? '800px' : '500px'"
            >
              <template #title>
                {{
                  `${$t('common.edit')} ${$tc(
                    'entity.' + entity,
                    1
                  )}: ${selectedId}`
                }}
              </template>
              <Form
                :id="selectedId"
                :editable="true"
                :model="model"
                :dialog.sync="dialog.edit"
              />
            </Dialog>
            <Dialog
              v-if="dialog.view === true"
              :dialog.sync="dialog.view"
              :title="`${$tc('entity.' + entity, 1)}`"
              :name="`view-${selectedId}`"
              form-type="view"
              :can-download-pdf="canDownloadPdf"
              closable
              :max-width="$vuetify.breakpoint.mdAndUp ? '800px' : '500px'"
            >
              <template #title>
                {{ $tc('entity.' + entity, 1) }}
              </template>
              <Form
                :id="selectedId"
                :model="model"
                :dialog.sync="dialog.view"
              />
            </Dialog>
            <Dialog
              v-if="dialog.viewMini === true"
              :dialog.sync="dialog.viewMini"
              :title="`${$tc('entity.' + entity, 1)}`"
              :name="`view-${selectedId}`"
              form-type="view"
              :can-download-pdf="canDownloadPdf"
              closable
              :max-width="$vuetify.breakpoint.mdAndUp ? '760px' : '460px'"
            >
              <template #title>
                {{ $tc('entity.' + entity, 1) }}
              </template>
              <MiniForm
                :id="selectedId"
                :model="model"
                :dialog.sync="dialog.viewMini"
                max-width="500px"
              />
            </Dialog>
            <!-- <Dialog
              v-if="dialog.addPaymentRequest === true"
              :dialog.sync="dialog.addPaymentRequest"
              :title="`${$t('button.add_entity', {
                entity: $tc('entity.' + entity, 1),
              })}`"
              :name="`addPaymentRequest`"
              form-type="create"
              :can-download-pdf="canDownloadPdf"
              closable
              :max-width="$vuetify.breakpoint.mdAndUp ? '800px' : '500px'"
            >
              <template #title>
                {{
                  $t('button.add_entity', {
                    entity: $tc('entity.' + entity, 1),
                  })
                }}
              </template>
              <AddForm
                :editable="true"
                model="payment_request"
                :dialog.sync="dialog.addPaymentRequest"
                :parent-key="['company_id']"
                :parent-value="[$route.params.companyCode]"
              />
            </Dialog> -->
            <Dialog
              v-if="dialog.addInventory === true"
              :dialog.sync="dialog.addInventory"
              :title="`${$t('common.addInventory')}`"
              :name="`addInventory`"
              form-type="create"
              :can-download-pdf="canDownloadPdf"
              :max-width="$vuetify.breakpoint.mdAndUp ? '800px' : '500px'"
            >
              <template #title>
                {{ $tc('common.addInventory') }}
              </template>
              <AddForm
                :editable="true"
                model="inventory"
                :dialog.sync="dialog.addInventory"
                :parent-key="[
                  'store_id',
                  'subdivision_id',
                  'condition_id',
                  'product_variation_id',
                ]"
                :parent-value="[
                  $route.params.storeCode,
                  $route.params.subdivisionCode,
                  1,
                  selectedId,
                ]"
              />
            </Dialog>
            <Dialog
              v-if="dialog.addStockCheck === true"
              :dialog.sync="dialog.addStockCheck"
              :title="$tc('entity.inventory_check', 1)"
              :name="`addStockCheck`"
              form-type="create"
              :max-width="$vuetify.breakpoint.mdAndUp ? '800px' : '500px'"
            >
              <template #title>
                {{ $tc('entity.inventory_check', 1) }}
              </template>
              <AddForm
                :editable="true"
                model="inventory_check"
                :dialog.sync="dialog.addStockCheck"
                :parent-key="['store_id', 'employee_id']"
                :parent-value="[
                  $route.params.storeCode,
                  $auth.user.employee_id,
                ]"
              />
            </Dialog>
            <Dialog
              v-if="dialog.addPartRequest === true"
              :dialog.sync="dialog.addPartRequest"
              :title="`${$t('common.addPartRequest')}`"
              :name="`addPartRequest`"
              form-type="create"
              :max-width="$vuetify.breakpoint.mdAndUp ? '800px' : '500px'"
            >
              <template #title>
                {{ $tc('common.addPartRequest') }}
              </template>
              <AddForm
                :editable="true"
                model="part_request"
                :dialog.sync="dialog.addPartRequest"
                :parent-key="['store_id', 'employee_id']"
                :parent-value="[
                  $route.params.storeCode,
                  $auth.user.employee_id,
                ]"
              />
            </Dialog>
          </v-card>
          <v-toolbar
            v-if="pageCount > 1"
            id="paginator"
            dense
            flat
            :color="mini ? 'transparent' : 'primary lighten-1'"
            :class="mini === false ? 'mb-0' : ''"
          >
            <v-spacer />
            <v-pagination
              v-model="page"
              flat
              color="primary"
              :length="pageCount"
            />
            <v-spacer />
          </v-toolbar>
        </div>

        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import ConfirmDeleteForm from '@/components/form/ConfirmDeleteForm';
import Dialog from '@/components/display/Dialog';
import Thumbnail from '@/components/display/Thumbnail';
import GeneratePaymentRequestViewerReport from '@/components/display/GeneratePaymentRequestViewerReport';
import CompanySelector from '@/components/form/field/CompanySelector';
import DatePickerRange from '@/components/form/field/DatePickerRange';

/* components: {
    Form: () => import('@/components/form/BrandForm'),
  }, */

export default {
  name: 'CrudTable',
  components: {
    // ConfirmDeleteForm,
    Dialog,
    Thumbnail,
    GeneratePaymentRequestViewerReport,
    CompanySelector,
    DatePickerRange,
  },
  props: {
    headers: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    groups: {
      type: Array || null,
      default: null,
    },
    entity: {
      type: String,
      default: null,
    },
    model: {
      type: String,
      default: null,
    },
    addModel: {
      type: String,
      default: null,
    },
    createModel: {
      type: String,
      default: null,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    parentKey: {
      type: String,
      default: null,
    },
    parentValue: {
      type: Number,
      default: null,
    },
    canDownloadPdf: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },

  data: () => ({
    form: {
      fields: {
        dateRange: {
          value: [],
          editedValue: [],
          label: 'common.date_range',
        },
        companyCode: {
          value: '',
          editedValue: '',
          label: 'data_label.customer',
        },
      },
    },
    search: '',
    page: 1,
    pageCount: 0,
    itemsPerPage: 20,
    showSelect: false,
    selected: [],
    dialog: {
      create: false,
      edit: false,
      delete: false,
      view: false,
      viewMini: false,
      addPaymentRequest: false,
      addInventory: false,
      addStockCheck: false,
      addPartRequest: false,
    },
    selectedId: null,
    selectedIndex: null,
    groupBy: {
      text: null,
      value: null,
    },
    tableHeight: '100px',
  }),
  computed: {
    /* workflow() {
      // eslint-disable-next-line no-console
      console.log('**<computed>workflow');
      return this.$store
        .$db()
        .model('workflow')
        .query()
        .where('entity', this.entity)
        .with('steps')
        .first();
    }, */
    workflow() {
      return null;
    },
    computedIndex() {
      // eslint-disable-next-line no-console
      console.log('**<computed>computedIndex');
      return (this.page - 1) * this.itemsPerPage + this.selectedIndex;
    },
    /* currentStep() {
      return this.$store
        .$db()
        .model('step')
        .query()
        .where((step) => {
          return (
            step.workflow_id === this.workflow.id &&
            step.step === this.item.step
          );
        })
        .with('workflow')
        .first();
    },
    currentApproval() {
      return this.$store
        .$db()
        .model(this.entity + '_approval')
        .query()
        .where((approval) => {
          return (
            approval[this.entity + '_id'] === this.id &&
            approval.step === this.item.step
          );
        })
        .with('workflow')
        .first();
    }, */
    /* selectedLength() {
      // eslint-disable-next-line no-console
      console.log('**<computed>selectedLength');
      return this.selected.length;
    }, */
    dateRangeFilterActive() {
      return this.form.fields.dateRange.editedValue.length === 2;
    },
    companyFilterActive() {
      return (
        this.form.fields.companyCode.editedValue !== '' &&
        this.form.fields.companyCode.editedValue !== null
      );
    },
    companyAndDateRangeFilterActive() {
      return this.dateRangeFilterActive && this.companyFilterActive;
    },
    dialogClosed() {
      // eslint-disable-next-line no-console
      console.log('**<computed>dialogClosed');
      if (
        this.dialog.create === false &&
        this.dialog.edit === false &&
        this.dialog.delete === false &&
        this.dialog.view === false
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    items(newVal) {
      // eslint-disable-next-line no-console
      console.log(`Length:  ${newVal.length}`);

      for (const item of newVal) {
        // eslint-disable-next-line no-console
        console.log(`Item Updated:  ${item.document_code}`);
      }
    },
    'form.fields.dateRange.editedValue.length'(newVal, oldVal) {
      // eslint-disable-next-line no-console
      console.log('New date Range = ' + this.form.fields.dateRange.editedValue);
      // const tempSearch = this.search;
      if (newVal === 2) {
        if (
          this.form.fields.dateRange.editedValue[0] >
          this.form.fields.dateRange.editedValue[1]
        ) {
          this.form.fields.dateRange.editedValue.reverse();
        }
        this.search = 'Between Dates:';
      } else {
        this.search = '';
      }
    },
    'form.fields.companyCode.editedValue'(newVal, oldVal) {
      // eslint-disable-next-line no-console
      console.log('New company code = ' + newVal);
      // const tempSearch = this.search;
      if (newVal !== null && newVal !== '') {
        this.search = 'Company:';
      } else if (this.form.fields.dateRange.editedValue.length === 2) {
        this.search = 'Between Dates:';
      } else {
        this.search = '';
      }
    },
    dialogClosed(newVal) {
      // eslint-disable-next-line no-console
      console.log('**<watch>dialogClosed');
      if (newVal) {
        // eslint-disable-next-line no-console
        console.log('Reset Model:' + this.model);
        this.$options.components.Form = () =>
          import(`@/components/view/${this.model}View`);
      }
    },
  },
  created() {
    // eslint-disable-next-line no-console
    console.log('ROLE_USER: ' + this.$auth.hasScope('ROLE_USER'));
    // eslint-disable-next-line no-console
    console.log('ROLE_SUPPLIER: ' + this.$auth.hasScope('ROLE_SUPPLIER'));
    // eslint-disable-next-line no-console
    console.log('ROLE_ADMIN: ' + this.$auth.hasScope('ROLE_ADMIN'));
    // eslint-disable-next-line no-console
    console.log('New Model:' + this.model);

    this.itemsPerPage = this.mini === true ? 5 : this.itemsPerPage;
    this.setAddForm();
    this.setCreateForm();
    this.setMiniForm();
    this.setForm();
  },
  methods: {
    setAddForm() {
      this.$options.components.AddForm = () =>
        import(
          `@/components/view/${this.addModel ? this.addModel : this.model}View`
        );
    },
    setCreateForm() {
      this.$options.components.CreateForm = () =>
        import(
          `@/components/view/${
            this.createModel ? this.createModel : this.model
          }View`
        );
    },
    setMiniForm() {
      if (this.mini) {
        this.$options.components.MiniForm = () =>
          import(
            `@/components/view/${
              this.createModel ? this.createModel : this.model
            }View`
          );
      }
    },
    setForm() {
      if (!this.mini) {
        this.$options.components.Form = () =>
          import(
            `@/components/view/${
              this.createModel ? this.createModel : this.model
            }View`
          );
      }
    },
    resetGroupBy() {
      // eslint-disable-next-line no-console
      console.log('**<method>resetGroupBy');
      this.groupBy = {
        text: null,
        value: null,
      };
    },
    isStepAuthorizedGroup(step) {
      // eslint-disable-next-line no-console
      console.log('**<method>isStepAuthorizedGroup: ');
      if (!this.$auth.user?.groups?.[step?.group_id]) {
        // eslint-disable-next-line no-console
        console.log(`Step ${step?.step}: false`);
        return false;
      }

      // eslint-disable-next-line no-console
      console.log(`Step ${step?.step}: true`);
      return true;
    },
    isAuthorizedGroup(step) {
      // eslint-disable-next-line no-console
      console.log('**<method>isAuthorizedGroup');
      let groupCount = 0;
      if (
        this.$auth &&
        this.$auth.loggedIn &&
        this.$auth.user.employee.groups
      ) {
        this.$auth.user.employee.groups.forEach((group) => {
          // eslint-disable-next-line no-console
          // console.log('Group: ' + group.id);
          if (this.workflow && this.workflow.steps) {
            this.workflow.steps.forEach((workflowStep) => {
              // eslint-disable-next-line no-console
              // console.log('Workflow Steo: ' + workflowStep.step);
              // eslint-disable-next-line no-console
              // console.log('Workflow Group: ' + workflowStep.group_id);
              if (
                workflowStep.step === step &&
                workflowStep.group_id === group.id
              ) {
                // eslint-disable-next-line no-console
                // console.log('Match');
                groupCount++;
              }
            });
          }
        });
      }
      return groupCount > 0;
    },
    viewItem(event, { item, index }) {
      // eslint-disable-next-line no-console
      console.log('**<method>viewItem');
      if (this.entity === 'payment_request' && this.mini === false) {
        this.$router.push(`${this.$route.fullPath}/${item.document_code}`);
      } else if (
        this.entity !== 'payment_request' &&
        this.isStepAuthorizedGroup(item.step)
      ) {
        this.setForm();
        this.dialog.edit = true;
      } else if (this.mini === true) {
        this.setForm();
        this.dialog.viewMini = true;
      } else {
        this.setForm();
        this.dialog.view = true;
      }
      this.selectedId = item.id;
      this.selectedIndex = index;
    },
    filterCompany(value, search, item) {
      // eslint-disable-next-line no-console
      console.log(`filterCompany`);
      const companyCode = item.company.code;
      const companyCodeMatch =
        companyCode === this.form.fields.companyCode.editedValue;

      return companyCodeMatch;
    },
    filterDateRange(value, search, item) {
      // eslint-disable-next-line no-console
      console.log(`filterDateRange`);
      const createdAt = new Date(item.created_at);
      const fromDate = new Date(
        this.form.fields.dateRange.editedValue[0] + ' 00:00:00.000'
      );
      const toDate = new Date(
        this.form.fields.dateRange.editedValue[1] + ' 23:59:59.999'
      );
      const dateRangeMatch = createdAt >= fromDate && createdAt <= toDate;

      return dateRangeMatch;
    },
    filterCompanyAndDateRange(value, search, item) {
      // eslint-disable-next-line no-console
      console.log(`filterCompanyAndDateRange`);
      const companyCode = item.company.code;
      const companyCodeMatch =
        companyCode === this.form.fields.companyCode.editedValue;

      const createdAt = new Date(item.created_at);
      const fromDate = new Date(
        this.form.fields.dateRange.editedValue[0] + ' 00:00:00.000'
      );
      const toDate = new Date(
        this.form.fields.dateRange.editedValue[1] + ' 23:59:59.999'
      );
      const dateRangeMatch = createdAt >= fromDate && createdAt <= toDate;

      return companyCodeMatch && dateRangeMatch;
    },
  },
};
</script>
