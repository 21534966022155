import { Model } from '@vuex-orm/core';
import Employee from './employee';
import PersonFile from './person_file';
import Country from './country';
import Gender from './gender';

export default class Person extends Model {
  static entity = 'person';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      full_name: this.string(null).nullable(),
      preferred_name: this.string(null).nullable(),
      telephone: this.string(null).nullable(),
      email: this.string(null).nullable(),
      date_of_birth: this.attr(null),
      gender_id: this.number(null).nullable(),
      country_id: this.number(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      country: this.belongsTo(Country, 'country_id'),
      gender: this.belongsTo(Gender, 'gender_id'),
      employee: this.hasOne(Employee, 'person_id'),
      person_files: this.hasMany(PersonFile, 'person_id'),
    };
  }

  get nameWithTelephone() {
    return `${this.full_name} - ${this.telephone}`;
  }
}
