<template>
  <v-dialog
    ref="dialog"
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.width < 350"
    width="290px"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="dateRangeText"
        :label="$t(label)"
        append-icon="mdi-calendar-filter"
        :required="required"
        :locale="$i18n.locale"
        color="accent"
        dense
        flat
        hide-details
        single-line
        solo
        text
        clearable
        readonly
        role="search"
        v-bind="attrs"
        v-on="on"
        @click:append="$emit('update:editedValue', value)"
        @click:clear="$emit('update:editedValue', [])"
      ></v-text-field>
    </template>
    <v-date-picker
      :value="editedValue"
      :locale="$i18n.locale"
      :active-picker.sync="activePicker"
      scrollable
      range
      color="accent"
      :max="maximum"
      :min="minimum"
      @input="$emit('update:editedValue', $event)"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="accent"
        @click="updateEditedValue([]), (dialog = false)"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="editedValue === null"
        color="accent"
        @click="$refs.dialog.save(editedValue)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  name: 'DatePickerRange',
  props: {
    value: {
      type: Array,
      required: true,
    },
    editedValue: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    dateOfBirth: {
      type: Boolean,
      default: false,
    },
    yearsPast: {
      type: Number,
      default: 50,
    },
    yearsInFuture: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    dialog: false,
    activePicker: null,
  }),
  computed: {
    edited() {
      return this.editedValue !== this.value;
    },
    minimum() {
      return this.dateOfBirth === true
        ? this.getDateFromToday(-100)
        : this.getDateFromToday(-this.yearsPast);
    },
    maximum() {
      return this.dateOfBirth === true
        ? this.getDateFromToday(-16)
        : this.getDateFromToday(this.yearsInFuture);
    },
    dateRangeText() {
      if (this.editedValue.length !== 2) return '';
      else
        return `${this.$d(
          new Date(this.editedValue[0]),
          'short',
          'th'
        )}~${this.$d(new Date(this.editedValue[1]), 'short', 'th')}`;
    },
  },
  watch: {
    dialog(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'));
    },
  },
  methods: {
    updateEditedValue(value) {
      this.$emit('update:editedValue', value);
    },
    getDateFromToday(addYears) {
      const d = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
      const year = d.getFullYear() + addYears;

      const month = ('0' + (d.getMonth() + 1)).slice(-2);
      const date = ('0' + d.getDate()).slice(-2);

      const dateFromToday = `${year}-${month}-${date}`;
      // eslint-disable-next-line no-console
      console.log(`Date: ${dateFromToday}`);
      return dateFromToday;
    },
  },
};
</script>
