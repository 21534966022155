import { Model } from '@vuex-orm/core';
import PaymentRequestFile from './payment_request_file';
import Company from './company';
import PaymentType from './payment_type';
import Step from './step';
import Supplier from './supplier';
import SupplierBankAccount from './supplier_bank_account';
import SupplierEmployee from './supplier_employee';

export default class PaymentRequest extends Model {
  static entity = 'payment_request';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      document_code: this.string(null).nullable(),
      step_id: this.number(null).nullable(),
      company_id: this.number(null).nullable(),
      supplier_id: this.number(null).nullable(),
      supplier_employee_id: this.number(null).nullable(),
      payment_due_date: this.attr(null),
      payment_type_id: this.number(null).nullable(),
      supplier_bank_account_id: this.number(null).nullable(),
      attachments_total: this.number(null).nullable(),
      attachments_approved: this.boolean(),
      attachments_rejected: this.boolean(),
      attachments_rejected_reason: this.string(null).nullable(),
      payment_made: this.boolean(),
      payment_rejected: this.boolean(),
      payment_rejected_reason: this.string(null).nullable(),
      payment_complete: this.boolean(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      company: this.belongsTo(Company, 'company_id'),
      payment_request_files: this.hasMany(
        PaymentRequestFile,
        'payment_request_id'
      ),
      payment_type: this.belongsTo(PaymentType, 'payment_type_id'),
      step: this.belongsTo(Step, 'step_id'),
      supplier: this.belongsTo(Supplier, 'supplier_id'),
      supplier_bank_account: this.belongsTo(
        SupplierBankAccount,
        'supplier_bank_account_id'
      ),
      supplier_employee: this.belongsTo(
        SupplierEmployee,
        'supplier_employee_id'
      ),
    };
  }
}
