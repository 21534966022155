<template>
  <v-alert
    v-if="message.status !== ''"
    v-model="show"
    :type="message.status"
    :color="color ? color : message.status"
    border="left"
    dismissible
    transition="fade-transition"
  >
    {{ message.text }}
  </v-alert>
</template>
<script>
export default {
  name: 'Message',

  props: {
    message: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: true,
    };
  },

  watch: {
    'message.status'(newVal) {
      if (newVal) {
        this.show = true;
      }
    },
    'message.text'(newVal) {
      if (newVal) {
        this.show = true;
      }
    },
  },
};
</script>
