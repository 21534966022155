import { Model } from '@vuex-orm/core';
import File from './file';
import Person from './person';

export default class PersonFile extends Model {
  static entity = 'person_file';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      person_id: this.number(null).nullable(),
      file_id: this.number(null).nullable(),
      is_default: this.boolean(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      file: this.belongsTo(File, 'file_id'),
      person: this.belongsTo(Person, 'person_id'),
    };
  }
}
