import { Model } from '@vuex-orm/core';
import Role from './role';
import User from './user';

export default class UserRole extends Model {
  static entity = 'user_role';

  static primaryKey = ['user_id', 'role_id'];

  static fields() {
    return {
      // Fields
      user_id: this.number(null).nullable(),
      role_id: this.number(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      role: this.belongsTo(Role, 'role_id'),
      user: this.belongsTo(User, 'user_id'),
    };
  }
}
