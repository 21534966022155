<template>
  <div>
    <v-progress-linear
      :indeterminate="syncing"
      aria-label="API Sync Progress"
    />
    <slot></slot>
  </div>
</template>

<script>
import Address from '@/models/address';
import AddressType from '@/models/address_type';
import Bank from '@/models/bank';
import Branch from '@/models/branch';
import Company from '@/models/company';
import CompanyEmployee from '@/models/company_employee';
import CompanyGroup from '@/models/company_group';
import Country from '@/models/country';
import CountryCurrency from '@/models/country_currency';
import Currency from '@/models/currency';
import DocumentType from '@/models/document_type';
import Employee from '@/models/employee';
import EmployeeGroup from '@/models/employee_group';
import EntityCategory from '@/models/entity_category';
import File from '@/models/file';
import Gender from '@/models/gender';
import Group from '@/models/group';
import GroupPermission from '@/models/group_permission';
import PaymentRequest from '@/models/payment_request';
import PaymentRequestFile from '@/models/payment_request_file';
import PaymentRequestRequiredAttachment from '@/models/payment_request_required_attachment';
import PaymentType from '@/models/payment_type';
import Person from '@/models/person';
import PersonFile from '@/models/person_file';
import Role from '@/models/role';
import Step from '@/models/step';
import Supplier from '@/models/supplier';
import SupplierBankAccount from '@/models/supplier_bank_account';
import SupplierEmployee from '@/models/supplier_employee';
import Sync from '@/models/sync';
import User from '@/models/user';
import UserRole from '@/models/user_role';
export default {
  name: 'SyncData',
  props: {},
  data: () => ({
    syncActive: false,
    totalToSync: 0,
    leftToSync: 0,
    currentlySyncing: 0,
    syncInterval: null,
  }),
  computed: {
    syncing() {
      return this.leftToSync > 0 || this.currentlySyncing > 0;
    },
    syncAddress() {
      if (!this.syncActive) return false;

      const addressSyncInfo = Sync.query().whereId('address').first();
      if (!addressSyncInfo || addressSyncInfo.count === 0) return 'noSync';

      const addresses = Address.query().get();
      if (
        addressSyncInfo.count > 0 &&
        addressSyncInfo.count !== addresses.length
      )
        return true;

      const lastDbUpdate = new Date(addressSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = addresses.every((address) => {
        const lastLocalUpdate = new Date(address.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncAddressType() {
      if (!this.syncActive) return false;

      const addressTypeSyncInfo = Sync.query().whereId('address_type').first();
      if (!addressTypeSyncInfo || addressTypeSyncInfo.count === 0)
        return 'noSync';

      const addressTypes = AddressType.query().get();
      if (
        addressTypeSyncInfo.count > 0 &&
        addressTypeSyncInfo.count !== addressTypes.length
      )
        return true;

      const lastDbUpdate = new Date(addressTypeSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = addressTypes.every((addressType) => {
        const lastLocalUpdate = new Date(addressType.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncBank() {
      if (!this.syncActive) return false;

      const bankSyncInfo = Sync.query().whereId('bank').first();
      if (!bankSyncInfo || bankSyncInfo.count === 0) return 'noSync';

      const banks = Bank.query().get();
      if (bankSyncInfo.count > 0 && bankSyncInfo.count !== banks.length)
        return true;

      const lastDbUpdate = new Date(bankSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = banks.every((bank) => {
        const lastLocalUpdate = new Date(bank.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncBranch() {
      if (!this.syncActive) return false;

      const branchSyncInfo = Sync.query().whereId('branch').first();
      if (!branchSyncInfo || branchSyncInfo.count === 0) return 'noSync';

      const branches = Branch.query().get();
      if (branchSyncInfo.count > 0 && branchSyncInfo.count !== branches.length)
        return true;

      const lastDbUpdate = new Date(branchSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = branches.every((branch) => {
        const lastLocalUpdate = new Date(branch.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncCompany() {
      if (!this.syncActive) return false;

      const companySyncInfo = Sync.query().whereId('company').first();
      if (!companySyncInfo || companySyncInfo.count === 0) return 'noSync';

      const companies = Company.query().get();
      if (
        companySyncInfo.count > 0 &&
        companySyncInfo.count !== companies.length
      )
        return true;

      const lastDbUpdate = new Date(companySyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = companies.every((company) => {
        const lastLocalUpdate = new Date(company.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncCompanyEmployee() {
      if (!this.syncActive) return false;

      const companyEmployeeSyncInfo = Sync.query()
        .whereId('company_employee')
        .first();
      if (!companyEmployeeSyncInfo || companyEmployeeSyncInfo.count === 0)
        return 'noSync';

      const companyEmployees = CompanyEmployee.query().get();
      if (
        companyEmployeeSyncInfo.count > 0 &&
        companyEmployeeSyncInfo.count !== companyEmployees.length
      )
        return true;

      const lastDbUpdate = new Date(companyEmployeeSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = companyEmployees.every(
        (companyEmployee) => {
          const lastLocalUpdate = new Date(companyEmployee.updated_at);
          return lastDbUpdate > lastLocalUpdate;
        }
      );

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncCompanyGroup() {
      if (!this.syncActive) return false;

      const companyGroupSyncInfo = Sync.query()
        .whereId('company_group')
        .first();
      if (!companyGroupSyncInfo || companyGroupSyncInfo.count === 0)
        return 'noSync';

      const companyGroups = CompanyGroup.query().get();
      if (
        companyGroupSyncInfo.count > 0 &&
        companyGroupSyncInfo.count !== companyGroups.length
      )
        return true;

      const lastDbUpdate = new Date(companyGroupSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = companyGroups.every((companyGroup) => {
        const lastLocalUpdate = new Date(companyGroup.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncCountry() {
      if (!this.syncActive) return false;

      const countrySyncInfo = Sync.query().whereId('country').first();
      if (!countrySyncInfo || countrySyncInfo.count === 0) return 'noSync';

      const countries = Country.query().get();
      if (
        countrySyncInfo.count > 0 &&
        countrySyncInfo.count !== countries.length
      )
        return true;

      const lastDbUpdate = new Date(countrySyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = countries.every((country) => {
        const lastLocalUpdate = new Date(country.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncCountryCurrency() {
      if (!this.syncActive) return false;

      const countryCurrencySyncInfo = Sync.query()
        .whereId('country_currency')
        .first();
      if (!countryCurrencySyncInfo || countryCurrencySyncInfo.count === 0)
        return 'noSync';

      const countryCurrencies = CountryCurrency.query().get();
      if (
        countryCurrencySyncInfo.count > 0 &&
        countryCurrencySyncInfo.count !== countryCurrencies.length
      )
        return true;

      const lastDbUpdate = new Date(countryCurrencySyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = countryCurrencies.every(
        (countryCurrency) => {
          const lastLocalUpdate = new Date(countryCurrency.updated_at);
          return lastDbUpdate > lastLocalUpdate;
        }
      );

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncCurrency() {
      if (!this.syncActive) return false;

      const currencySyncInfo = Sync.query().whereId('currency').first();
      if (!currencySyncInfo || currencySyncInfo.count === 0) return 'noSync';

      const currencies = Currency.query().get();
      if (
        currencySyncInfo.count > 0 &&
        currencySyncInfo.count !== currencies.length
      )
        return true;

      const lastDbUpdate = new Date(currencySyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = currencies.every((currency) => {
        const lastLocalUpdate = new Date(currency.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncDocumentType() {
      if (!this.syncActive) return false;

      const documentTypeSyncInfo = Sync.query()
        .whereId('document_type')
        .first();
      if (!documentTypeSyncInfo || documentTypeSyncInfo.count === 0)
        return 'noSync';

      const documentTypes = DocumentType.query().get();
      if (
        documentTypeSyncInfo.count > 0 &&
        documentTypeSyncInfo.count !== documentTypes.length
      )
        return true;

      const lastDbUpdate = new Date(documentTypeSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = documentTypes.every((documentType) => {
        const lastLocalUpdate = new Date(documentType.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncEmployee() {
      if (!this.syncActive) return false;

      const employeeSyncInfo = Sync.query().whereId('employee').first();
      if (!employeeSyncInfo || employeeSyncInfo.count === 0) return 'noSync';

      const employees = Employee.query().get();
      if (
        employeeSyncInfo.count > 0 &&
        employeeSyncInfo.count !== employees.length
      )
        return true;

      const lastDbUpdate = new Date(employeeSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = employees.every((employee) => {
        const lastLocalUpdate = new Date(employee.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncEmployeeGroup() {
      if (!this.syncActive) return false;

      const employeeGroupSyncInfo = Sync.query()
        .whereId('employee_group')
        .first();
      if (!employeeGroupSyncInfo || employeeGroupSyncInfo.count === 0)
        return 'noSync';

      const employeeGroups = EmployeeGroup.query().get();
      if (
        employeeGroupSyncInfo.count > 0 &&
        employeeGroupSyncInfo.count !== employeeGroups.length
      )
        return true;

      const lastDbUpdate = new Date(employeeGroupSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = employeeGroups.every((employeeGroup) => {
        const lastLocalUpdate = new Date(employeeGroup.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncEntityCategory() {
      if (!this.syncActive) return false;

      const entityCategorySyncInfo = Sync.query()
        .whereId('entity_category')
        .first();
      if (!entityCategorySyncInfo || entityCategorySyncInfo.count === 0)
        return 'noSync';

      const entityCategories = EntityCategory.query().get();
      if (
        entityCategorySyncInfo.count > 0 &&
        entityCategorySyncInfo.count !== entityCategories.length
      )
        return true;

      const lastDbUpdate = new Date(entityCategorySyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = entityCategories.every(
        (entityCategory) => {
          const lastLocalUpdate = new Date(entityCategory.updated_at);
          return lastDbUpdate > lastLocalUpdate;
        }
      );

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncFile() {
      if (!this.syncActive) return false;

      const fileSyncInfo = Sync.query().whereId('file').first();
      if (!fileSyncInfo || fileSyncInfo.count === 0) return 'noSync';

      const files = File.query().get();
      if (fileSyncInfo.count > 0 && fileSyncInfo.count !== files.length)
        return true;

      const lastDbUpdate = new Date(fileSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = files.every((file) => {
        const lastLocalUpdate = new Date(file.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncGender() {
      if (!this.syncActive) return false;

      const genderSyncInfo = Sync.query().whereId('gender').first();
      if (!genderSyncInfo || genderSyncInfo.count === 0) return 'noSync';

      const genders = Gender.query().get();
      if (genderSyncInfo.count > 0 && genderSyncInfo.count !== genders.length)
        return true;

      const lastDbUpdate = new Date(genderSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = genders.every((gender) => {
        const lastLocalUpdate = new Date(gender.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncGroup() {
      if (!this.syncActive) return false;

      const groupSyncInfo = Sync.query().whereId('group').first();
      if (!groupSyncInfo || groupSyncInfo.count === 0) return 'noSync';

      const groups = Group.query().get();
      if (groupSyncInfo.count > 0 && groupSyncInfo.count !== groups.length)
        return true;

      const lastDbUpdate = new Date(groupSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = groups.every((group) => {
        const lastLocalUpdate = new Date(group.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncGroupPermission() {
      if (!this.syncActive) return false;

      const groupPermissionSyncInfo = Sync.query()
        .whereId('group_permission')
        .first();
      if (!groupPermissionSyncInfo || groupPermissionSyncInfo.count === 0)
        return 'noSync';

      const groupPermissions = GroupPermission.query().get();
      if (
        groupPermissionSyncInfo.count > 0 &&
        groupPermissionSyncInfo.count !== groupPermissions.length
      )
        return true;

      const lastDbUpdate = new Date(groupPermissionSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = groupPermissions.every(
        (groupPermission) => {
          const lastLocalUpdate = new Date(groupPermission.updated_at);
          return lastDbUpdate > lastLocalUpdate;
        }
      );

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncPaymentRequest() {
      if (!this.syncActive) return false;

      const paymentRequestSyncInfo = Sync.query()
        .whereId('payment_request')
        .first();
      if (!paymentRequestSyncInfo || paymentRequestSyncInfo.count === 0)
        return 'noSync';

      const paymentRequests = PaymentRequest.query().get();
      if (
        paymentRequestSyncInfo.count > 0 &&
        paymentRequestSyncInfo.count !== paymentRequests.length
      )
        return true;

      const lastDbUpdate = new Date(paymentRequestSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = paymentRequests.every((paymentRequest) => {
        const lastLocalUpdate = new Date(paymentRequest.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncPaymentRequestFile() {
      if (!this.syncActive) return false;

      const paymentRequestFileSyncInfo = Sync.query()
        .whereId('payment_request_file')
        .first();
      if (!paymentRequestFileSyncInfo || paymentRequestFileSyncInfo.count === 0)
        return 'noSync';

      const paymentRequestFiles = PaymentRequestFile.query().get();
      if (
        paymentRequestFileSyncInfo.count > 0 &&
        paymentRequestFileSyncInfo.count !== paymentRequestFiles.length
      )
        return true;

      const lastDbUpdate = new Date(paymentRequestFileSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = paymentRequestFiles.every(
        (paymentRequestFile) => {
          const lastLocalUpdate = new Date(paymentRequestFile.updated_at);
          return lastDbUpdate > lastLocalUpdate;
        }
      );

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncPaymentRequestRequiredAttachment() {
      if (!this.syncActive) return false;

      const paymentRequestRequiredAttachmentSyncInfo = Sync.query()
        .whereId('payment_request_required_attachment')
        .first();
      if (
        !paymentRequestRequiredAttachmentSyncInfo ||
        paymentRequestRequiredAttachmentSyncInfo.count === 0
      )
        return 'noSync';

      const paymentRequestRequiredAttachments =
        PaymentRequestRequiredAttachment.query().get();
      if (
        paymentRequestRequiredAttachmentSyncInfo.count > 0 &&
        paymentRequestRequiredAttachmentSyncInfo.count !==
          paymentRequestRequiredAttachments.length
      )
        return true;

      const lastDbUpdate = new Date(
        paymentRequestRequiredAttachmentSyncInfo.last_updated_at
      );
      const dbUpdateLaterThanLocal = paymentRequestRequiredAttachments.every(
        (paymentRequestRequiredAttachment) => {
          const lastLocalUpdate = new Date(
            paymentRequestRequiredAttachment.updated_at
          );
          return lastDbUpdate > lastLocalUpdate;
        }
      );

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncPaymentType() {
      if (!this.syncActive) return false;

      const paymentTypeSyncInfo = Sync.query().whereId('payment_type').first();
      if (!paymentTypeSyncInfo || paymentTypeSyncInfo.count === 0)
        return 'noSync';

      const paymentTypes = PaymentType.query().get();
      if (
        paymentTypeSyncInfo.count > 0 &&
        paymentTypeSyncInfo.count !== paymentTypes.length
      )
        return true;

      const lastDbUpdate = new Date(paymentTypeSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = paymentTypes.every((paymentType) => {
        const lastLocalUpdate = new Date(paymentType.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncPerson() {
      if (!this.syncActive) return false;

      const personSyncInfo = Sync.query().whereId('person').first();
      if (!personSyncInfo || personSyncInfo.count === 0) return 'noSync';

      const people = Person.query().get();
      if (personSyncInfo.count > 0 && personSyncInfo.count !== people.length)
        return true;

      const lastDbUpdate = new Date(personSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = people.every((person) => {
        const lastLocalUpdate = new Date(person.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncPersonFile() {
      if (!this.syncActive) return false;

      const personFileSyncInfo = Sync.query().whereId('person_file').first();
      if (!personFileSyncInfo || personFileSyncInfo.count === 0)
        return 'noSync';

      const personFiles = PersonFile.query().get();
      if (
        personFileSyncInfo.count > 0 &&
        personFileSyncInfo.count !== personFiles.length
      )
        return true;

      const lastDbUpdate = new Date(personFileSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = personFiles.every((personFile) => {
        const lastLocalUpdate = new Date(personFile.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncRole() {
      if (!this.syncActive) return false;

      const roleSyncInfo = Sync.query().whereId('role').first();
      if (!roleSyncInfo || roleSyncInfo.count === 0) return 'noSync';

      const roles = Role.query().get();
      if (roleSyncInfo.count > 0 && roleSyncInfo.count !== roles.length)
        return true;

      const lastDbUpdate = new Date(roleSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = roles.every((role) => {
        const lastLocalUpdate = new Date(role.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncStep() {
      if (!this.syncActive) return false;

      const stepSyncInfo = Sync.query().whereId('step').first();
      if (!stepSyncInfo || stepSyncInfo.count === 0) return 'noSync';

      const steps = Step.query().get();
      if (stepSyncInfo.count > 0 && stepSyncInfo.count !== steps.length)
        return true;

      const lastDbUpdate = new Date(stepSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = steps.every((step) => {
        const lastLocalUpdate = new Date(step.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncSupplier() {
      if (!this.syncActive) return false;

      const supplierSyncInfo = Sync.query().whereId('supplier').first();
      if (!supplierSyncInfo || supplierSyncInfo.count === 0) return 'noSync';

      const suppliers = Supplier.query().get();
      if (
        supplierSyncInfo.count > 0 &&
        supplierSyncInfo.count !== suppliers.length
      )
        return true;

      const lastDbUpdate = new Date(supplierSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = suppliers.every((supplier) => {
        const lastLocalUpdate = new Date(supplier.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncSupplierBankAccount() {
      if (!this.syncActive) return false;

      const supplierBankAccountSyncInfo = Sync.query()
        .whereId('supplier_bank_account')
        .first();
      if (
        !supplierBankAccountSyncInfo ||
        supplierBankAccountSyncInfo.count === 0
      )
        return 'noSync';

      const supplierBankAccounts = SupplierBankAccount.query().get();
      if (
        supplierBankAccountSyncInfo.count > 0 &&
        supplierBankAccountSyncInfo.count !== supplierBankAccounts.length
      )
        return true;

      const lastDbUpdate = new Date(
        supplierBankAccountSyncInfo.last_updated_at
      );
      const dbUpdateLaterThanLocal = supplierBankAccounts.every(
        (supplierBankAccount) => {
          const lastLocalUpdate = new Date(supplierBankAccount.updated_at);
          return lastDbUpdate > lastLocalUpdate;
        }
      );

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncSupplierEmployee() {
      if (!this.syncActive) return false;

      const supplierEmployeeSyncInfo = Sync.query()
        .whereId('supplier_employee')
        .first();
      if (!supplierEmployeeSyncInfo || supplierEmployeeSyncInfo.count === 0)
        return 'noSync';

      const supplierEmployees = SupplierEmployee.query().get();
      if (
        supplierEmployeeSyncInfo.count > 0 &&
        supplierEmployeeSyncInfo.count !== supplierEmployees.length
      )
        return true;

      const lastDbUpdate = new Date(supplierEmployeeSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = supplierEmployees.every(
        (supplierEmployee) => {
          const lastLocalUpdate = new Date(supplierEmployee.updated_at);
          return lastDbUpdate > lastLocalUpdate;
        }
      );

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncUser() {
      if (!this.syncActive) return false;

      const userSyncInfo = Sync.query().whereId('user').first();
      if (!userSyncInfo || userSyncInfo.count === 0) return 'noSync';

      const users = User.query().get();
      if (userSyncInfo.count > 0 && userSyncInfo.count !== users.length)
        return true;

      const lastDbUpdate = new Date(userSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = users.every((user) => {
        const lastLocalUpdate = new Date(user.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
    syncUserRole() {
      if (!this.syncActive) return false;

      const userRoleSyncInfo = Sync.query().whereId('user_role').first();
      if (!userRoleSyncInfo || userRoleSyncInfo.count === 0) return 'noSync';

      const userRoles = UserRole.query().get();
      if (
        userRoleSyncInfo.count > 0 &&
        userRoleSyncInfo.count !== userRoles.length
      )
        return true;

      const lastDbUpdate = new Date(userRoleSyncInfo.last_updated_at);
      const dbUpdateLaterThanLocal = userRoles.every((userRole) => {
        const lastLocalUpdate = new Date(userRole.updated_at);
        return lastDbUpdate > lastLocalUpdate;
      });

      if (!dbUpdateLaterThanLocal) return 'noSync';
      return true;
    },
  },
  watch: {
    syncing(newVal) {
      // eslint-disable-next-line no-console
      console.log(`syncing: ${newVal}`);
      if (newVal === false) {
        this.resetSyncActive();
      }
    },
    syncAddress(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getAddresses();
    },
    syncAddressType(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getAddressTypes();
    },
    syncBank(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getBanks();
    },
    syncBranch(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getBranches();
    },
    syncCompany(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getCompanies();
    },
    syncCompanyEmployee(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getCompanyEmployees();
    },
    syncCompanyGroup(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getCompanyGroups();
    },
    syncCountry(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getCountries();
    },
    syncCountryCurrency(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getCountryCurrencies();
    },
    syncCurrency(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getCurrencies();
    },
    syncDocumentType(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getDocumentTypes();
    },
    syncEmployee(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getEmployees();
    },
    syncEmployeeGroup(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getEmployeeGroups();
    },
    syncEntityCategory(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getEntityCategories();
    },
    syncFile(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getFiles();
    },
    syncGender(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getGenders();
    },
    syncGroup(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getGroups();
    },
    syncGroupPermission(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getGroupPermissions();
    },
    syncPaymentRequest(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getPaymentRequests();
    },
    syncPaymentRequestFile(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getPaymentRequestFiles();
    },
    syncPaymentRequestRequiredAttachment(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getPaymentRequestRequiredAttachments();
    },
    syncPaymentType(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getPaymentTypes();
    },
    syncPerson(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getPeople();
    },
    syncPersonFile(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getPersonFiles();
    },
    syncRole(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getRoles();
    },
    syncStep(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getSteps();
    },
    syncSupplier(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getSuppliers();
    },
    syncSupplierBankAccount(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getSupplierBankAccounts();
    },
    syncSupplierEmployee(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getSupplierEmployees();
    },
    syncUser(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getUsers();
    },
    syncUserRole(newVal) {
      if (newVal === 'noSync') {
        this.leftToSync--;
      } else if (newVal) this.getUserRoles();
    },
  },
  mounted() {
    this.getSyncData();
    this.syncInterval = setInterval(() => this.getSyncData(), 30000);
  },
  destroyed() {
    clearInterval(this.syncInterval);
  },
  methods: {
    resetSyncActive() {
      this.syncActive = false;
      // eslint-disable-next-line no-console
      console.log('Sync Finished');
    },
    getSyncData() {
      if (this.currentlySyncing !== 0) return false;

      if (this.syncActive) {
        // eslint-disable-next-line no-console
        console.log('Previous Timed-out, retrying.');
        this.leftToSync = 0;
      }
      // eslint-disable-next-line no-console
      console.log('Sync Started');
      this.leftToSync = 1;
      Sync.api()
        .get('/api/sync/')
        .then((res) => {
          this.totalToSync = res.response.data.length + this.leftToSync;
          this.leftToSync = this.totalToSync;
          this.syncActive = true;
        })
        .then(() => this.leftToSync--)
        .catch((err) => {
          this.leftToSync--;
          // eslint-disable-next-line no-console
          console.log('Sync failed: ' + err);
        });
    },
    getAddresses() {
      // eslint-disable-next-line no-console
      console.log('Getting Addresses.');
      this.currentlySyncing++;
      Address.api()
        .get('/api/address/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting Addresses:' + err);
          this.currentlySyncing--;
        });
    },
    getAddressTypes() {
      // eslint-disable-next-line no-console
      console.log('Getting AddressTypes.');
      this.currentlySyncing++;
      AddressType.api()
        .get('/api/address_type/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting AddressTypes:' + err);
          this.currentlySyncing--;
        });
    },
    getBanks() {
      // eslint-disable-next-line no-console
      console.log('Getting Banks.');
      this.currentlySyncing++;
      Bank.api()
        .get('/api/bank/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting Banks:' + err);
          this.currentlySyncing--;
        });
    },
    getBranches() {
      // eslint-disable-next-line no-console
      console.log('Getting Branches.');
      this.currentlySyncing++;
      Branch.api()
        .get('/api/branch/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting Branches:' + err);
          this.currentlySyncing--;
        });
    },
    getCompanies() {
      // eslint-disable-next-line no-console
      console.log('Getting Companies.');
      this.currentlySyncing++;
      Company.api()
        .get('/api/company/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting Companies:' + err);
          this.currentlySyncing--;
        });
    },
    getCompanyEmployees() {
      // eslint-disable-next-line no-console
      console.log('Getting CompanyEmployees.');
      this.currentlySyncing++;
      CompanyEmployee.api()
        .get('/api/company_employee/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting CompanyEmployees:' + err);
          this.currentlySyncing--;
        });
    },
    getCompanyGroups() {
      // eslint-disable-next-line no-console
      console.log('Getting CompanyGroups.');
      this.currentlySyncing++;
      CompanyGroup.api()
        .get('/api/company_group/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting CompanyGroups:' + err);
          this.currentlySyncing--;
        });
    },
    getCountries() {
      // eslint-disable-next-line no-console
      console.log('Getting Countries.');
      this.currentlySyncing++;
      Country.api()
        .get('/api/country/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting Countries:' + err);
          this.currentlySyncing--;
        });
    },
    getCountryCurrencies() {
      // eslint-disable-next-line no-console
      console.log('Getting CountryCurrencies.');
      this.currentlySyncing++;
      CountryCurrency.api()
        .get('/api/country_currency/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting CountryCurrencies:' + err);
          this.currentlySyncing--;
        });
    },
    getCurrencies() {
      // eslint-disable-next-line no-console
      console.log('Getting Currencies.');
      this.currentlySyncing++;
      Currency.api()
        .get('/api/currency/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting Currencies:' + err);
          this.currentlySyncing--;
        });
    },
    getDocumentTypes() {
      // eslint-disable-next-line no-console
      console.log('Getting DocumentTypes.');
      this.currentlySyncing++;
      DocumentType.api()
        .get('/api/document_type/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting DocumentTypes:' + err);
          this.currentlySyncing--;
        });
    },
    getEmployees() {
      // eslint-disable-next-line no-console
      console.log('Getting Employees.');
      this.currentlySyncing++;
      Employee.api()
        .get('/api/employee/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting Employees:' + err);
          this.currentlySyncing--;
        });
    },
    getEmployeeGroups() {
      // eslint-disable-next-line no-console
      console.log('Getting EmployeeGroups.');
      this.currentlySyncing++;
      EmployeeGroup.api()
        .get('/api/employee_group/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting EmployeeGroups:' + err);
          this.currentlySyncing--;
        });
    },
    getEntityCategories() {
      // eslint-disable-next-line no-console
      console.log('Getting EntityCategories.');
      this.currentlySyncing++;
      EntityCategory.api()
        .get('/api/entity_category/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting EntityCategories:' + err);
          this.currentlySyncing--;
        });
    },
    getFiles() {
      // eslint-disable-next-line no-console
      console.log('Getting Files.');
      this.currentlySyncing++;
      File.api()
        .get('/api/file/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting Files:' + err);
          this.currentlySyncing--;
        });
    },
    getGenders() {
      // eslint-disable-next-line no-console
      console.log('Getting Genders.');
      this.currentlySyncing++;
      Gender.api()
        .get('/api/gender/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting Genders:' + err);
          this.currentlySyncing--;
        });
    },
    getGroups() {
      // eslint-disable-next-line no-console
      console.log('Getting Groups.');
      this.currentlySyncing++;
      Group.api()
        .get('/api/group/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting Groups:' + err);
          this.currentlySyncing--;
        });
    },
    getGroupPermissions() {
      // eslint-disable-next-line no-console
      console.log('Getting GroupPermissions.');
      this.currentlySyncing++;
      GroupPermission.api()
        .get('/api/group_permission/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting GroupPermissions:' + err);
          this.currentlySyncing--;
        });
    },
    getPaymentRequests() {
      // eslint-disable-next-line no-console
      console.log('Getting PaymentRequests.');
      this.currentlySyncing++;
      PaymentRequest.api()
        .get('/api/payment_request/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting PaymentRequests:' + err);
          this.currentlySyncing--;
        });
    },
    getPaymentRequestFiles() {
      // eslint-disable-next-line no-console
      console.log('Getting PaymentRequestFiles.');
      this.currentlySyncing++;
      PaymentRequestFile.api()
        .get('/api/payment_request_file/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting PaymentRequestFiles:' + err);
          this.currentlySyncing--;
        });
    },
    getPaymentRequestRequiredAttachments() {
      // eslint-disable-next-line no-console
      console.log('Getting PaymentRequestRequiredAttachments.');
      this.currentlySyncing++;
      PaymentRequestRequiredAttachment.api()
        .get('/api/payment_request_required_attachment/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting PaymentRequestRequiredAttachments:' + err);
          this.currentlySyncing--;
        });
    },
    getPaymentTypes() {
      // eslint-disable-next-line no-console
      console.log('Getting PaymentTypes.');
      this.currentlySyncing++;
      PaymentType.api()
        .get('/api/payment_type/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting PaymentTypes:' + err);
          this.currentlySyncing--;
        });
    },
    getPeople() {
      // eslint-disable-next-line no-console
      console.log('Getting People.');
      this.currentlySyncing++;
      Person.api()
        .get('/api/person/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting People:' + err);
          this.currentlySyncing--;
        });
    },
    getPersonFiles() {
      // eslint-disable-next-line no-console
      console.log('Getting PersonFiles.');
      this.currentlySyncing++;
      PersonFile.api()
        .get('/api/person_file/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting PersonFiles:' + err);
          this.currentlySyncing--;
        });
    },
    getRoles() {
      // eslint-disable-next-line no-console
      console.log('Getting Roles.');
      this.currentlySyncing++;
      Role.api()
        .get('/api/role/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting Roles:' + err);
          this.currentlySyncing--;
        });
    },
    getSteps() {
      // eslint-disable-next-line no-console
      console.log('Getting Steps.');
      this.currentlySyncing++;
      Step.api()
        .get('/api/step/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting Steps:' + err);
          this.currentlySyncing--;
        });
    },
    getSuppliers() {
      // eslint-disable-next-line no-console
      console.log('Getting Suppliers.');
      this.currentlySyncing++;
      Supplier.api()
        .get('/api/supplier/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting Suppliers:' + err);
          this.currentlySyncing--;
        });
    },
    getSupplierBankAccounts() {
      // eslint-disable-next-line no-console
      console.log('Getting SupplierBankAccounts.');
      this.currentlySyncing++;
      SupplierBankAccount.api()
        .get('/api/supplier_bank_account/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting SupplierBankAccounts:' + err);
          this.currentlySyncing--;
        });
    },
    getSupplierEmployees() {
      // eslint-disable-next-line no-console
      console.log('Getting SupplierEmployees.');
      this.currentlySyncing++;
      SupplierEmployee.api()
        .get('/api/supplier_employee/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting SupplierEmployees:' + err);
          this.currentlySyncing--;
        });
    },
    getUsers() {
      // eslint-disable-next-line no-console
      console.log('Getting Users.');
      this.currentlySyncing++;
      User.api()
        .get('/api/user/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting Users:' + err);
          this.currentlySyncing--;
        });
    },
    getUserRoles() {
      // eslint-disable-next-line no-console
      console.log('Getting UserRoles.');
      this.currentlySyncing++;
      UserRole.api()
        .get('/api/user_role/', {
          persistBy: 'create',
        })
        .then(() => {
          this.currentlySyncing--;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('Error Getting UserRoles:' + err);
          this.currentlySyncing--;
        });
    },
  },
};
</script>
