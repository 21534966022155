import { Model } from '@vuex-orm/core';

export default ({ $axios }) => {
  Model.setAxios($axios);

  if (process.client) {
    const host = window.location.hostname;
    $axios.setBaseURL('http://' + host + ':8080');
  }
};
