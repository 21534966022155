<template>
  <CrudTable
    :headers="headers"
    :items="items"
    :groups="groups"
    :mini="mini"
    :entity="entity"
    :model="model"
    :parent-key="parentKey"
    :parent-value="parentValue"
    :editable="editable"
  />
</template>

<script>
import CrudTable from '@/components/display/CrudTable.vue';

export default {
  name: 'GroupTable',
  components: { CrudTable },
  props: {
    items: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    parentKey: {
      type: String,
      default: null,
    },
    parentValue: {
      type: Number,
      default: null,
    },
    canDownloadPdf: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    entity: 'group',
    model: 'Group',
  }),
  computed: {
    headers() {
      return {
        small: [
          {
            text: this.$t('data_label.id'),
            filterable: false,
            value: '$id',
            align: ' d-none',
            groupable: false,
          },
          {
            text: this.$t('data_label.name'),
            value: 'name',
            groupable: false,
          },
        ],
        full: [
          {
            text: this.$t('data_label.id'),
            filterable: false,
            value: '$id',
            align: ' d-none d-sm-table-cell',
            groupable: false,
          },
          {
            text: this.$t('data_label.name'),
            value: 'name',
            groupable: false,
          },
          {
            text: '',
            value: 'actions',
            align: 'right',
            sortable: false,
            groupable: false,
          },
        ],
      };
    },
    groups() {
      return [];
    },
  },
};
</script>
