import { Model } from '@vuex-orm/core';
import PaymentRequest from './payment_request';
import SupplierBankAccount from './supplier_bank_account';
import SupplierEmployee from './supplier_employee';
import Branch from './branch';
import Company from './company';

export default class Supplier extends Model {
  static entity = 'supplier';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      company_id: this.number(null).nullable(),
      branch_id: this.number(null).nullable(),
      telephone: this.string(null).nullable(),
      email: this.string(null).nullable(),
      tax_code: this.string(null).nullable(),
      passcode: this.string(null).nullable(),
      otp: this.string(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      branch: this.belongsTo(Branch, 'branch_id'),
      company: this.belongsTo(Company, 'company_id'),
      payment_requests: this.hasMany(PaymentRequest, 'supplier_id'),
      supplier_bank_accounts: this.hasMany(SupplierBankAccount, 'supplier_id'),
      supplier_employees: this.hasMany(SupplierEmployee, 'supplier_id'),
    };
  }

  get description() {
    return `${
      this.branch && this.branch.company ? this.branch.company.name : ''
    } (${this.branch ? this.branch.name : ''})`;
  }
}
