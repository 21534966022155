<template>
  <v-card :id="`content-${pageName}`" height="100%" style="overflow-y: hidden">
    <v-img
      v-if="image"
      :src="image"
      width="80px"
      contain
      class="ma-auto mb-6"
    />
    <v-card
      flat
      :tile="isDialog ? false : $vuetify.breakpoint.xsOnly ? true : false"
      :class="
        isDialog
          ? ''
          : $vuetify.breakpoint.xsOnly
          ? 'primary lighten-1 px-0'
          : 'primary lighten-1'
      "
      justify-content="space-between"
      dense
    >
      <v-toolbar
        v-if="pageName !== null"
        flat
        dense
        :color="color"
        dark
        class="px-0"
      >
        <v-btn
          v-if="!isDialog && showBackButton === true"
          icon
          tile
          aria-label="Go back"
          @click="$router.go(-1)"
        >
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
        <v-toolbar-items v-else-if="icon" class="mr-2">
          <v-icon dark>{{ icon }}</v-icon>
        </v-toolbar-items>
        <v-toolbar-title class="overflow-x-hidden">
          <slot name="title" />
        </v-toolbar-title>
        <v-spacer />
        <slot name="items" />
        <v-spacer />
        <v-toolbar-items>
          <v-icon
            v-if="canDownloadPdf"
            tile
            class="no-print"
            @click="downloadPdf()"
            >mdi-file-download</v-icon
          >
          <slot name="download" />
          <slot name="email" />
          <slot name="print" />
          <slot name="close" />
        </v-toolbar-items>
      </v-toolbar>
      <v-container
        :id="`tab-container-${pageName}`"
        :style="
          isDialog
            ? `max-height: ${
                $vuetify.breakpoint.height - 48
              }px; overflow-y: auto`
            : ''
        "
        :class="hasTabs ? 'pa-0' : ''"
      >
        <slot />
      </v-container>
    </v-card>
  </v-card>
</template>

<script>
import { jsPDF as JsPDF } from 'jspdf';
import { toPng } from 'html-to-image';

export default {
  name: 'Page',
  props: {
    formType: {
      type: String,
      default: 'view',
    },
    pageName: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    canDownloadPdf: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    hasTabs: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    icon: 'mdi-eye',
    color: 'primary',
  }),
  created() {
    this.setIcon();
    this.setColor();
  },
  methods: {
    setIcon() {
      this.icon =
        this.formType === 'edit'
          ? 'mdi-pencil'
          : this.formType === 'create'
          ? 'mdi-plus'
          : this.formType === 'delete'
          ? 'mdi-delete'
          : this.formType === 'add_inventory'
          ? 'mdi-store-plus'
          : this.formType === 'generate_qr_code'
          ? 'mdi-qrcode'
          : this.formType === 'check_inventory'
          ? 'mdi-store-check'
          : this.formType === 'file-pdf'
          ? 'mdi-file-pdf-box'
          : this.formType === 'file-img'
          ? 'mdi-file-image'
          : this.formType === 'file'
          ? 'mdi-file'
          : this.formType === 'login'
          ? 'mdi-lock'
          : this.formType === 'redirect'
          ? 'mdi-arrow-decision'
          : this.formType === 'payment_request'
          ? 'mdi-receipt-text'
          : this.formType === 'sign_up_complete'
          ? 'mdi-unlock'
          : 'mdi-eye';
    },
    setColor() {
      this.color =
        this.formType === 'edit'
          ? 'warning darken-2'
          : this.formType === 'create'
          ? 'accent'
          : this.formType === 'delete'
          ? 'error darken-2'
          : this.formType === 'add_inventory'
          ? 'accent'
          : this.formType === 'generate_qr_code'
          ? 'accent'
          : this.formType === 'check_inventory'
          ? 'accent'
          : this.formType === 'file-pdf' ||
            this.formType === 'file-img' ||
            this.formType === 'file'
          ? 'accent'
          : 'primary';
    },
    downloadPdf() {
      // const dialogs = document.getElementById('v-dialog');
      const dialog = document.getElementsByClassName(`dialog-${this.pageName}`);
      const node = document.getElementById(`content-${this.pageName}`);
      const tabContainer = document.getElementById(
        `tab-container-${this.pageName}`
      );
      const downloadProgress = document.getElementById(
        `download-progress-${this.pageName}`
      );
      const body = document.getElementsByTagName('body');

      const hide = document.getElementsByClassName('no-print');
      const pdfColumns = document.getElementsByClassName('col-pdf');

      // eslint-disable-next-line no-console
      // console.log('Dialog height: ' + dialog[0].parentNode.offsetHeight);
      downloadProgress.style.display = 'block';

      downloadProgress.style.height = dialog[0].parentNode.offsetHeight + 'px';

      if (this.$vuetify.breakpoint.width < 600) {
        downloadProgress.parentNode.style.height =
          dialog[0].parentNode.offsetHeight + 'px';
      }

      // eslint-disable-next-line no-console
      // console.log('Download height: ' + downloadProgress.style.height);

      const originalOverflowY = tabContainer.style.overflowY;
      const originalMaxHeight = tabContainer.style.maxHeight;
      tabContainer.style.overflowY = 'hidden';
      tabContainer.style.maxHeight = '';

      const originalClass = [];

      for (let i = 0; i < pdfColumns.length; i++) {
        originalClass[i] = pdfColumns[i].className;
        pdfColumns[i].className = 'col-pdf col-6 pr-1 mb-2';
      }

      node.style.width = '800px';

      dialog[0].style = `width: 800px; height: ${
        dialog[0].offsetHeight + 'px'
      }; transform-origin: center center; max-width: 800px; overflow-y: hidden`;

      const originalBodyStyleOverflow = body[0].style.overflow;
      body[0].style.overflow = 'hidden';

      const originalHideStyleDisplay = [];
      for (let i = 0; i < hide.length; i++) {
        originalHideStyleDisplay.push(hide[i].style.display);
        hide[i].style.display = 'none';
      }

      // eslint-disable-next-line no-console
      // console.log('toPng');

      toPng(node, { pixelRatio: 2 }).then((dataUrl) => {
        const img = new Image();
        let width = 0;
        let height = 0;

        img.src = dataUrl;
        img.decode().then(() => {
          const doc = new JsPDF({
            unit: 'px',
            format: 'a4',
            hotfixes: 'px_scaling',
          });
          width = img.width;
          height = img.height;

          doc.addImage(dataUrl, 'image/png', 0, 0, width / 3.58, height / 3.58);
          const documentName = `${this.pageName
            .replace(': ', '_')
            .substring(this.pageName.indexOf(' ') + 1, this.pageName.length)
            .trim()}`;

          dialog[0].style =
            'transform-origin: center center; max-width: 800px;';
          /* for (let i = 0; i < dialogs.length; i++) {
            dialogs[i].style =
              'transform-origin: center center; max-width: 800px;';
          } */

          for (let i = 0; i < pdfColumns.length; i++) {
            pdfColumns[i].className = originalClass[i];
          }

          node.style.width = '';
          tabContainer.style.overflowY = originalOverflowY;
          tabContainer.style.maxHeight = originalMaxHeight;

          body[0].style.overflow = originalBodyStyleOverflow;

          for (let i = 0; i < hide.length; i++) {
            hide[i].style.display = originalHideStyleDisplay[i];
          }

          const date = new Date();
          const timeString = `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}_${date.getHours()}.${date.getMinutes()}`;
          doc
            .save(`${timeString}-${documentName}.pdf`, {
              returnPromise: true,
            })
            .then(() => {
              setTimeout(() => {
                downloadProgress.style.display = 'none';
              }, 1000);
            });
        });
      });
    },
    close() {
      this.$emit('update:dialog', false);
    },
  },
};
</script>

<style>
.v-toolbar__title {
  overflow: visible;
}
</style>
