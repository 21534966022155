import { Model } from '@vuex-orm/core';
import Employee from './employee';
import Group from './group';

export default class EmployeeGroup extends Model {
  static entity = 'employee_group';

  static primaryKey = ['employee_id', 'group_id'];

  static fields() {
    return {
      // Fields
      employee_id: this.number(null).nullable(),
      group_id: this.number(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      employee: this.belongsTo(Employee, 'employee_id'),
      group: this.belongsTo(Group, 'group_id'),
    };
  }
}
