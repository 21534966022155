import { Model } from '@vuex-orm/core';
import PaymentRequestFile from './payment_request_file';
import PaymentRequestRequiredAttachment from './payment_request_required_attachment';

export default class DocumentType extends Model {
  static entity = 'document_type';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      type: this.string(null).nullable(),
      code: this.string(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      payment_request_files: this.hasMany(
        PaymentRequestFile,
        'document_type_id'
      ),
      payment_request_required_attachments: this.hasMany(
        PaymentRequestRequiredAttachment,
        'document_type_id'
      ),
    };
  }

  get description() {
    return `${this.code} - ${this.type}`;
  }
}
