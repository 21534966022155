<template>
  <v-img
    v-if="thumb"
    :src="thumb"
    lazy-src=""
    aspect-ratio="1"
    class="pa-0"
    :width="width"
    :height="height"
    transition="fade-transition"
  >
    <slot />
  </v-img>
  <v-card
    v-else
    class="pa-0"
    :width="width"
    :height="height"
    transition="fade-transition"
  >
    <slot />
  </v-card>
</template>
<script>
export default {
  name: 'Thumbnail',
  props: {
    thumbPath: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    dialog: false,
  }),

  computed: {
    thumb() {
      let image = null;
      try {
        if (this.thumbPath !== '') {
          image = `/file${this.thumbPath}`;
        } else {
          image = '/no_image.jpg';
        }
      } catch (err) {
        image = '/no_image.jpg';
      }

      return image;
    },
  },
};
</script>
