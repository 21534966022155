<template>
  <v-text-field
    v-if="$route.params?.companyCode"
    v-model="$route.params.companyCode"
    :label="$t(label)"
    append-icon="mdi-filter"
    color="accent"
    dense
    flat
    hide-details
    single-line
    solo
    text
    disabled
  />
  <v-select
    v-else
    :items="items"
    item-text="company.name"
    item-value="company.code"
    :label="$t(label)"
    append-icon="mdi-filter"
    color="accent"
    dense
    flat
    hide-details
    single-line
    solo
    text
    clearable
    @input="$emit('update:editedValue', $event || '')"
  />
</template>

<script>
export default {
  name: 'CompanySelector',
  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    editedValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    edited() {
      return this.editedValue !== this.value;
    },
  },
  watch: {
    '$route.params.companyCode'(newVal) {
      // eslint-disable-next-line no-console
      console.log(`WATCH $route.params.companyCode: ${newVal}`);
      this.$emit('update:editedValue', newVal);
    },
  },
  created() {
    if (this.$route.params.companyCode) {
      // eslint-disable-next-line no-console
      console.log(
        `CREATED $route.params.companyCode: ${this.$route.params.companyCode}`
      );
      this.$emit('update:editedValue', this.$route.params.companyCode);
    }
  },
  methods: {},
};
</script>
