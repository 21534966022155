<template>
  <div>
    <template
      v-if="
        overridePermissions === false &&
        (!$auth.user.permissions[entity].edit ||
          $auth.user.permissions[entity].edit === false)
      "
    >
      <v-tooltip color="error" bottom>
        <template #activator="{ on, attrs }">
          <v-card flat v-bind="attrs" class="d-inline-block" v-on="on">
            <v-btn
              text
              small
              :color="editDialog === false ? 'warning darken-2' : 'error'"
              :disabled="true"
              class="pa-0"
              @click="toggleEditDialog()"
            >
              <template v-if="editDialog === false">
                <v-icon>mdi-pencil</v-icon>
              </template>
              <template v-else>
                <v-icon>mdi-cancel</v-icon>
                <!-- {{ $t('common.cancel_edit') }} -->
              </template>
            </v-btn>
          </v-card>
        </template>
        <v-icon dark>mdi-lock</v-icon>
        {{
          $t('misc.no_permission_edit', { entity: $tc(`entity.${entity}`, 0) })
        }}
      </v-tooltip>
    </template>
    <v-btn
      v-else
      text
      small
      :color="editDialog === false ? 'warning darken-2' : 'error'"
      class="pa-0"
      @click="toggleEditDialog()"
    >
      <template v-if="editDialog === false">
        <v-icon>mdi-pencil</v-icon>
      </template>
      <template v-else>
        <v-icon>mdi-cancel</v-icon>
        <!-- {{ $t('common.cancel_edit') }} -->
      </template>
    </v-btn>
    <v-tooltip
      :color="
        deletable &&
        $auth.user.permissions[entity] &&
        $auth.user.permissions[entity].delete
          ? 'success'
          : 'error'
      "
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-card flat v-bind="attrs" class="d-inline-block" v-on="on">
          <v-btn
            text
            small
            :disabled="
              editDialog === true ||
              deletable === false ||
              !$auth.user.permissions[entity].delete ||
              ($auth.user.permissions[entity].delete &&
                $auth.user.permissions[entity].delete === false)
            "
            color="error"
            class="pa-0"
            @click="toggleDeleteDialog()"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card>
      </template>
      <template
        v-if="
          !$auth.user.permissions[entity].delete ||
          $auth.user.permissions[entity].delete === false
        "
      >
        <v-icon dark>mdi-lock</v-icon>
        {{
          $t('misc.no_permission_delete', {
            entity: $tc(`entity.${entity}`, 0),
          })
        }}
      </template>
      <template v-else-if="deletable === false">
        <v-icon dark>mdi-cancel</v-icon>
        {{
          $t('misc.cannot_delete_has_parents', {
            entity: $tc(`entity.${entity}`, 1),
            amount: deleteItems.length,
          })
        }}
        <v-card
          v-for="item in deleteItems"
          :key="item.id"
          flat
          dark
          color="transparent"
          class="pl-8"
        >
          {{ $tc(`entity.${item.entity}`, item.count) }}:
          {{ item.count }}
        </v-card>
      </template>
      <template v-else>
        <v-icon dark>mdi-check</v-icon>
        {{ $t('common.deletable') }}
      </template>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'EditDeleteButtons',
  props: {
    entity: {
      type: String,
      required: true,
    },
    overridePermissions: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      required: true,
    },
    deleteItems: {
      type: Array,
      required: true,
    },
    editDialog: {
      type: Boolean,
      required: true,
    },
    deleteDialog: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    toggleEditDialog() {
      this.$emit('update:editDialog', !this.editDialog);
    },
    toggleDeleteDialog() {
      this.$emit('update:deleteDialog', !this.deleteDialog);
    },
  },
};
</script>

<style scoped>
.v-btn {
  min-width: 28px !important;
}
</style>
