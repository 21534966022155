import { Model } from '@vuex-orm/core';
import Company from './company';
import CompanyEmployee from './company_employee';
import Group from './group';
import EmployeeGroup from './employee_group';
import SupplierEmployee from './supplier_employee';
import User from './user';
import Person from './person';

export default class Employee extends Model {
  static entity = 'employee';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      person_id: this.number(null).nullable(),
      employee_number: this.string(null).nullable(),
      hire_date: this.attr(null),
      resignation_date: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      companies: this.belongsToMany(
        Company,
        CompanyEmployee,
        'employee_id',
        'company_id'
      ),
      groups: this.belongsToMany(
        Group,
        EmployeeGroup,
        'employee_id',
        'group_id'
      ),
      supplier_employee: this.hasOne(SupplierEmployee, 'employee_id'),
      user: this.hasOne(User, 'employee_id'),
      person: this.belongsTo(Person, 'person_id'),
    };
  }

  get nameWithEmployeeNumber() {
    let nameWithEmployeeNumber = '';
    if (this.person && this.employee_number) {
      nameWithEmployeeNumber = `${this.person.full_name} (${this.employee_number})`;
    } else if (this.person) {
      nameWithEmployeeNumber = `${this.person.full_name} (${this.user?.username})`;
    }
    return `${nameWithEmployeeNumber}`;
  }
}
