<template>
  <div>
    <v-container class="pa-0" color="primary">
      <v-fade-transition>
        <Message :message="message" class="no-print mb-3" />
      </v-fade-transition>
      <v-expand-transition>
        <v-row dense :class="message.status !== '' ? 'mt-2' : 'mt-0'">
          <v-col
            v-if="
              id &&
              supplier_employee !== null &&
              (inPage === false ||
                (inPage === true && actions.editDialog === false))
            "
            :md="inPage ? 12 : 6"
            class="col-pdf col-12"
          >
            <v-card>
              <FullInformationCard
                :item="supplier_employee"
                :title-only="
                  (inPage === true || $vuetify.breakpoint.smAndDown) &&
                  actions.editDialog === true
                "
              >
                <template #editDelete>
                  <v-card
                    v-if="
                      closeView !== true &&
                      newRecord !== true &&
                      id &&
                      supplier_employee !== null &&
                      $auth.user.permissions[entity] &&
                      ($auth.user.permissions[entity].edit ||
                        $auth.user.permissions[entity].delete) &&
                      ($auth.user.permissions[entity].edit === true ||
                        $auth.user.permissions[entity].delete === true)
                    "
                    flat
                    class="pa-0"
                  >
                    <EditDeleteButtons
                      v-bind.sync="actions"
                      :entity="entity"
                      :deletable="deletable"
                      :delete-items="deleteItems"
                    />
                  </v-card>
                </template>
              </FullInformationCard>
            </v-card>
            <v-select
              v-if="
                supplier_employee !== null &&
                closeView !== true &&
                newRecord !== true &&
                inPage === false &&
                Object.keys(panelItems).length !== 0 &&
                actions.editDialog === false
              "
              v-model="panel"
              :items="
                Object.keys(panelItems).map((key) => ({
                  entity: key,
                  label: panelItems[key],
                }))
              "
              item-text="label"
              item-value="entity"
              label="Choose View"
              outlined
              standalone
              hide-details
              class="mt-3"
            />
          </v-col>
          <v-col
            v-if="actions.editDialog && (id === null || inPage === true)"
            :md="inPage || id === null ? 12 : 6"
            class="col-pdf col-12"
          >
            <v-fade-transition>
              <SupplierEmployeeForm
                :id.sync="id"
                :parent-key="parentKey"
                :parent-value="parentValue"
                :dialog.sync="actions.editDialog"
                :close-view.sync="closeView"
                :new-record.sync="newRecord"
                :in-page="inPage"
              />
            </v-fade-transition>
          </v-col>
          <v-col
            v-else-if="actions.editDialog"
            :md="inPage ? 12 : 6"
            class="col-pdf col-12"
          >
            <v-card>
              <InPageContent :dialog="true" form-type="edit">
                <template #title> {{ $t('common.edit') }} </template>
                <v-fade-transition>
                  <SupplierEmployeeForm
                    :id="id"
                    :parent-key="parentKey"
                    :parent-value="parentValue"
                    :dialog.sync="actions.editDialog"
                    :close-view.sync="closeView"
                    :new-record.sync="newRecord"
                    :in-page="inPage"
                  />
                </v-fade-transition>
              </InPageContent>
            </v-card>
          </v-col>
          <v-col
            v-if="
              supplier_employee &&
              supplier_employee.payment_requests &&
              panel === 'payment_requests' &&
              !actions.editDialog
            "
            ref="payment_requests.panel"
            :md="inPage ? 12 : 6"
            class="col-pdf col-12"
          >
            <v-card color="primary">
              <InPageContent :dialog="true" form-type="view" borderless>
                <template #title>
                  {{ panelItems.payment_requests }}
                </template>
                <v-card flat class="mt-2 transparent">
                  <PaymentRequestTable
                    :items="supplier_employee.payment_requests"
                    :mini="true"
                    parent-key="supplier_employee_id"
                    :parent-value="id"
                  >
                    <template #default="{}">
                      <nuxt-child />
                    </template>
                  </PaymentRequestTable>
                </v-card>
              </InPageContent>
            </v-card>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-container>
    <Dialog
      v-if="actions.deleteDialog === true"
      :dialog.sync="actions.deleteDialog"
      :title="`${$tc('entity.' + entity, 1)}`"
      :name="`delete-${id}`"
      form-type="delete"
      max-width="600px"
    >
      <template #title>
        {{ $tc('entity.' + entity, 1) }}
      </template>
      <ConfirmDeleteForm
        :id="id"
        :entity-name="entity"
        :dialog.sync="actions.deleteDialog"
      />
    </Dialog>
  </div>
</template>

<script>
import Message from '@/components/display/Message';
import PaymentRequestTable from '@/components/table/PaymentRequestTable';
import Dialog from '@/components/display/Dialog';
import ConfirmDeleteForm from '@/components/form/ConfirmDeleteForm';
import EditDeleteButtons from '@/components/display/EditDeleteButtons';
import InPageContent from '@/components/display/InPageContent';
import FullInformationCard from '@/components/display/FullInformationCard';

export default {
  name: 'SupplierEmployeeView',
  components: {
    Message,
    PaymentRequestTable,
    Dialog,
    ConfirmDeleteForm,
    EditDeleteButtons,
    InPageContent,
    FullInformationCard,
    SupplierEmployeeForm: () =>
      import('@/components/form/SupplierEmployeeForm'),
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    parentKey: {
      type: Array,
      default: null,
    },
    parentValue: {
      type: Array,
      default: null,
    },
    inPage: {
      type: Boolean,
      default: false,
    },
  },
  data(app) {
    return {
      entity: 'supplier_employee',
      closeView: false,
      newRecord: false,
      panel: '',
      loading: false,
      actions: {
        editDialog: false,
        deleteDialog: false,
      },
      formDialog: {
        Supplier: false,
        Employee: false,
      },
      message: {
        text: '',
        status: '',
      },
    };
  },
  computed: {
    SupplierEmployee() {
      return this.$store.$db().model('supplier_employee');
    },
    supplier_employee() {
      return this.SupplierEmployee.query()
        .whereId(this.id)
        .withAll()
        .with([
          'employee.person|user',
          'payment_requests.company.address|company_group',
          'payment_requests.payment_request_files.payment_request_required_attachment.file_type|step',
          'payment_requests.step.group|payment_request_required_attachments',
          'payment_requests.supplier.branch.company',
          'supplier.branch.company',
        ])
        .first();
    },
    edit() {
      return this.id;
    },
    panelClosed() {
      return this.panel === '' || this.panel === undefined;
    },
    panelItems() {
      const items = {
        payment_requests: '',
      };
      items.payment_requests = `${
        this.supplier_employee && this.supplier_employee.payment_requests
          ? this.$tc(
              'entity.payment_request',
              this.supplier_employee.payment_requests.length
            )
          : this.$tc('entity.payment_request', 0)
      } (${
        this.supplier_employee &&
        this.supplier_employee.payment_requests &&
        this.supplier_employee.payment_requests.length > 0
          ? this.supplier_employee.payment_requests.length
          : 'No Records'
      })`;

      return items;
    },
    deletable() {
      if (this.deleteItems.length < 1) {
        return true;
      } else {
        return false;
      }
    },
    deleteItems() {
      const items = [];
      if (
        this.supplier_employee.payment_requests &&
        this.supplier_employee.payment_requests.length > 0
      ) {
        items.push({
          entity: 'payment_request',
          count: this.supplier_employee.payment_requests.length,
        });
      }

      return items;
    },
  },

  watch: {
    'actions.deleteDialog'(newVal) {
      if (newVal === false && this.supplier_employee === null) {
        this.close();
      }
    },
    closeView(newVal) {
      if (newVal === true) {
        this.close();
      }
    },
    supplier_employee(newVal) {
      if (newVal === null && this.id !== null) {
        this.message.text = `${this.$tc(
          'entity.supplier_employee',
          1
        )} deleted.`;
        this.message.status = 'success';
      }
    },
  },
  created() {
    if (this.editable === true) {
      this.actions.editDialog = true;
    }
  },
  methods: {
    close() {
      if (this.newRecord === true) this.$emit('update:id', this.id);
      this.$emit('update:dialog', false);
    },
  },
};
</script>
