import { Model } from '@vuex-orm/core';
import PaymentRequest from './payment_request';

export default class PaymentType extends Model {
  static entity = 'payment_type';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      type: this.string(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      payment_requests: this.hasMany(PaymentRequest, 'payment_type_id'),
    };
  }
}
