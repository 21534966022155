import { Model } from '@vuex-orm/core';
import Address from './address';

export default class AddressType extends Model {
  static entity = 'address_type';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      type: this.string(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      addresses: this.hasMany(Address, 'address_type_id'),
    };
  }
}
