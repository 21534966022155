import { Model } from '@vuex-orm/core';
import Company from './company';
import Employee from './employee';

export default class CompanyEmployee extends Model {
  static entity = 'company_employee';

  static primaryKey = ['company_id', 'employee_id'];

  static fields() {
    return {
      // Fields
      company_id: this.number(null).nullable(),
      employee_id: this.number(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      company: this.belongsTo(Company, 'company_id'),
      employee: this.belongsTo(Employee, 'employee_id'),
    };
  }
}
