import { Model } from '@vuex-orm/core';
import PaymentRequest from './payment_request';
import Employee from './employee';
import Supplier from './supplier';

export default class SupplierEmployee extends Model {
  static entity = 'supplier_employee';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      supplier_id: this.number(null).nullable(),
      employee_id: this.number(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      employee: this.belongsTo(Employee, 'employee_id'),
      supplier: this.belongsTo(Supplier, 'supplier_id'),
      payment_requests: this.hasMany(PaymentRequest, 'supplier_employee_id'),
    };
  }

  get description() {
    return `${this.employee?.person?.full_name} - ${this.employee?.person?.email}`;
  }

  get supplierDescription() {
    return `${
      this.supplier && this.supplier.branch && this.supplier.branch.company
        ? this.supplier.description
        : ''
    } - ${this.person ? this.person.telephone : ''}`;
  }
}
