import { Model } from '@vuex-orm/core';
import PaymentRequest from './payment_request';
import Bank from './bank';
import Supplier from './supplier';

export default class SupplierBankAccount extends Model {
  static entity = 'supplier_bank_account';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      supplier_id: this.number(null).nullable(),
      bank_id: this.number(null).nullable(),
      account_number: this.string(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      bank: this.belongsTo(Bank, 'bank_id'),
      supplier: this.belongsTo(Supplier, 'supplier_id'),
      payment_requests: this.hasMany(
        PaymentRequest,
        'supplier_bank_account_id'
      ),
    };
  }

  get description() {
    return `${this.bank ? this.bank.name : ''} - #${this.account_number}`;
  }

  get supplierDescription() {
    return `${
      this.supplier && this.supplier.branch
        ? this.supplier.branch.description
        : ''
    } - #${this.account_number}`;
  }
}
