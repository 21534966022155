var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"overflow-y":"hidden"},attrs:{"id":`content-${_vm.pageName}`,"height":"100%"}},[(_vm.image)?_c('v-img',{staticClass:"ma-auto mb-6",attrs:{"src":_vm.image,"width":"80px","contain":""}}):_vm._e(),_vm._v(" "),_c('v-card',{class:_vm.isDialog
        ? ''
        : _vm.$vuetify.breakpoint.xsOnly
        ? 'primary lighten-1 px-0'
        : 'primary lighten-1',attrs:{"flat":"","tile":_vm.isDialog ? false : _vm.$vuetify.breakpoint.xsOnly ? true : false,"justify-content":"space-between","dense":""}},[(_vm.pageName !== null)?_c('v-toolbar',{staticClass:"px-0",attrs:{"flat":"","dense":"","color":_vm.color,"dark":""}},[(!_vm.isDialog && _vm.showBackButton === true)?_c('v-btn',{attrs:{"icon":"","tile":"","aria-label":"Go back"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v(" mdi-arrow-left ")])],1):(_vm.icon)?_c('v-toolbar-items',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(_vm._s(_vm.icon))])],1):_vm._e(),_vm._v(" "),_c('v-toolbar-title',{staticClass:"overflow-x-hidden"},[_vm._t("title")],2),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_vm._t("items"),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-toolbar-items',[(_vm.canDownloadPdf)?_c('v-icon',{staticClass:"no-print",attrs:{"tile":""},on:{"click":function($event){return _vm.downloadPdf()}}},[_vm._v("mdi-file-download")]):_vm._e(),_vm._v(" "),_vm._t("download"),_vm._v(" "),_vm._t("email"),_vm._v(" "),_vm._t("print"),_vm._v(" "),_vm._t("close")],2)],2):_vm._e(),_vm._v(" "),_c('v-container',{class:_vm.hasTabs ? 'pa-0' : '',style:(_vm.isDialog
          ? `max-height: ${
              _vm.$vuetify.breakpoint.height - 48
            }px; overflow-y: auto`
          : ''),attrs:{"id":`tab-container-${_vm.pageName}`}},[_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }