import { Model } from '@vuex-orm/core';
import Person from './person';

export default class Gender extends Model {
  static entity = 'gender';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      gender: this.string(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      people: this.hasMany(Person, 'gender_id'),
    };
  }
}
