import { Model } from '@vuex-orm/core';
import Country from './country';
import CountryCurrency from './country_currency';

export default class Currency extends Model {
  static entity = 'currency';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      iso_4217: this.string(null).nullable(),
      symbol: this.string(null).nullable(),
      name: this.string(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      countries: this.belongsToMany(
        Country,
        CountryCurrency,
        'currency_id',
        'country_id'
      ),
    };
  }

  get description() {
    return `${this.iso_4217} (${this.symbol}) ${this.name}`;
  }
}
