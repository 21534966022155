import { Model } from '@vuex-orm/core';
import Sync from './sync';

export default class EntityCategory extends Model {
  static entity = 'entity_category';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      name: this.string(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      syncs: this.hasMany(Sync, 'entity_category_id'),
    };
  }
}
