<template>
  <CrudTable
    :headers="headers"
    :items="items"
    :groups="groups"
    :mini="mini"
    :entity="entity"
    :model="model"
    :parent-key="parentKey"
    :parent-value="parentValue"
    :editable="editable"
  />
</template>

<script>
import CrudTable from '@/components/display/CrudTable.vue';

export default {
  name: 'CompanyTable',
  components: { CrudTable },
  props: {
    items: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    parentKey: {
      type: String,
      default: null,
    },
    parentValue: {
      type: Number,
      default: null,
    },
    canDownloadPdf: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    entity: 'company',
    model: 'Company',
  }),
  computed: {
    headers() {
      return {
        small: [
          {
            text: this.$t('data_label.code'),
            value: 'code',
            groupable: false,
          },
          {
            text: this.$t('data_label.name'),
            value: 'name',
            groupable: false,
          },
          {
            text: this.$t('data_label.company_group'),
            value: 'company_group.name',
            align: ' d-none',
            sortable: false,
          },
        ],
        full: [
          {
            text: this.$t('data_label.code'),
            value: 'code',
            groupable: false,
          },
          {
            text: this.$t('data_label.name'),
            value: 'name',
            groupable: false,
          },
          {
            text: this.$t('data_label.company_group'),
            value: 'company_group.name',
            align: ' d-none d-sm-table-cell',
            sortable: false,
          },
          {
            text: '',
            value: 'actions',
            align: 'right',
            sortable: false,
            groupable: false,
          },
        ],
      };
    },
    groups() {
      return [
        {
          text: this.$t('data_label.address'),
          value: 'address.fullAddress',
        },
        {
          text: this.$t('data_label.company_group'),
          value: 'company_group.name',
        },
      ];
    },
  },
};
</script>
