<template>
  <v-form ref="form" v-model="form.valid" lazy-validation>
    <v-container>
      <Message :message="message" />
      <v-fade-transition>
        <v-row
          v-if="!form.success"
          dense
          :class="message.status !== '' ? 'mt-2' : ''"
        >
          <v-col cols="3" sm="2" align-self="center" align="middle">
            <v-spacer />
            <v-icon x-large>mdi-delete</v-icon>
          </v-col>
          <v-col cols="9" sm="10">
            <v-row dense>
              <v-col cols="12">
                <v-checkbox
                  v-model="form.fields.confirmDelete.value"
                  :rules="form.fields.confirmDelete.rules"
                  :label="form.fields.confirmDelete.label"
                  color="accent"
                  required
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-container>
    <v-card-actions>
      <v-spacer />
      <v-fade-transition>
        <v-btn v-if="!form.success" color="accent" text @click="close">
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn v-else color="accent" text @click="close">
          {{ $t('button.close') }}
          <span v-if="form.successCloseTimer > 0"
            >({{ form.successCloseTimer }})</span
          >
        </v-btn>
      </v-fade-transition>
      <v-fade-transition>
        <v-btn
          :loading="loading"
          color="accent"
          :dark="form.success || !form.valid ? false : true"
          :disabled="!form.valid || form.success"
          @click="validate"
        >
          {{ $t('button.confirm') }}
        </v-btn>
      </v-fade-transition>
    </v-card-actions>
  </v-form>
</template>

<script>
// import Person from '@/models/person';
import Message from '@/components/display/Message';

export default {
  name: 'ConfirmDeleteForm',
  components: {
    Message,
  },
  props: {
    id: {
      type: Number || String,
      required: true,
    },
    entityName: {
      type: String,
      required: true,
    },
  },
  data: (app) => ({
    form: {
      valid: false,
      fields: {
        confirmDelete: {
          value: false,
          label: app.$i18n.t('data_label.confirm_delete'),
          rules: [(v) => v === true || 'please confirm'],
        },
      },
      finish: false,
      success: false,
      successCloseTimer: 0,
    },
    entity: null,
    loading: false,
    message: {
      text: '',
      status: '',
    },
  }),
  computed: {
    formFinish() {
      // eslint-disable-next-line no-console
      console.log('Form finished');
      return this.form.finish;
    },
    formSuccess() {
      // eslint-disable-next-line no-console
      console.log('Form success');
      return this.form.success;
    },
    formSuccessCloseTimer() {
      // eslint-disable-next-line no-console
      console.log('Form success close timer');
      return this.form.successCloseTimer;
    },
  },
  watch: {
    formFinish(newVal) {
      this.$refs.form.validate();
      if (newVal && this.form.finish === true) {
        this.deleteItem();
      }
      this.form.finish = false;
    },
    formSuccess(newVal) {
      if (newVal) {
        this.form.successCloseTimer = 2;
      }
    },
    formSuccessCloseTimer(newVal) {
      if (newVal > 0) {
        setTimeout(() => {
          this.form.successCloseTimer--;
        }, 1000);
      } else if (newVal === 0) {
        this.close();
      }
    },
  },
  mounted() {
    // const entitySpecifier = `~/models/${this.entityName}`;

    this.form.valid = false;

    import(`~/models/${this.entityName}`).then((module) => {
      this.entity = module.default;
      // eslint-disable-next-line no-console
      console.log(this.entity);
    });
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        // eslint-disable-next-line no-console
        console.log('Validated');
        this.form.finish = true;
      }
    },
    async deleteItem() {
      try {
        // eslint-disable-next-line no-console
        console.log('deleteItem');
        this.loading = true;
        const result = await this.entity
          .api()
          .delete(`/api/${this.entityName}/${this.id}`, {
            delete: this.id,
          });
        this.loading = false;
        if (result.response.status === 200) {
          this.form.success = true;
          this.message.text = 'Successfully deleted.';
          this.message.status = 'success';
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`Error: ${error.message}`);
        this.loading = false;
        this.message.text = error.message;
        this.message.status = 'error';
      }
    },
    close() {
      this.$emit('update:dialog', false);
    },
  },
};
</script>
