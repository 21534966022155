<template>
  <v-card flat color="transparent">
    <v-card-title>
      <template v-if="item.product_variation">
        {{ item.product_variation.description }}
      </template>
      <template v-else-if="item.person">
        {{ item.person.full_name }}
      </template>
      <template v-else-if="item.full_name">
        {{ item.full_name }}
      </template>
      <template v-else-if="item.name">
        {{ item.name }} {{ item.symbol ? `( ${item.symbol})` : '' }}
      </template>
      <template v-else-if="item.type">{{ item.type }}</template>
      <template v-else-if="item.gender">{{ item.gender }}</template>
      <template v-else-if="item.line_1">{{ item.line_1 }}</template>
      <template v-else-if="item.employee_id && item.username">
        {{ item.employee.person.full_name }}
      </template>
      <template v-else-if="item.start_date && !item.sub_department_position">
        {{ item.store ? item.store.name : '' }}
        {{ $t(`month_name.${item.month}`) }}- {{ item.year }}
        <v-chip
          v-if="item.status"
          small
          outlined
          flat
          :color="
            item.status.id === 1
              ? 'error darken-2'
              : item.status.id === 2
              ? ''
              : item.status.id === 3
              ? 'accent'
              : item.status.id === 4
              ? 'yellow darken-4'
              : item.status.id === 5
              ? 'indigo darken-4'
              : 'success'
          "
          class="ml-1"
        >
          <v-icon v-if="item.status.id === 5" small class="mx-1">
            mdi-check-circle
          </v-icon>
          {{ item.status.name }}
        </v-chip>
      </template>
      <template v-else-if="item.inventory_check && item.inventory.code">
        {{ item.inventory.code }}
      </template>
      <template v-else-if="item.inventory_transaction_type_id">
        {{ item.inventory_transaction_type.type }}
        <v-chip
          v-if="item.batch"
          small
          flat
          color="warning darken-2"
          class="ml-1 pl-1"
        >
          <v-icon small class="mx-1">mdi-package-variant</v-icon>
          {{ item.batch }}
        </v-chip>
        <v-chip
          v-if="item.quantity_remaining > 0"
          small
          dark
          :color="item.quantity_remaining > 0 ? 'success' : 'error'"
          class="d-inline-block mr-1 pl-1"
        >
          <v-icon small class="mr-2">{{
            item.quantity_remaining > 0
              ? 'mdi-check-circle'
              : 'mdi-close-circle'
          }}</v-icon>
          {{ item.quantity_remaining }}/{{ item.quantity }}
        </v-chip>
        <v-chip
          v-else
          small
          dark
          :color="item.quantity >= 0 ? 'success' : 'error'"
          class="d-inline-block mr-1 pl-1"
        >
          <v-icon small class="mr-2">{{
            item.quantity >= 0 ? 'mdi-plus-circle' : 'mdi-minus-circle'
          }}</v-icon>
          {{ item.quantity }}
        </v-chip>
      </template>
      <template v-else-if="item.company && item.department">
        {{ item.description }}
      </template>
      <template v-else-if="item.company_department && item.position">
        {{ item.description }}
      </template>
      <template v-else-if="item.supplier && item.bank">
        {{ item.description }}
      </template>
      <template v-else-if="item.supplier && item.employee">
        {{ item.supplier.description }}
      </template>
      <template v-else-if="item.sync_entity">
        {{ item.group.name }} / {{ $tc(`entity.${item.sync_entity}`, 1) }} ({{
          item.sync_entity
        }})
      </template>
      <template
        v-else-if="item.document_type && item.step && item.attachment_number"
      >
        #{{ item.attachment_number }} - {{ item.document_type.type }} ({{
          item.document_type.code
        }})
      </template>
      <template v-else-if="item.status && item.step">
        {{ $t('data_label.step') }} {{ item.step }} - {{ item.status }}
      </template>
      <v-spacer />
      <slot name="editDelete" />
    </v-card-title>

    <v-card-subtitle v-if="item.supplier && item.employee">
      {{ item.employee.person.full_name }}
    </v-card-subtitle>
    <v-card-subtitle
      v-if="item.document_type && item.step && item.attachment_number"
    >
      {{ $t('data_label.step') }} {{ item.step.step }} - {{ item.step.status }}
    </v-card-subtitle>

    <v-card-subtitle v-if="item.status && item.step && item.group">
      <v-icon small>mdi-account-group</v-icon>
      {{ item.group.name }}
    </v-card-subtitle>

    <template v-if="titleOnly === false">
      <v-card-subtitle v-if="item.sync_entity">
        <v-card flat>
          <v-icon :color="item.view ? 'success' : 'error'">{{
            item.view ? 'mdi-check' : 'mdi-close'
          }}</v-icon>
          {{ $t('data_label.view') }}
        </v-card>
        <v-card flat>
          <v-icon :color="item.create ? 'success' : 'error'">{{
            item.create ? 'mdi-check' : 'mdi-close'
          }}</v-icon>
          {{ $t('data_label.create') }}
        </v-card>
        <v-card flat>
          <v-icon :color="item.edit ? 'success' : 'error'">{{
            item.edit ? 'mdi-check' : 'mdi-close'
          }}</v-icon>
          {{ $t('data_label.edit') }}
        </v-card>
        <v-card flat>
          <v-icon :color="item.delete ? 'success' : 'error'">{{
            item.delete ? 'mdi-check' : 'mdi-close'
          }}</v-icon>
          {{ $t('data_label.delete') }}
        </v-card>
      </v-card-subtitle>

      <v-card-subtitle v-if="item.store && item.start_date">
        <v-card flat>
          <v-icon small>mdi-store</v-icon>
          {{ item.store.code }}
          <v-card flat class="d-inline-block">
            <v-icon small>mdi-calendar-start</v-icon>
            {{ item.start_date }}
            <v-icon v-if="item.finish_date" small color="success">
              mdi-calendar-check
            </v-icon>
            <v-icon v-else small color="error">mdi-calendar-clock</v-icon>
            {{ item.finish_date }}
          </v-card>
        </v-card>
      </v-card-subtitle>

      <v-card-subtitle v-if="item.supplier && item.person">
        <v-card flat>
          <v-icon small>mdi-store</v-icon>
          {{ item.supplier.description }}
        </v-card>
        <v-card flat>
          <v-icon small>mdi-phone</v-icon>
          {{ item.person.telephone }}
        </v-card>
      </v-card-subtitle>

      <v-card-subtitle v-if="item.supplier && item.bank">
        <v-card flat>
          <v-icon small>mdi-store</v-icon>
          {{ item.supplier.description }}
        </v-card>
        <v-card flat>
          <v-icon small>mdi-phone</v-icon>
          {{ item.bank.name }}
        </v-card>
      </v-card-subtitle>

      <v-card-subtitle v-if="item.inventory_transaction_type_id">
        <v-card flat class="d-inline-block">
          <v-icon small class="mr-1">mdi-tag</v-icon>
          {{ item.inventory.code }}
        </v-card>
        <v-card flat class="d-inline-block">
          <v-icon small class="mr-1">mdi-account</v-icon>
          {{ item.employee.person.full_name }}
        </v-card>
        <v-card flat class="d-inline-block">
          <v-icon small>mdi-calendar</v-icon>
          {{ $d(new Date(item.updated_at), 'short', 'th') }}
        </v-card>
      </v-card-subtitle>

      <v-card-subtitle
        v-if="item.preferred_name && item.gender_id && item.country_id"
      >
        {{ item.preferred_name }}
        <v-card flat class="d-inline-block">
          <v-icon small class="ml-1">
            {{ item.gender_id === 1 ? 'mdi-gender-male' : 'mdi-gender-female' }}
          </v-icon>
          {{ item.gender.gender }}
        </v-card>
        <v-card flat class="d-inline-block">
          <template v-if="item.date_of_birth !== null">
            <v-icon small color="success" class="ml-1"> mdi-cake </v-icon>
            {{ $d(new Date(item.date_of_birth), 'short', 'th') }}
          </template>
        </v-card>
        <v-card flat class="d-inline-block">
          <template v-if="item.country !== null">
            <v-icon small class="ml-1"> mdi-flag </v-icon>
            {{ item.country.demonym }}
          </template>
        </v-card>
        <v-card v-if="item.email" flat class="mt-3">
          <v-icon small>mdi-email</v-icon>
          {{ item.email }}
        </v-card>
        <v-card v-if="item.telephone" flat class="mt-0">
          <v-icon small>mdi-phone</v-icon>
          {{ item.telephone }}
        </v-card>
      </v-card-subtitle>

      <v-card-subtitle v-if="item.brand_id">
        {{ item.brand.name }}{{ item.range !== null ? ` (${item.range})` : '' }}
        <v-card v-if="item.model_number !== null" flat class="d-inline-block">
          <v-icon small>mdi-pound</v-icon>
          {{ item.model_number }}
        </v-card>
        <!-- <v-card flat class="d-block">
        <template v-if="item.date_of_birth !== null">
          <v-icon small color="success"> mdi-cake </v-icon>
          {{ item.date_of_birth }}
        </template>
      </v-card>
      <v-card flat class="d-block">
        <template v-if="item.country !== null">
          <v-icon small> mdi-flag </v-icon>
          {{ item.country.name }}
        </template>
      </v-card> -->
      </v-card-subtitle>

      <v-card-subtitle v-if="item.inventory_check && item.group">
        <v-icon small>mdi-account-group</v-icon>
        {{ item.group.name }}
        <template v-if="item.employee">
          <v-icon small>mdi-account</v-icon>
          {{ item.employee.nameWithEmployeeNumber }}
        </template>
      </v-card-subtitle>

      <v-card-subtitle
        v-if="item.inventory_check && item.quantity_calculated"
        class="pt-0"
      >
        <v-card flat class="d-inline">
          <template v-if="item.quantity_counted === null">
            <v-card flat class="d-inline"> <b>Check 1: </b>required </v-card>
          </template>
          <template v-else>
            <b>Check 1: </b>
            {{ item.quantity_counted }}/{{ item.quantity_calculated }}

            <v-chip
              v-if="item.quantity_discrepancy === 0 && item.complete === true"
              small
              flat
              dark
              color="success"
              class="pl-1"
            >
              <v-icon small> mdi-check-circle </v-icon>
              Complete
            </v-chip>
            <v-chip
              v-if="item.quantity_discrepancy === 0 && item.complete === false"
              small
              flat
              outlined
              color="success"
              class="pl-1"
            >
              <v-icon small> mdi-check-circle </v-icon>
              Complete (Waiting for others)
            </v-chip>
            <v-chip
              v-else-if="
                item.quantity_discrepancy !== 0 && item.miscounted === true
              "
              small
              flat
              outlined
              color="warning darken-2"
              class="pl-1"
            >
              <v-icon small> mdi-exclamation </v-icon>
              {{ $t('status.miscounted') }}
            </v-chip>
            <v-chip
              v-else-if="item.quantity_discrepancy !== 0 && item.found === true"
              small
              flat
              outlined
              color="success"
              class="pl-1"
            >
              <v-icon small> mdi-check-circle </v-icon>
              {{ $t('status.found') }}
            </v-chip>
            <v-chip
              v-else-if="item.quantity_discrepancy !== 0"
              small
              flat
              outlined
              color="warning darken-2"
              class="pl-1"
            >
              <v-icon small> mdi-close-circle </v-icon>
              Needs recheck
            </v-chip>
          </template>
        </v-card>
      </v-card-subtitle>

      <v-card-subtitle
        v-if="
          item.inventory_check &&
          item.quantity_discrepancy &&
          item.quantity_discrepancy !== null &&
          (item.quantity_discrepancy !== 0 ||
            (item.quantity_discrepancy === 0 &&
              inventory_snapshot_paired !== null &&
              inventory_snapshot_paired.quantity_discrepancy !== null &&
              inventory_snapshot_paired.quantity_discrepancy !== 0))
        "
        class="pt-0"
      >
        <v-card flat class="d-inline">
          <template
            v-if="
              (item.quantity_discrepancy !== null ||
                item.quantity_discrepancy !== 0) &&
              item.quantity_counted_recheck === null
            "
          >
            <v-card flat class="d-inline"> <b>Check 2: </b>required </v-card>
          </template>
          <template v-else-if="inventory_snapshot_paired !== null">
            <b>Check 2: </b>
            {{ item.quantity_counted_recheck }}/{{ item.quantity_calculated }}
            <v-chip
              v-if="
                item.quantity_discrepancy ===
                  item.quantity_discrepancy_recheck &&
                item.quantity_discrepancy_recheck === 0 &&
                inventory_snapshot_paired.quantity_discrepancy_recheck === null
              "
            ></v-chip>
            <v-chip
              v-if="
                item.quantity_discrepancy ===
                  item.quantity_discrepancy_recheck &&
                item.quantity_discrepancy_recheck === 0 &&
                inventory_snapshot_paired.quantity_discrepancy_recheck === 0
              "
            ></v-chip>
            <v-chip
              v-if="
                item.quantity_discrepancy ===
                  item.quantity_discrepancy_recheck &&
                item.quantity_discrepancy_recheck === 0 &&
                inventory_snapshot_paired.quantity_discrepancy_recheck !== 0
              "
            ></v-chip>
            <v-chip
              v-if="
                item.quantity_discrepancy ===
                  item.quantity_discrepancy_recheck &&
                item.quantity_discrepancy_recheck !== 0 &&
                inventory_snapshot_paired.quantity_discrepancy_recheck === null
              "
            ></v-chip>
            <v-chip
              v-if="
                item.quantity_discrepancy ===
                  item.quantity_discrepancy_recheck &&
                item.quantity_discrepancy_recheck !== 0 &&
                inventory_snapshot_paired.quantity_discrepancy_recheck === 0
              "
            ></v-chip>
            <v-chip
              v-if="
                item.quantity_discrepancy ===
                  item.quantity_discrepancy_recheck &&
                item.quantity_discrepancy_recheck !== 0 &&
                inventory_snapshot_paired.quantity_discrepancy_recheck !== 0
              "
            ></v-chip>
            <v-chip
              v-if="
                item.quantity_discrepancy_recheck === 0 &&
                item.complete === true
              "
            ></v-chip>

            <!-- <v-chip
          v-if="item.quantity_discrepancy_recheck === 0 && item.complete === true"
          small
          flat
          dark
          color="success"
          class="pl-1"
        >
          <v-icon small> mdi-check-circle </v-icon>
          Complete
        </v-chip>
        <v-chip
          v-else-if="item.quantity_discrepancy_recheck === 0 && inventory_snapshot_paired.quantity_discrepancy_recheck === 0 && "
          small
          flat
          dark
          color="success"
          class="pl-1"
        >
          <v-icon small> mdi-check-circle </v-icon>
          Complete
        </v-chip> -->
          </template>
          <template v-else>
            <b>Check 2: </b>
            {{ item.quantity_counted_recheck }}/{{ item.quantity_calculated }}
            <v-chip
              v-if="
                item.quantity_discrepancy_recheck === 0 &&
                item.complete === true
              "
              small
              flat
              dark
              color="success"
              class="pl-1"
            >
              <v-icon small> mdi-check-circle </v-icon>
              Complete
            </v-chip>
          </template>
          <!-- <template v-else>
        <b>Check 2: </b>
        {{ item.quantity_counted_recheck }}/{{ item.quantity_calculated }}
        <v-chip
          v-if="item.quantity_discrepancy_recheck === 0 && item.complete === true"
          small
          flat
          dark
          color="success"
          class="pl-1"
        >
          <v-icon small> mdi-check-circle </v-icon>
          Complete
        </v-chip>
        <v-chip
          v-else-if="
            item.quantity_discrepancy === item.quantity_discrepancy_recheck &&
            inventory_snapshot_paired !== null &&
            inventory_snapshot_paired.quantity_discrepancy_recheck !== null &&
            inventory_snapshot_paired.quantity_discrepancy_recheck !==
              item.quantity_discrepancy_recheck
          "
          small
          flat
          outlined
          color="warning darken-2"
          class="pl-1"
        >
          <v-icon small> mdi-check-circle </v-icon>
          Correct (Waiting for others)
        </v-chip>
        <v-chip
          v-else-if="
            item.quantity_discrepancy === item.quantity_discrepancy_recheck &&
            inventory_snapshot_paired !== null &&
            inventory_snapshot_paired.quantity_discrepancy_recheck !==
              item.quantity_discrepancy_recheck
          "
          small
          flat
          outlined
          color="success"
          class="pl-1"
        >
          <v-icon small> mdi-check-circle </v-icon>
          Correct (Waiting for others)
        </v-chip>
        <v-chip
          v-else-if="item.quantity_discrepancy_recheck === 0"
          small
          flat
          outlined
          color="success"
          class="pl-1"
        >
          <v-icon small> mdi-check-circle </v-icon>
          Correct (Waiting for others)
        </v-chip>
        <v-chip
          v-else-if="
            item.quantity_discrepancy_recheck !== 0 &&
            item.accept_discrepancy === true &&
            item.complete === true
          "
          small
          flat
          dark
          color="warning darken-2"
          class="pl-1"
        >
          <v-icon small> mdi-check-circle </v-icon>
          Complete ({{ item.quantity_discrepancy_recheck }} accepted missing)
        </v-chip>
      </template> -->
        </v-card>
      </v-card-subtitle>

      <v-card-subtitle v-if="item.employee_id && item.username" class="pt-0">
        <v-card flat class="d-block">
          <v-icon small>mdi-tag</v-icon>
          {{ item.username }}
        </v-card>
      </v-card-subtitle>

      <v-card-text v-if="item.conditions" class="pt-0">
        <v-card flat class="d-inline-block">
          <b> {{ $tc('entity.condition', item.conditions.length) }}: </b>

          <ul v-if="item.conditions.length >= 2">
            <li v-for="condition in item.conditions" :key="condition.name">
              {{ condition.name }}
            </li>
          </ul>
          <v-card-text
            v-else-if="item.conditions.length === 1"
            class="d-inline pl-0"
          >
            {{ item.conditions[0].name }}
          </v-card-text>
          <v-card-text v-else class="d-inline pl-0">None</v-card-text>
        </v-card>
      </v-card-text>

      <v-card-subtitle
        v-if="item.group_1 && item.item_discrepancy_total !== null"
        flat
        class="pt-0 d-block"
      >
        <v-card flat class="d-block">
          <v-icon
            small
            :color="
              item.item_discrepancy_total > 0 ? 'warning darken-2' : 'success'
            "
          >
            {{
              item.item_discrepancy_total > 0 ? 'mdi-exclamation' : 'mdi-check'
            }}
          </v-icon>
          {{
            $tc('status.discrepancies_found', item.item_discrepancy_total, {
              amount: item.item_discrepancy_total,
            })
          }}
        </v-card>
      </v-card-subtitle>

      <v-card-subtitle v-if="item.group_1" class="pt-0">
        <v-card flat class="d-block">
          <v-icon small color="accent">mdi-account-group</v-icon>
          {{ item.group_1.name }}
          <v-chip
            v-if="item.group_1_remaining > 0"
            small
            outlined
            flat
            color="warning darken-2"
          >
            {{ item.group_1_remaining }}
            {{ $t('status.remaining') }}
          </v-chip>
          <v-chip v-else small flat dark color="success" class="pl-0">
            <v-icon small class="mx-1">mdi-check-circle</v-icon>
            {{ $t('status.complete') }}
          </v-chip>
        </v-card>
      </v-card-subtitle>

      <v-card-subtitle v-if="!item.code && item.abbreviation" class="pt-0">
        <v-card flat>
          {{ item.abbreviation }}
        </v-card>
      </v-card-subtitle>

      <v-card-subtitle v-if="item.group_1" class="pt-0">
        <v-card flat class="d-block">
          <v-icon
            v-if="item.group_2 && item.group_2 !== null"
            small
            color="success"
          >
            mdi-check
          </v-icon>
          <v-icon v-else small color="error">mdi-close</v-icon>
          {{ $t('data_label.accounting_check') }}
        </v-card>
        <v-card
          v-if="item.group_2 && item.group_2 !== null"
          flat
          class="d-block"
        >
          <v-icon small color="accent"> mdi-account-group </v-icon>
          {{ item.group_2.name }}
          <v-chip
            v-if="item.group_2_remaining > 0"
            small
            outlined
            flat
            color="warning darken-2"
          >
            {{ item.group_2_remaining }}
            {{ $t('status.remaining') }}
          </v-chip>
          <v-chip v-else small flat dark color="success" class="pl-0">
            <v-icon small class="mx-1">mdi-check-circle</v-icon>
            {{ $t('status.complete') }}
          </v-chip>
        </v-card>
      </v-card-subtitle>

      <v-card-subtitle v-if="item.line_1">
        <v-card flat>
          <span v-if="item.line_2" class="d-block">{{ item.line_2 }}</span>
          <span v-if="item.line_3" class="d-block">{{ item.line_3 }}</span>
          <span v-if="item.city" class="d-block">{{ item.city }}</span>
          <span v-if="item.state_province_county" class="d-block">{{
            item.state_province_county
          }}</span>
          <span v-if="item.postcode_zip" class="d-block">{{
            item.postcode_zip
          }}</span>
          <span v-if="item.country" class="d-block">{{
            item.country.name
          }}</span>
        </v-card>
      </v-card-subtitle>

      <v-card-text v-if="item.address_type" class="pt-0">
        <v-card flat>
          <b>{{ $t('data_label.address_type') }}:</b>
          {{ item.address_type.type }}
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.latitude" class="pt-0">
        <v-card flat class="d-block">
          <b>{{ $t('data_label.latitude') }}:</b>
          {{ item.latitude }}
        </v-card>
        <v-card flat class="d-block">
          <b>{{ $t('data_label.longitude') }}:</b>
          {{ item.longitude }}
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.quantity && item.unit_price" class="pt-0">
        <v-card v-if="item.delivery_order_number !== null" flat class="d-block">
          <b>{{ $t('data_label.delivery_order') }}:</b>
          {{ item.delivery_order_number }}
        </v-card>
        <v-card flat class="d-block">
          <b>{{ $t('data_label.unit_price') }}:</b>
          ฿{{ item.unit_price }}
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.quantity && item.quantity !== 0" class="pt-0">
        <v-card
          v-if="
            item.inventory_transaction_type_id === 1 ||
            item.inventory_transaction_type_id === 2 ||
            item.inventory_transaction_type_id === 4 ||
            item.inventory_transaction_type_id === 6
          "
          flat
          class="d-block"
        >
          <b>{{ $t('data_label.quantity_added') }}:</b>
          {{ item.quantity }}
        </v-card>
        <v-card v-else flat class="d-block">
          <b>{{ $t('data_label.quantity_taken') }}:</b>
          {{ item.quantity }}
        </v-card>
        <v-card
          v-if="
            item.quantity_remaining &&
            (item.inventory_transaction_type_id === 1 ||
              item.inventory_transaction_type_id === 2 ||
              item.inventory_transaction_type_id === 4)
          "
          flat
          class="d-block"
        >
          <b>{{ $t('data_label.quantity_remaining') }}:</b>
          {{ item.quantity_remaining }}
        </v-card>
      </v-card-text>

      <v-card-subtitle
        v-if="
          item.code ||
          item.document_code ||
          item.company ||
          item.company_group ||
          item.employee_number ||
          item.iso_3166_alpha_2 ||
          item.iso_639_1 ||
          item.iso_4217 ||
          item.dialling_code ||
          item.locationDescription
        "
      >
        <v-card v-if="item.code && !item.company_group" flat class="d-block">
          <v-icon small>mdi-tag</v-icon>
          {{ item.code }}
          <v-card v-if="item.abbreviation" flat class="d-inline ml-2">
            <v-icon small>mdi-tag</v-icon>
            {{ item.abbreviation }}
          </v-card>
          <v-chip
            v-if="
              item.store_id &&
              (item.storage_area_id === null ||
                item.quantity_calculated === null)
            "
            small
            dark
            color="warning darken-2"
            class="d-inline-block mr-1"
          >
            <v-icon small dark left class="mr-1">mdi-exclamation-thick </v-icon>
            {{ $t('status.draft') }}
          </v-chip>
        </v-card>
        <v-card v-if="item.document_code" flat class="d-block">
          <v-icon small>mdi-tag</v-icon>
          {{ item.document_code }}
        </v-card>
        <v-card v-if="item.company" flat class="d-block">
          <v-icon small>mdi-domain</v-icon>
          {{ item.company.nameWithCompanyGroup }}
        </v-card>
        <v-card v-if="item.company_group" flat class="d-block">
          <v-card flat class="d-inline-block">
            <v-icon small>mdi-domain</v-icon>
            {{ item.company_group.name }}
          </v-card>
          <v-card flat class="d-inline-block ml-1">
            <v-icon small>mdi-tag</v-icon>
            {{ item.code }}
          </v-card>
          <v-card v-if="item.email" flat class="mt-0">
            <v-icon small>mdi-email</v-icon>
            {{ item.email }}
          </v-card>
        </v-card>
        <v-card v-if="item.employee_number" flat class="d-block">
          <v-icon small>mdi-tag</v-icon>
          {{ item.employee_number }}
          <v-card v-if="item.hire_date" flat class="d-inline-block">
            <v-icon small color="success" class="ml-2"
              >mdi-calendar-start</v-icon
            >
            {{ item.hire_date }}
            <v-icon small color="error" class="ml-2">mdi-calendar-end</v-icon>
            {{ item.resignation_date ? item.resignation_date : '?' }}
          </v-card>
        </v-card>
        <v-card v-if="item.iso_3166_alpha_2" flat class="d-block">
          <v-icon small>mdi-tag</v-icon>
          {{ item.iso_3166_alpha_2 }}
          <v-icon small class="ml-2">mdi-tag</v-icon>
          {{ item.iso_3166_alpha_3 }}
        </v-card>
        <v-card v-if="item.iso_639_1" flat class="d-block">
          <v-icon small>mdi-tag</v-icon>
          {{ item.iso_639_1 }}
          <v-icon small class="ml-2">mdi-tag</v-icon>
          {{ item.iso_639_2 }}
        </v-card>
        <v-card v-if="item.iso_4217" flat class="d-block">
          <v-icon small>mdi-tag</v-icon>
          {{ item.iso_4217 }}
        </v-card>
        <v-card v-if="item.dialling_code" flat class="d-block">
          <v-icon small>mdi-phone-dial</v-icon>
          {{ item.dialling_code }}
        </v-card>
        <v-card v-if="item.locationDescription" flat class="d-block">
          <template v-if="item.storage_area_id !== null">
            <v-icon small>mdi-map-marker</v-icon>
            {{ item.locationDescription }}
            <v-card v-if="item.quantity_calculated" flat class="d-inline-block">
              <v-icon small color="success" class="ml-2">mdi-check</v-icon>
              {{ item.quantity_calculated }}
            </v-card>
            <v-card
              v-else-if="item.quantity_calculated === 0"
              flat
              class="d-inline-block"
            >
              <v-icon small color="error" class="mr-1"
                >mdi-store-remove
              </v-icon>
              {{ $t('status.out_of_stock') }}
            </v-card>
            <v-card v-else flat class="d-inline-block">
              <v-icon small color="error" class="ml-2">mdi-store-alert</v-icon>
              {{
                $t('status.attribute_not_set', {
                  attribute: $t('data_label.quantity'),
                })
              }}
            </v-card>
            <v-card
              v-if="item.quantity_discrepancy"
              flat
              class="d-inline-block"
            >
              <v-icon small color="error" class="ml-2">mdi-store-alert</v-icon>
              {{ item.quantity_discrepancy }}
            </v-card>
          </template>
          <template v-else>
            <v-icon small color="error">mdi-map-marker</v-icon>
            {{
              $t('status.attribute_not_set', {
                attribute: $t('data_label.storage_area'),
              })
            }}
            <v-card v-if="item.quantity_calculated" flat class="d-inline-block">
              <v-icon small color="success" class="ml-2">mdi-check</v-icon>
              {{ item.quantity_calculated }}
            </v-card>
            <v-card
              v-else-if="item.quantity_calculated === 0"
              flat
              color="error"
              class="d-inline-block"
            >
              <v-icon small color="error" class="mr-1"
                >mdi-store-remove
              </v-icon>
              {{ $t('status.out_of_stock') }}
            </v-card>
            <v-card v-else flat class="d-inline-block">
              <v-icon small color="error" class="ml-2">mdi-store-alert</v-icon>
              {{
                $t('status.attribute_not_set', {
                  attribute: $t('data_label.quantity'),
                })
              }}
            </v-card>
          </template>
        </v-card>
      </v-card-subtitle>

      <v-card-text
        v-if="item.store_type && item.department_sub_department"
        class="pt-0"
      >
        <v-card flat>
          <b>{{ $t('data_label.store_type') }}:</b>
          {{ item.store_type.type }}
        </v-card>
        <v-card flat>
          <b>{{ $t('data_label.department_sub_department') }}:</b>
          {{ item.department_sub_department.description }}
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.demonym" class="pt-0">
        <v-card flat class="d-inline">
          <b>{{ $t('data_label.demonym') }}:</b>
          {{ item.demonym }}
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.primary_location" class="pt-0">
        <v-card flat class="d-inline">
          <b>{{ $t('data_label.primary_location') }}:</b>
          {{ item.primary_location.name }}
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.primary_location_type" class="pt-0">
        <v-card flat class="d-inline">
          <b>{{ $t('data_label.primary_location_type') }}:</b>
          {{ item.primary_location_type.type }}
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.storage_area_type" class="pt-0">
        <v-card flat class="d-inline">
          <b>{{ $t('data_label.storage_area_type') }}:</b>
          {{ item.storage_area_type.type }}
        </v-card>
      </v-card-text>

      <v-card-subtitle
        v-if="!qrScan && item.subdivision_product_category && item.product_type"
        class="mt-0"
      >
        <v-card flat class="d-block">
          <b>{{ $t('data_label.product_category') }}:</b>
          {{ item.subdivision_product_category.codeName }}
        </v-card>
        <v-card flat class="d-block">
          <b>{{ $t('data_label.product_type') }}:</b>
          {{ item.product_type.codeType }}
        </v-card>
      </v-card-subtitle>

      <v-card-subtitle
        v-if="item.product_type_id && item.base_unit_id"
        class="mt-0"
      >
        <v-card flat class="d-block">
          <b>{{ $t('data_label.product_type') }}:</b>
          {{ item.product_type.codeType }}
        </v-card>
        <v-card flat class="d-block">
          <b>{{ $t('data_label.base_unit') }}:</b>
          {{ item.base_unit.name }}
        </v-card>
      </v-card-subtitle>

      <v-card-text
        v-if="
          !qrScan &&
          item.product_variation &&
          item.product_variation.product &&
          (item.product_variation.product.fixed_attributes ||
            item.product_variation.product.product_variation_attributes)
        "
      >
        <v-card flat class="d-inline-block">
          <b>
            {{
              $tc(
                'entity.fixed_attribute',
                item.product_variation.product.fixed_attributes.length +
                  item.product_variation.product_variation_attributes.length
              )
            }}:
          </b>
          <ul v-if="item.product_variation.product.fixed_attributes">
            <li
              v-for="fixed_attribute in item.product_variation.product
                .fixed_attributes"
              :key="fixed_attribute.description"
            >
              {{ fixed_attribute.description }}
            </li>
          </ul>
          <ul v-if="item.product_variation.product_variation_attributes">
            <li
              v-for="product_variation_attribute in item.product_variation
                .product_variation_attributes"
              :key="product_variation_attribute.attributeValueUnit"
            >
              {{ product_variation_attribute.attributeValueUnit }}
            </li>
          </ul>
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.branches" class="pt-0">
        <v-card flat class="d-inline">
          <b> {{ $tc('entity.branch', item.branches.length) }}: </b>
          <ul v-if="item.branches.length > 0">
            <li
              v-for="(branch, index) in item.branches"
              :key="branch.name + '-' + index"
            >
              <v-card flat>
                {{ branch.description }}
                <v-card v-if="!item.line_1" flat class="transparent">
                  <v-icon small>mdi-map-marker</v-icon>
                  {{
                    branch.address_line_0 !== null
                      ? `${branch.address_line_0}, `
                      : ''
                  }}{{ branch.address.fullAddress }}
                </v-card>
                <template v-else> - {{ branch.address_line_0 }} </template>
              </v-card>
            </li>
          </ul>
          <v-card-text v-else class="d-inline pl-0">None</v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.company_departments" class="pt-0">
        <v-card flat class="d-inline">
          <b>
            {{ $tc('entity.department', item.company_departments.length) }}:
          </b>
          <ul v-if="item.company_departments.length > 0">
            <li
              v-for="company_department in item.company_departments"
              :key="company_department.description"
            >
              <v-card flat>
                {{ company_department.description }}
              </v-card>
            </li>
          </ul>
          <v-card-text v-else class="d-inline pl-0">None</v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.groups" class="pt-0">
        <v-card flat class="d-inline-block">
          <b> {{ $tc('entity.group', item.groups.length) }}: </b>
          <ul v-if="item.groups.length >= 2">
            <li v-for="group in item.groups" :key="group.name">
              {{ group.name }}
            </li>
          </ul>
          <v-card-text
            v-else-if="item.groups.length === 1"
            class="d-inline pl-0"
          >
            {{ item.groups[0].name }}
          </v-card-text>
          <v-card-text v-else class="d-inline pl-0">None</v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.company_department_positions" class="pt-0">
        <v-card flat class="d-inline-block">
          <b>
            {{
              $tc(
                'entity.company_department_position',
                item.company_department_positions.length
              )
            }}:
          </b>
        </v-card>
        <ul v-if="item.company_department_positions.length >= 2">
          <li
            v-for="company_department_position in item.company_department_positions"
            :key="company_department_position.description"
          >
            {{ company_department_position.description }}
          </li>
        </ul>
        <v-card-text
          v-else-if="item.company_department_positions.length === 1"
          class="d-inline pl-0"
        >
          {{ item.company_department_positions[0].description }}
        </v-card-text>
        <v-card-text v-else class="d-inline pl-0">None</v-card-text>
      </v-card-text>

      <v-card-text v-if="item.fixed_attributes" class="pt-0">
        <v-card flat class="d-inline-block">
          <b>
            {{ $tc('entity.fixed_attribute', item.fixed_attributes.length) }}:
          </b>
          <ul v-if="item.fixed_attributes.length >= 2">
            <li
              v-for="fixed_attribute in item.fixed_attributes"
              :key="fixed_attribute.description"
            >
              {{ fixed_attribute.description }}
            </li>
          </ul>
          <v-card-text
            v-else-if="item.fixed_attributes.length === 1"
            class="d-inline pl-0"
          >
            {{ item.fixed_attributes[0].description }}
          </v-card-text>
          <v-card-text v-else class="d-inline pl-0">None</v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.attributes" class="pt-0">
        <v-card flat class="d-inline-block">
          <b> {{ $tc('entity.attribute', item.attributes.length) }}: </b>
          <ul v-if="item.attributes.length >= 2">
            <li v-for="attribute in item.attributes" :key="attribute.name">
              {{ attribute.name }}
            </li>
          </ul>
          <v-card-text
            v-else-if="item.attributes.length === 1"
            class="d-inline pl-0"
          >
            {{ item.attributes[0].name }}
          </v-card-text>
          <v-card-text v-else class="d-inline pl-0">None</v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.subdivisions" class="my-0">
        <v-card flat class="d-inline">
          <b> {{ $tc('entity.subdivision', item.subdivisions.length) }}: </b>
          <ul v-if="item.subdivisions.length >= 2">
            <li
              v-for="subdivision in item.subdivisions"
              :key="subdivision.nameAbbreviationCode"
            >
              {{ subdivision.nameAbbreviationCode }}
            </li>
          </ul>
          <v-card-text
            v-else-if="item.subdivisions.length === 1"
            class="d-inline pl-0"
          >
            {{ item.subdivisions[0].nameAbbreviationCode }}
          </v-card-text>
          <v-card-text v-else class="d-inline pl-0">None</v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.countries" class="pt-0">
        <v-card flat class="d-inline">
          <b> {{ $tc('entity.country', item.countries.length) }}: </b>
          <ul v-if="item.countries.length >= 2">
            <li v-for="country in item.countries" :key="country.name">
              {{ country.name }}
            </li>
          </ul>
          <v-card-text
            v-else-if="item.countries.length === 1"
            class="d-inline pl-0"
          >
            {{ item.countries[0].name }}
          </v-card-text>
          <v-card-text v-else class="d-inline pl-0">None</v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.languages" class="pt-0">
        <v-card flat class="d-inline">
          <b> {{ $tc('entity.language', item.languages.length) }}: </b>
          <ul v-if="item.languages.length >= 2">
            <li v-for="language in item.languages" :key="language.name">
              {{ language.name }}
            </li>
          </ul>
          <v-card-text
            v-else-if="item.languages.length === 1"
            class="d-inline pl-0"
          >
            {{ item.languages[0].name }}
          </v-card-text>
          <v-card-text v-else class="d-inline pl-0">None</v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.currencies" class="pt-0">
        <v-card flat class="d-inline">
          <b> {{ $tc('entity.currency', item.currencies.length) }}: </b>
          <ul v-if="item.currencies.length >= 2">
            <li v-for="currency in item.currencies" :key="currency.name">
              {{ currency.description }}
            </li>
          </ul>
          <v-card-text
            v-else-if="item.currencies.length === 1"
            class="d-inline pl-0"
          >
            {{ item.currencies[0].description }}
          </v-card-text>
          <v-card-text v-else class="d-inline pl-0">None</v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.companies" class="pt-0">
        <v-card flat class="d-inline">
          <b> {{ $tc('entity.company', item.companies.length) }}: </b>
          <ul v-if="item.companies.length >= 2">
            <li v-for="company in item.companies" :key="company.name">
              {{ company.name }}
            </li>
          </ul>
          <v-card-text
            v-else-if="item.companies.length === 1"
            class="d-inline pl-0"
          >
            {{ item.companies[0].name }}
          </v-card-text>
          <v-card-text v-else class="d-inline pl-0">None</v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.roles" class="pt-0">
        <v-card flat class="d-inline">
          <b> {{ $tc('entity.role', item.roles.length) }}: </b>
          <ul v-if="item.roles.length >= 2">
            <li v-for="role in item.roles" :key="role.name">
              {{ role.name }}
            </li>
          </ul>
          <v-card-text
            v-else-if="item.roles.length === 1"
            class="d-inline pl-0"
          >
            {{ item.roles[0].name }}
          </v-card-text>
          <v-card-text v-else class="d-inline pl-0">None</v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.supplier_bank_accounts" class="pt-0">
        <v-card flat class="d-inline">
          <b>
            {{
              $tc(
                'entity.supplier_bank_account',
                item.supplier_bank_accounts.length
              )
            }}:
          </b>
          <ul v-if="item.supplier_bank_accounts.length >= 2">
            <li
              v-for="supplier_bank_account in item.supplier_bank_accounts"
              :key="supplier_bank_account.supplierDescription"
            >
              {{ supplier_bank_account.supplierDescription }}
            </li>
          </ul>
          <v-card-text
            v-else-if="item.supplier_bank_accounts.length === 1"
            class="d-inline pl-0"
          >
            {{ item.supplier_bank_accounts[0].supplierDescription }}
          </v-card-text>
          <v-card-text v-else class="d-inline pl-0">None</v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.contacts" class="pt-0">
        <v-card flat class="d-inline">
          <b> {{ $tc('entity.contact', item.contacts.length) }}: </b>
          <ul v-if="item.contacts.length >= 2">
            <li v-for="contact in item.contacts" :key="contact.telephone">
              {{ contact.telephone }} ({{ contact.country.name }}) ({{
                contact.contact_type.type
              }})
              <v-card v-if="contact.is_primary" flat class="d-inline-block">
                <v-icon color="accent">mdi-check</v-icon>
                {{ $t('entity.is_primary') }}
              </v-card>
              <v-chip v-if="contact.is_line">
                <b>LINE Account</b>
              </v-chip>
            </li>
          </ul>
          <v-card-text
            v-else-if="item.contacts.length === 1"
            class="d-inline pl-0"
          >
            {{ item.contacts[0].name }}
            <v-card
              v-if="item.contacts[0].is_primary"
              flat
              class="d-inline-block"
            >
              <v-icon color="accent">mdi-check</v-icon>
              {{ $t('entity.is_primary') }}
            </v-card>
            <v-chip v-if="item.contacts[0].is_line" small color="success">
              <b>LINE</b>
            </v-chip>
          </v-card-text>
          <v-card-text v-else class="d-inline pl-0">None</v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.emails" class="pt-0">
        <v-card flat class="d-inline">
          <b> {{ $tc('entity.email', item.emails.length) }}: </b>
          <ul v-if="item.emails.length >= 2">
            <li v-for="email in item.emails" :key="email.telephone">
              {{ email.email }}
              <v-card v-if="email.is_primary" flat class="d-inline-block">
                <v-icon color="accent">mdi-check</v-icon>
                {{ $t('entity.is_primary') }}
              </v-card>
            </li>
          </ul>
          <v-card-text
            v-else-if="item.emails.length === 1"
            class="d-inline pl-0"
          >
            {{ item.emails[0].email }}
            <v-card
              v-if="item.emails[0].is_primary"
              flat
              class="d-inline-block"
            >
              <v-icon color="accent">mdi-check</v-icon>
              {{ $t('entity.is_primary') }}
            </v-card>
          </v-card-text>
          <v-card-text v-else class="d-inline pl-0">None</v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.address" class="pt-0">
        <v-card flat class="d-inline-block">
          <b>
            {{ $tc('entity.address', 1) }} ({{
              item.address.address_type.type
            }}):
          </b>
          <span v-if="item.address_line_0" class="d-block">{{
            item.address_line_0
          }}</span>
          <span v-if="item.address.line_1" class="d-block">{{
            item.address.line_1
          }}</span>
          <span v-if="item.address.line_2" class="d-block">{{
            item.address.line_2
          }}</span>
          <span v-if="item.address.line_3" class="d-block">{{
            item.address.line_3
          }}</span>
          <span v-if="item.address.city" class="d-block">{{
            item.address.city
          }}</span>
          <span v-if="item.address.state_province_county" class="d-block">{{
            item.address.state_province_county
          }}</span>
          <span v-if="item.address.postcode_zip" class="d-block">{{
            item.address.postcode_zip
          }}</span>
          <span v-if="item.address.country" class="d-block">{{
            item.address.country.name
          }}</span>
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.addresses" class="pt-0">
        <v-card flat class="d-inline">
          <b>{{ $tc('entity.address', item.addresses.length) }}:</b>
          {{ item.addresses.length }}
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.employees" class="pt-0">
        <v-card flat class="d-inline">
          <b>{{ $tc('entity.employee', item.employees.length) }}:</b>
          {{ item.employees.length }}
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.products" class="pt-0">
        <v-card flat class="d-inline">
          <b>{{ $tc('entity.product', item.products.length) }}:</b>
          {{ item.products.length }}
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.primary_locations" class="pt-0">
        <v-card flat class="d-inline">
          <b
            >{{
              $tc('entity.primary_location', item.primary_locations.length)
            }}:</b
          >
          {{ item.primary_locations.length }}
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.stores" class="pt-0">
        <v-card flat class="d-inline">
          <b>{{ $tc('entity.store', item.stores.length) }}:</b>
          {{ item.stores.length }}
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.storage_areas" class="pt-0">
        <v-card flat class="d-inline">
          <b>{{ $tc('entity.storage_area', item.storage_areas.length) }}:</b>
          {{ item.storage_areas.length }}
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.inventories" class="pt-0">
        <v-card flat class="d-inline">
          <b>{{ $tc('entity.inventory', item.inventories.length) }}:</b>
          {{ item.inventories.length }}
        </v-card>
      </v-card-text>

      <v-card-text v-if="item.people" class="pt-0">
        <v-card flat class="d-inline">
          <b>{{ $tc('entity.person', item.people.length) }}:</b>
          {{ item.people.length }}
        </v-card>
      </v-card-text>
    </template>
    <v-card flat class="mx-3 pb-1">
      <slot />
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: 'FullInformationCard',
  props: {
    item: {
      type: Object,
      required: true,
    },
    qrScan: {
      type: Boolean,
      default: false,
    },
    titleOnly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
