import { Model } from '@vuex-orm/core';
import User from './user';
import UserRole from './user_role';

export default class Role extends Model {
  static entity = 'role';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      name: this.string(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      users: this.belongsToMany(User, UserRole, 'role_id', 'user_id'),
    };
  }
}
