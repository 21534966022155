import VuexORM from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import Address from '@/models/address';
import AddressType from '@/models/address_type';
import Bank from '@/models/bank';
import Branch from '@/models/branch';
import Company from '@/models/company';
import CompanyEmployee from '@/models/company_employee';
import CompanyGroup from '@/models/company_group';
import Country from '@/models/country';
import CountryCurrency from '@/models/country_currency';
import Currency from '@/models/currency';
import DocumentType from '@/models/document_type';
import Employee from '@/models/employee';
import EmployeeGroup from '@/models/employee_group';
import EntityCategory from '@/models/entity_category';
import File from '@/models/file';
import Gender from '@/models/gender';
import Group from '@/models/group';
import GroupPermission from '@/models/group_permission';
import PaymentRequest from '@/models/payment_request';
import PaymentRequestFile from '@/models/payment_request_file';
import PaymentRequestRequiredAttachment from '@/models/payment_request_required_attachment';
import PaymentType from '@/models/payment_type';
import Person from '@/models/person';
import PersonFile from '@/models/person_file';
import Role from '@/models/role';
import Step from '@/models/step';
import Supplier from '@/models/supplier';
import SupplierBankAccount from '@/models/supplier_bank_account';
import SupplierEmployee from '@/models/supplier_employee';
import Sync from '@/models/sync';
import User from '@/models/user';
import UserRole from '@/models/user_role';

VuexORM.use(VuexORMAxios);

const database = new VuexORM.Database();

database.register(Address);
database.register(AddressType);
database.register(Bank);
database.register(Branch);
database.register(Company);
database.register(CompanyEmployee);
database.register(CompanyGroup);
database.register(Country);
database.register(CountryCurrency);
database.register(Currency);
database.register(DocumentType);
database.register(Employee);
database.register(EmployeeGroup);
database.register(EntityCategory);
database.register(File);
database.register(Gender);
database.register(Group);
database.register(GroupPermission);
database.register(PaymentRequest);
database.register(PaymentRequestFile);
database.register(PaymentRequestRequiredAttachment);
database.register(PaymentType);
database.register(Person);
database.register(PersonFile);
database.register(Role);
database.register(Step);
database.register(Supplier);
database.register(SupplierBankAccount);
database.register(SupplierEmployee);
database.register(Sync);
database.register(User);
database.register(UserRole);

export default database;
