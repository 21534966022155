import { Model } from '@vuex-orm/core';
import Branch from './branch';
import Employee from './employee';
import CompanyEmployee from './company_employee';
import PaymentRequest from './payment_request';
import Supplier from './supplier';
import Address from './address';
import CompanyGroup from './company_group';

export default class Company extends Model {
  static entity = 'company';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      company_group_id: this.number(null).nullable(),
      name: this.string(null).nullable(),
      is_supplier: this.boolean(),
      code: this.string(null).nullable(),
      tax_code: this.string(null).nullable(),
      email: this.string(null).nullable(),
      address_id: this.number(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      employees: this.belongsToMany(
        Employee,
        CompanyEmployee,
        'company_id',
        'employee_id'
      ),
      address: this.belongsTo(Address, 'address_id'),
      branches: this.hasMany(Branch, 'company_id'),
      payment_requests: this.hasMany(PaymentRequest, 'company_id'),
      suppliers: this.hasMany(Supplier, 'company_id'),
      company_group: this.belongsTo(CompanyGroup, 'company_group_id'),
    };
  }

  get nameWithCompanyGroup() {
    const name = this.name ? this.name : '';
    const companyGroup = this.company_group
      ? ` (${this.company_group.name})`
      : '';

    return `${name}${companyGroup}`;
  }
}
