<template>
  <v-toolbar-items>
    <v-btn icon :disabled="currentPage < 2" @click="previousPage()">
      <v-icon :color="onToolbar ? '' : 'accent'" tile large
        >mdi-chevron-left</v-icon
      >
    </v-btn>
    <v-toolbar-title class="py-2">
      {{ currentPage }} {{ onToolbar ? '/' : $t('misc.of') }} {{ pageCount }}
    </v-toolbar-title>
    <v-btn icon :disabled="currentPage >= pageCount" @click="nextPage()">
      <v-icon :color="onToolbar ? '' : 'accent'" tile large
        >mdi-chevron-right</v-icon
      >
    </v-btn>
  </v-toolbar-items>
</template>

<script>
export default {
  name: 'MiniPaginator',

  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    onToolbar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    width() {
      return this.$vuetify.breakpoint.width;
    },
  },
  methods: {
    nextPage() {
      this.$emit('update:currentPage', this.currentPage + 1);
    },
    previousPage() {
      this.$emit('update:currentPage', this.currentPage - 1);
    },
  },
};
</script>
