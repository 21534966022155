import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _73196ac6 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _fc535764 = () => interopDefault(import('..\\pages\\login\\index.vue' /* webpackChunkName: "pages/login/index" */))
const _9a83875a = () => interopDefault(import('..\\pages\\logout\\index.vue' /* webpackChunkName: "pages/logout/index" */))
const _4e24803e = () => interopDefault(import('..\\pages\\office\\index.vue' /* webpackChunkName: "pages/office/index" */))
const _978af830 = () => interopDefault(import('..\\pages\\sign_up.vue' /* webpackChunkName: "pages/sign_up" */))
const _4f68da6e = () => interopDefault(import('..\\pages\\sign_up\\_supplierId.vue' /* webpackChunkName: "pages/sign_up/_supplierId" */))
const _0a5d13de = () => interopDefault(import('..\\pages\\office\\admin.vue' /* webpackChunkName: "pages/office/admin" */))
const _5e226a38 = () => interopDefault(import('..\\pages\\office\\admin\\index.vue' /* webpackChunkName: "pages/office/admin/index" */))
const _97e4e6a6 = () => interopDefault(import('..\\pages\\office\\admin\\permission.vue' /* webpackChunkName: "pages/office/admin/permission" */))
const _19eed3a7 = () => interopDefault(import('..\\pages\\office\\admin\\permission\\group_permissions.vue' /* webpackChunkName: "pages/office/admin/permission/group_permissions" */))
const _618d2301 = () => interopDefault(import('..\\pages\\office\\admin\\permission\\groups.vue' /* webpackChunkName: "pages/office/admin/permission/groups" */))
const _0aef823e = () => interopDefault(import('..\\pages\\office\\admin\\settings\\index.vue' /* webpackChunkName: "pages/office/admin/settings/index" */))
const _74372129 = () => interopDefault(import('..\\pages\\office\\admin\\user.vue' /* webpackChunkName: "pages/office/admin/user" */))
const _bd6e88b8 = () => interopDefault(import('..\\pages\\office\\admin\\user\\roles.vue' /* webpackChunkName: "pages/office/admin/user/roles" */))
const _bd03c522 = () => interopDefault(import('..\\pages\\office\\admin\\user\\users.vue' /* webpackChunkName: "pages/office/admin/user/users" */))
const _163d0f43 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\address.vue' /* webpackChunkName: "pages/office/admin/settings/address" */))
const _1a2ccc2f = () => interopDefault(import('..\\pages\\office\\admin\\settings\\address\\addresses.vue' /* webpackChunkName: "pages/office/admin/settings/address/addresses" */))
const _532a187b = () => interopDefault(import('..\\pages\\office\\admin\\settings\\address\\address_types.vue' /* webpackChunkName: "pages/office/admin/settings/address/address_types" */))
const _7d3ea9a3 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\branch.vue' /* webpackChunkName: "pages/office/admin/settings/branch" */))
const _4af34d33 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\branch\\branches.vue' /* webpackChunkName: "pages/office/admin/settings/branch/branches" */))
const _4a07434c = () => interopDefault(import('..\\pages\\office\\admin\\settings\\company.vue' /* webpackChunkName: "pages/office/admin/settings/company" */))
const _0abaeb82 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\company\\companies.vue' /* webpackChunkName: "pages/office/admin/settings/company/companies" */))
const _7167afa2 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\company\\company_groups.vue' /* webpackChunkName: "pages/office/admin/settings/company/company_groups" */))
const _105229e5 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\country.vue' /* webpackChunkName: "pages/office/admin/settings/country" */))
const _55f94342 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\country\\countries.vue' /* webpackChunkName: "pages/office/admin/settings/country/countries" */))
const _4db2bad4 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\country\\currencies.vue' /* webpackChunkName: "pages/office/admin/settings/country/currencies" */))
const _4c74fa5c = () => interopDefault(import('..\\pages\\office\\admin\\settings\\document.vue' /* webpackChunkName: "pages/office/admin/settings/document" */))
const _9006109e = () => interopDefault(import('..\\pages\\office\\admin\\settings\\document\\document_types.vue' /* webpackChunkName: "pages/office/admin/settings/document/document_types" */))
const _3d400e6f = () => interopDefault(import('..\\pages\\office\\admin\\settings\\employee.vue' /* webpackChunkName: "pages/office/admin/settings/employee" */))
const _8c58b834 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\employee\\employees.vue' /* webpackChunkName: "pages/office/admin/settings/employee/employees" */))
const _708ac2c3 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\employee\\groups.vue' /* webpackChunkName: "pages/office/admin/settings/employee/groups" */))
const _5b7523e4 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\group.vue' /* webpackChunkName: "pages/office/admin/settings/group" */))
const _a77bd93c = () => interopDefault(import('..\\pages\\office\\admin\\settings\\group\\groups.vue' /* webpackChunkName: "pages/office/admin/settings/group/groups" */))
const _689a9eb6 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\payment_request.vue' /* webpackChunkName: "pages/office/admin/settings/payment_request" */))
const _43d9dc3e = () => interopDefault(import('..\\pages\\office\\admin\\settings\\payment_request\\payment_request_required_attachments.vue' /* webpackChunkName: "pages/office/admin/settings/payment_request/payment_request_required_attachments" */))
const _166a232a = () => interopDefault(import('..\\pages\\office\\admin\\settings\\payment_request\\payment_types.vue' /* webpackChunkName: "pages/office/admin/settings/payment_request/payment_types" */))
const _4eb40812 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\payment_request\\steps.vue' /* webpackChunkName: "pages/office/admin/settings/payment_request/steps" */))
const _550b0dd6 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\person.vue' /* webpackChunkName: "pages/office/admin/settings/person" */))
const _10c5e328 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\person\\genders.vue' /* webpackChunkName: "pages/office/admin/settings/person/genders" */))
const _211a2336 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\person\\people.vue' /* webpackChunkName: "pages/office/admin/settings/person/people" */))
const _7dd0b98c = () => interopDefault(import('..\\pages\\office\\admin\\settings\\user.vue' /* webpackChunkName: "pages/office/admin/settings/user" */))
const _28487de1 = () => interopDefault(import('..\\pages\\office\\admin\\settings\\user\\roles.vue' /* webpackChunkName: "pages/office/admin/settings/user/roles" */))
const _287ddfac = () => interopDefault(import('..\\pages\\office\\admin\\settings\\user\\users.vue' /* webpackChunkName: "pages/office/admin/settings/user/users" */))
const _136e2a8a = () => interopDefault(import('..\\pages\\office\\login\\index.vue' /* webpackChunkName: "pages/office/login/index" */))
const _54212d97 = () => interopDefault(import('..\\pages\\office\\logout\\index.vue' /* webpackChunkName: "pages/office/logout/index" */))
const _bd52dd94 = () => interopDefault(import('..\\pages\\office\\suppliers.vue' /* webpackChunkName: "pages/office/suppliers" */))
const _6fb3cae8 = () => interopDefault(import('..\\pages\\office\\suppliers\\index.vue' /* webpackChunkName: "pages/office/suppliers/index" */))
const _6b546a0c = () => interopDefault(import('..\\pages\\office\\_companyCode.vue' /* webpackChunkName: "pages/office/_companyCode" */))
const _1c594fe9 = () => interopDefault(import('..\\pages\\office\\_companyCode\\payment_requests.vue' /* webpackChunkName: "pages/office/_companyCode/payment_requests" */))
const _08a3fc79 = () => interopDefault(import('..\\pages\\office\\_companyCode\\payment_requests\\index.vue' /* webpackChunkName: "pages/office/_companyCode/payment_requests/index" */))
const _3fc3379e = () => interopDefault(import('..\\pages\\office\\_companyCode\\payment_requests\\new_payment_request.vue' /* webpackChunkName: "pages/office/_companyCode/payment_requests/new_payment_request" */))
const _17603488 = () => interopDefault(import('..\\pages\\office\\_companyCode\\payment_requests\\_paymentRequestCode.vue' /* webpackChunkName: "pages/office/_companyCode/payment_requests/_paymentRequestCode" */))
const _b8df099e = () => interopDefault(import('..\\pages\\_supplierId.vue' /* webpackChunkName: "pages/_supplierId" */))
const _4f6b1031 = () => interopDefault(import('..\\pages\\_supplierId\\index.vue' /* webpackChunkName: "pages/_supplierId/index" */))
const _7ce16956 = () => interopDefault(import('..\\pages\\_supplierId\\new_payment_request.vue' /* webpackChunkName: "pages/_supplierId/new_payment_request" */))
const _316e1774 = () => interopDefault(import('..\\pages\\_supplierId\\_paymentRequestCode.vue' /* webpackChunkName: "pages/_supplierId/_paymentRequestCode" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _73196ac6,
    name: "index___en"
  }, {
    path: "/login",
    component: _fc535764,
    name: "login___th"
  }, {
    path: "/logout",
    component: _9a83875a,
    name: "logout___th"
  }, {
    path: "/office",
    component: _4e24803e,
    name: "office___th"
  }, {
    path: "/sign_up",
    component: _978af830,
    name: "sign_up___th",
    children: [{
      path: ":supplierId?",
      component: _4f68da6e,
      name: "sign_up-supplierId___th"
    }]
  }, {
    path: "/en/login",
    component: _fc535764,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _9a83875a,
    name: "logout___en"
  }, {
    path: "/en/office",
    component: _4e24803e,
    name: "office___en"
  }, {
    path: "/en/sign_up",
    component: _978af830,
    name: "sign_up___en",
    children: [{
      path: ":supplierId?",
      component: _4f68da6e,
      name: "sign_up-supplierId___en"
    }]
  }, {
    path: "/office/admin",
    component: _0a5d13de,
    children: [{
      path: "",
      component: _5e226a38,
      name: "office-admin___th"
    }, {
      path: "permission",
      component: _97e4e6a6,
      name: "office-admin-permission___th",
      children: [{
        path: "group_permissions",
        component: _19eed3a7,
        name: "office-admin-permission-group_permissions___th"
      }, {
        path: "groups",
        component: _618d2301,
        name: "office-admin-permission-groups___th"
      }]
    }, {
      path: "settings",
      component: _0aef823e,
      name: "office-admin-settings___th"
    }, {
      path: "user",
      component: _74372129,
      name: "office-admin-user___th",
      children: [{
        path: "roles",
        component: _bd6e88b8,
        name: "office-admin-user-roles___th"
      }, {
        path: "users",
        component: _bd03c522,
        name: "office-admin-user-users___th"
      }]
    }, {
      path: "settings/address",
      component: _163d0f43,
      name: "office-admin-settings-address___th",
      children: [{
        path: "addresses",
        component: _1a2ccc2f,
        name: "office-admin-settings-address-addresses___th"
      }, {
        path: "address_types",
        component: _532a187b,
        name: "office-admin-settings-address-address_types___th"
      }]
    }, {
      path: "settings/branch",
      component: _7d3ea9a3,
      name: "office-admin-settings-branch___th",
      children: [{
        path: "branches",
        component: _4af34d33,
        name: "office-admin-settings-branch-branches___th"
      }]
    }, {
      path: "settings/company",
      component: _4a07434c,
      name: "office-admin-settings-company___th",
      children: [{
        path: "companies",
        component: _0abaeb82,
        name: "office-admin-settings-company-companies___th"
      }, {
        path: "company_groups",
        component: _7167afa2,
        name: "office-admin-settings-company-company_groups___th"
      }]
    }, {
      path: "settings/country",
      component: _105229e5,
      name: "office-admin-settings-country___th",
      children: [{
        path: "countries",
        component: _55f94342,
        name: "office-admin-settings-country-countries___th"
      }, {
        path: "currencies",
        component: _4db2bad4,
        name: "office-admin-settings-country-currencies___th"
      }]
    }, {
      path: "settings/document",
      component: _4c74fa5c,
      name: "office-admin-settings-document___th",
      children: [{
        path: "document_types",
        component: _9006109e,
        name: "office-admin-settings-document-document_types___th"
      }]
    }, {
      path: "settings/employee",
      component: _3d400e6f,
      name: "office-admin-settings-employee___th",
      children: [{
        path: "employees",
        component: _8c58b834,
        name: "office-admin-settings-employee-employees___th"
      }, {
        path: "groups",
        component: _708ac2c3,
        name: "office-admin-settings-employee-groups___th"
      }]
    }, {
      path: "settings/group",
      component: _5b7523e4,
      name: "office-admin-settings-group___th",
      children: [{
        path: "groups",
        component: _a77bd93c,
        name: "office-admin-settings-group-groups___th"
      }]
    }, {
      path: "settings/payment_request",
      component: _689a9eb6,
      name: "office-admin-settings-payment_request___th",
      children: [{
        path: "payment_request_required_attachments",
        component: _43d9dc3e,
        name: "office-admin-settings-payment_request-payment_request_required_attachments___th"
      }, {
        path: "payment_types",
        component: _166a232a,
        name: "office-admin-settings-payment_request-payment_types___th"
      }, {
        path: "steps",
        component: _4eb40812,
        name: "office-admin-settings-payment_request-steps___th"
      }]
    }, {
      path: "settings/person",
      component: _550b0dd6,
      name: "office-admin-settings-person___th",
      children: [{
        path: "genders",
        component: _10c5e328,
        name: "office-admin-settings-person-genders___th"
      }, {
        path: "people",
        component: _211a2336,
        name: "office-admin-settings-person-people___th"
      }]
    }, {
      path: "settings/user",
      component: _7dd0b98c,
      name: "office-admin-settings-user___th",
      children: [{
        path: "roles",
        component: _28487de1,
        name: "office-admin-settings-user-roles___th"
      }, {
        path: "users",
        component: _287ddfac,
        name: "office-admin-settings-user-users___th"
      }]
    }]
  }, {
    path: "/office/login",
    component: _136e2a8a,
    name: "office-login___th"
  }, {
    path: "/office/logout",
    component: _54212d97,
    name: "office-logout___th"
  }, {
    path: "/office/suppliers",
    component: _bd52dd94,
    children: [{
      path: "",
      component: _6fb3cae8,
      name: "office-suppliers___th"
    }]
  }, {
    path: "/en/office/admin",
    component: _0a5d13de,
    children: [{
      path: "",
      component: _5e226a38,
      name: "office-admin___en"
    }, {
      path: "permission",
      component: _97e4e6a6,
      name: "office-admin-permission___en",
      children: [{
        path: "group_permissions",
        component: _19eed3a7,
        name: "office-admin-permission-group_permissions___en"
      }, {
        path: "groups",
        component: _618d2301,
        name: "office-admin-permission-groups___en"
      }]
    }, {
      path: "settings",
      component: _0aef823e,
      name: "office-admin-settings___en"
    }, {
      path: "user",
      component: _74372129,
      name: "office-admin-user___en",
      children: [{
        path: "roles",
        component: _bd6e88b8,
        name: "office-admin-user-roles___en"
      }, {
        path: "users",
        component: _bd03c522,
        name: "office-admin-user-users___en"
      }]
    }, {
      path: "settings/address",
      component: _163d0f43,
      name: "office-admin-settings-address___en",
      children: [{
        path: "addresses",
        component: _1a2ccc2f,
        name: "office-admin-settings-address-addresses___en"
      }, {
        path: "address_types",
        component: _532a187b,
        name: "office-admin-settings-address-address_types___en"
      }]
    }, {
      path: "settings/branch",
      component: _7d3ea9a3,
      name: "office-admin-settings-branch___en",
      children: [{
        path: "branches",
        component: _4af34d33,
        name: "office-admin-settings-branch-branches___en"
      }]
    }, {
      path: "settings/company",
      component: _4a07434c,
      name: "office-admin-settings-company___en",
      children: [{
        path: "companies",
        component: _0abaeb82,
        name: "office-admin-settings-company-companies___en"
      }, {
        path: "company_groups",
        component: _7167afa2,
        name: "office-admin-settings-company-company_groups___en"
      }]
    }, {
      path: "settings/country",
      component: _105229e5,
      name: "office-admin-settings-country___en",
      children: [{
        path: "countries",
        component: _55f94342,
        name: "office-admin-settings-country-countries___en"
      }, {
        path: "currencies",
        component: _4db2bad4,
        name: "office-admin-settings-country-currencies___en"
      }]
    }, {
      path: "settings/document",
      component: _4c74fa5c,
      name: "office-admin-settings-document___en",
      children: [{
        path: "document_types",
        component: _9006109e,
        name: "office-admin-settings-document-document_types___en"
      }]
    }, {
      path: "settings/employee",
      component: _3d400e6f,
      name: "office-admin-settings-employee___en",
      children: [{
        path: "employees",
        component: _8c58b834,
        name: "office-admin-settings-employee-employees___en"
      }, {
        path: "groups",
        component: _708ac2c3,
        name: "office-admin-settings-employee-groups___en"
      }]
    }, {
      path: "settings/group",
      component: _5b7523e4,
      name: "office-admin-settings-group___en",
      children: [{
        path: "groups",
        component: _a77bd93c,
        name: "office-admin-settings-group-groups___en"
      }]
    }, {
      path: "settings/payment_request",
      component: _689a9eb6,
      name: "office-admin-settings-payment_request___en",
      children: [{
        path: "payment_request_required_attachments",
        component: _43d9dc3e,
        name: "office-admin-settings-payment_request-payment_request_required_attachments___en"
      }, {
        path: "payment_types",
        component: _166a232a,
        name: "office-admin-settings-payment_request-payment_types___en"
      }, {
        path: "steps",
        component: _4eb40812,
        name: "office-admin-settings-payment_request-steps___en"
      }]
    }, {
      path: "settings/person",
      component: _550b0dd6,
      name: "office-admin-settings-person___en",
      children: [{
        path: "genders",
        component: _10c5e328,
        name: "office-admin-settings-person-genders___en"
      }, {
        path: "people",
        component: _211a2336,
        name: "office-admin-settings-person-people___en"
      }]
    }, {
      path: "settings/user",
      component: _7dd0b98c,
      name: "office-admin-settings-user___en",
      children: [{
        path: "roles",
        component: _28487de1,
        name: "office-admin-settings-user-roles___en"
      }, {
        path: "users",
        component: _287ddfac,
        name: "office-admin-settings-user-users___en"
      }]
    }]
  }, {
    path: "/en/office/login",
    component: _136e2a8a,
    name: "office-login___en"
  }, {
    path: "/en/office/logout",
    component: _54212d97,
    name: "office-logout___en"
  }, {
    path: "/en/office/suppliers",
    component: _bd52dd94,
    children: [{
      path: "",
      component: _6fb3cae8,
      name: "office-suppliers___en"
    }]
  }, {
    path: "/",
    component: _73196ac6,
    name: "index___th"
  }, {
    path: "/en/office/:companyCode",
    component: _6b546a0c,
    name: "office-companyCode___en",
    children: [{
      path: "payment_requests",
      component: _1c594fe9,
      children: [{
        path: "",
        component: _08a3fc79,
        name: "office-companyCode-payment_requests___en"
      }, {
        path: "new_payment_request",
        component: _3fc3379e,
        name: "office-companyCode-payment_requests-new_payment_request___en"
      }, {
        path: ":paymentRequestCode",
        component: _17603488,
        name: "office-companyCode-payment_requests-paymentRequestCode___en"
      }]
    }]
  }, {
    path: "/en/:supplierId",
    component: _b8df099e,
    children: [{
      path: "",
      component: _4f6b1031,
      name: "supplierId___en"
    }, {
      path: "new_payment_request",
      component: _7ce16956,
      name: "supplierId-new_payment_request___en"
    }, {
      path: ":paymentRequestCode",
      component: _316e1774,
      name: "supplierId-paymentRequestCode___en"
    }]
  }, {
    path: "/office/:companyCode",
    component: _6b546a0c,
    name: "office-companyCode___th",
    children: [{
      path: "payment_requests",
      component: _1c594fe9,
      children: [{
        path: "",
        component: _08a3fc79,
        name: "office-companyCode-payment_requests___th"
      }, {
        path: "new_payment_request",
        component: _3fc3379e,
        name: "office-companyCode-payment_requests-new_payment_request___th"
      }, {
        path: ":paymentRequestCode",
        component: _17603488,
        name: "office-companyCode-payment_requests-paymentRequestCode___th"
      }]
    }]
  }, {
    path: "/:supplierId",
    component: _b8df099e,
    children: [{
      path: "",
      component: _4f6b1031,
      name: "supplierId___th"
    }, {
      path: "new_payment_request",
      component: _7ce16956,
      name: "supplierId-new_payment_request___th"
    }, {
      path: ":paymentRequestCode",
      component: _316e1774,
      name: "supplierId-paymentRequestCode___th"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
