import { Model } from '@vuex-orm/core';
import Group from './group';
import Sync from './sync';

export default class GroupPermission extends Model {
  static entity = 'group_permission';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      group_id: this.number(null).nullable(),
      sync_entity: this.string(null).nullable(),
      view: this.boolean(),
      create: this.boolean(),
      edit: this.boolean(),
      delete: this.boolean(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      group: this.belongsTo(Group, 'group_id'),
      sync_entity_sync: this.belongsTo(Sync, 'sync_entity'),
    };
  }
}
