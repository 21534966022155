<template>
  <v-card flat color="transparent">
    <v-fade-transition>
      <v-overlay v-if="overlay" opacity="0.2" />
    </v-fade-transition>
    <v-expand-transition>
      <v-card flat color="transparent" :style="overlay ? 'z-index: 99' : ''">
        <v-toolbar height="38px" dark flat :color="color" class="ma-0">
          <v-toolbar-items class="mr-2">
            <v-icon dark>{{ icon }}</v-icon>
          </v-toolbar-items>
          <v-toolbar-title class="overflow-x-hidden">
            <slot name="title" />
            <!-- {{ items[selected].storage_areas[selectedChild].code }} -->
          </v-toolbar-title>
          <v-spacer />
          <slot name="additional" class="ml-2" />
        </v-toolbar>
        <v-card
          flat
          color="white"
          :class="!borderless ? 'px-2 py-3 rounded-t-0' : 'rounded-t-0'"
        >
          <slot />
          <!-- <ChildForm
        :id="selectedChildId"
        :editable="true"
        :model="childModel"
        :dialog.sync="dialog.editChild"
        in-page
      /> -->
        </v-card>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: 'InPageContent',
  props: {
    formType: {
      type: String,
      default: 'view',
    },
    overrideIcon: {
      type: String,
      default: null,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    icon: 'mdi-eye',
    color: 'primary',
  }),
  created() {
    this.setIcon();
    this.setColor();

    /* const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      this.close();

      next(false);
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard();
    }); */
  },
  methods: {
    setIcon() {
      this.icon =
        this.overrideIcon !== null
          ? this.overrideIcon
          : this.formType === 'edit'
          ? 'mdi-pencil'
          : this.formType === 'create'
          ? 'mdi-plus'
          : this.formType === 'delete'
          ? 'mdi-delete'
          : this.formType === 'inventory_check'
          ? 'mdi-store-check'
          : this.formType === 'inventory'
          ? 'mdi-warehouse'
          : 'mdi-eye';
    },
    setColor() {
      this.color =
        this.formType === 'edit'
          ? 'warning darken-2'
          : this.formType === 'create' || this.formType === 'inventory_check'
          ? 'accent'
          : this.formType === 'delete'
          ? 'error darken-2'
          : 'primary';
    },
    close() {
      // eslint-disable-next-line no-console
      // console.log('close dialog');
      this.$emit(`update:dialog`, false);
      // this.dialog = false;
    },
  },
};
</script>
