<template>
  <div>
    <v-container class="pa-0" color="primary">
      <v-fade-transition>
        <Message :message="message" class="no-print mb-3" />
      </v-fade-transition>
      <v-expand-transition>
        <v-row dense :class="message.status !== '' ? 'mt-2' : 'mt-0'">
          <v-col
            v-if="
              id &&
              employee !== null &&
              (inPage === false ||
                (inPage === true && actions.editDialog === false))
            "
            :md="inPage ? 12 : 6"
            class="col-pdf col-12"
          >
            <v-card>
              <ImageGallery
                :form-open.sync="childFormDialog"
                :images="
                  employee && employee.person
                    ? employee.person.person_files
                    : []
                "
                :mini="true"
                entity="person_file"
                model="PersonFile"
                parent-key="person_id"
                :parent-value="id"
              />
              <FullInformationCard
                :item="employee"
                :title-only="
                  (inPage === true || $vuetify.breakpoint.smAndDown) &&
                  actions.editDialog === true
                "
              >
                <template #editDelete>
                  <v-card
                    v-if="
                      closeView !== true &&
                      newRecord !== true &&
                      id &&
                      employee !== null &&
                      $auth.user.permissions[entity] &&
                      ($auth.user.permissions[entity].edit ||
                        $auth.user.permissions[entity].delete) &&
                      ($auth.user.permissions[entity].edit === true ||
                        $auth.user.permissions[entity].delete === true)
                    "
                    flat
                    class="pa-0"
                  >
                    <EditDeleteButtons
                      v-bind.sync="actions"
                      :entity="entity"
                      :deletable="deletable"
                      :delete-items="deleteItems"
                    />
                  </v-card>
                </template>
              </FullInformationCard>
            </v-card>
            <v-select
              v-if="
                employee !== null &&
                closeView !== true &&
                newRecord !== true &&
                inPage === false &&
                Object.keys(panelItems).length !== 0 &&
                actions.editDialog === false
              "
              v-model="panel"
              :items="
                Object.keys(panelItems).map((key) => ({
                  entity: key,
                  label: panelItems[key],
                }))
              "
              item-text="label"
              item-value="entity"
              label="Choose View"
              outlined
              standalone
              hide-details
              class="mt-3"
            />
          </v-col>
          <v-col
            v-if="actions.editDialog && (id === null || inPage === true)"
            :md="inPage || id === null ? 12 : 6"
            class="col-pdf col-12"
          >
            <v-fade-transition>
              <EmployeeForm
                :id.sync="id"
                :parent-key="parentKey"
                :parent-value="parentValue"
                :dialog.sync="actions.editDialog"
                :close-view.sync="closeView"
                :new-record.sync="newRecord"
                :in-page="inPage"
              />
            </v-fade-transition>
          </v-col>
          <v-col
            v-else-if="actions.editDialog"
            :md="inPage ? 12 : 6"
            class="col-pdf col-12"
          >
            <v-card>
              <InPageContent :dialog="true" form-type="edit">
                <template #title> {{ $t('common.edit') }} </template>
                <v-fade-transition>
                  <EmployeeForm
                    :id="id"
                    :parent-key="parentKey"
                    :parent-value="parentValue"
                    :dialog.sync="actions.editDialog"
                    :close-view.sync="closeView"
                    :new-record.sync="newRecord"
                    :in-page="inPage"
                  />
                </v-fade-transition>
              </InPageContent>
            </v-card>
          </v-col>
          <v-col
            v-if="
              employee &&
              employee.companies &&
              panel === 'companies' &&
              !actions.editDialog
            "
            :md="inPage ? 12 : 6"
            class="col-pdf col-12"
          >
            <v-card color="primary">
              <InPageContent :dialog="true" form-type="view" borderless>
                <template #title>
                  {{ panelItems.companies }}
                </template>
                <v-card flat class="mt-2">
                  <CompanyTable
                    :items="employee.companies"
                    :mini="true"
                    parent-key="employee_id"
                    :parent-value="id"
                    :editable="false"
                  >
                    <template #default="{}">
                      <nuxt-child />
                    </template>
                  </CompanyTable>
                </v-card>
              </InPageContent>
            </v-card>
          </v-col>
          <v-col
            v-if="
              employee &&
              employee.groups &&
              panel === 'groups' &&
              !actions.editDialog
            "
            :md="inPage ? 12 : 6"
            class="col-pdf col-12"
          >
            <v-card color="primary">
              <InPageContent :dialog="true" form-type="view" borderless>
                <template #title>
                  {{ panelItems.groups }}
                </template>
                <v-card flat class="mt-2">
                  <GroupTable
                    :items="employee.groups"
                    :mini="true"
                    parent-key="employee_id"
                    :parent-value="id"
                    :editable="false"
                  >
                    <template #default="{}">
                      <nuxt-child />
                    </template>
                  </GroupTable>
                </v-card>
              </InPageContent>
            </v-card>
          </v-col>
          <v-col
            v-if="
              employee &&
              employee.supplier_employee &&
              panel === 'supplier_employee' &&
              !actions.editDialog
            "
            ref="supplier_employee.panel"
            :md="inPage ? 12 : 6"
            class="col-pdf col-12"
          >
            <v-card color="primary">
              <InPageContent :dialog="true" form-type="view" borderless>
                <template #title>
                  {{ panelItems.supplier_employee }}
                </template>
                <v-card flat class="mt-2 pa-2 transparent">
                  <SupplierEmployeeForm
                    v-if="employee.supplier_employee"
                    :id="employee.supplier_employee.id"
                    :in-page="true"
                    :editable="false"
                  />
                </v-card>
              </InPageContent>
            </v-card>
          </v-col>
          <v-col
            v-if="
              employee &&
              employee.user &&
              panel === 'user' &&
              !actions.editDialog
            "
            ref="user.panel"
            :md="inPage ? 12 : 6"
            class="col-pdf col-12"
          >
            <v-card color="primary">
              <InPageContent :dialog="true" form-type="view" borderless>
                <template #title>
                  {{ panelItems.user }}
                </template>
                <v-card flat class="mt-2 pa-2 transparent">
                  <UserForm
                    v-if="employee.user"
                    :id="employee.user.id"
                    :in-page="true"
                  />
                </v-card>
              </InPageContent>
            </v-card>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-container>
    <Dialog
      v-if="actions.deleteDialog === true"
      :dialog.sync="actions.deleteDialog"
      :title="`${$tc('entity.' + entity, 1)}`"
      :name="`delete-${id}`"
      form-type="delete"
      max-width="600px"
    >
      <template #title>
        {{ $tc('entity.' + entity, 1) }}
      </template>
      <ConfirmDeleteForm
        :id="id"
        :entity-name="entity"
        :dialog.sync="actions.deleteDialog"
      />
    </Dialog>
  </div>
</template>

<script>
import Message from '@/components/display/Message';
import CompanyTable from '@/components/table/CompanyTable';
import GroupTable from '@/components/table/GroupTable';
import SupplierEmployeeForm from '@/components/view/SupplierEmployeeView';
import UserForm from '@/components/view/UserView';
import ImageGallery from '@/components/display/ImageGallery';
import Dialog from '@/components/display/Dialog';
import ConfirmDeleteForm from '@/components/form/ConfirmDeleteForm';
import EditDeleteButtons from '@/components/display/EditDeleteButtons';
import InPageContent from '@/components/display/InPageContent';
import FullInformationCard from '@/components/display/FullInformationCard';

export default {
  name: 'EmployeeView',
  components: {
    Message,
    CompanyTable,
    GroupTable,
    SupplierEmployeeForm,
    UserForm,
    ImageGallery,
    Dialog,
    ConfirmDeleteForm,
    EditDeleteButtons,
    InPageContent,
    FullInformationCard,
    EmployeeForm: () => import('@/components/form/EmployeeForm'),
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    parentKey: {
      type: Array,
      default: null,
    },
    parentValue: {
      type: Array,
      default: null,
    },
    inPage: {
      type: Boolean,
      default: false,
    },
  },
  data(app) {
    return {
      entity: 'employee',
      closeView: false,
      newRecord: false,
      panel: '',
      loading: false,
      actions: {
        editDialog: false,
        deleteDialog: false,
      },
      formDialog: {
        Person: false,
      },
      message: {
        text: '',
        status: '',
      },
    };
  },
  computed: {
    Employee() {
      return this.$store.$db().model('employee');
    },
    employee() {
      return this.Employee.query()
        .whereId(this.id)
        .withAll()
        .with(['employee.person', 'person.person_files.file'])
        .first();
    },
    edit() {
      return this.id;
    },
    panelClosed() {
      return this.panel === '' || this.panel === undefined;
    },
    panelItems() {
      const items = {
        companies: '',
        groups: '',
        supplier_employee: '',
        user: '',
      };
      items.companies = `${
        this.employee && this.employee.companies
          ? this.$tc('entity.company', this.employee.companies.length)
          : this.$tc('entity.company', 0)
      } (${
        this.employee &&
        this.employee.companies &&
        this.employee.companies.length > 0
          ? this.employee.companies.length
          : 'No Records'
      })`;
      items.groups = `${
        this.employee && this.employee.groups
          ? this.$tc('entity.group', this.employee.groups.length)
          : this.$tc('entity.group', 0)
      } (${
        this.employee && this.employee.groups && this.employee.groups.length > 0
          ? this.employee.groups.length
          : 'No Records'
      })`;
      items.supplier_employee = `${this.$tc('entity.supplier_employee', 1)} (${
        this.employee &&
        (!this.employee.supplier_employee ||
          this.employee.supplier_employee === null)
          ? this.$t('button.no')
          : this.$t('button.yes')
      })`;
      items.user = `${this.$tc('entity.user', 1)} (${
        this.employee && (!this.employee.user || this.employee.user === null)
          ? this.$t('button.no')
          : this.$t('button.yes')
      })`;

      return items;
    },
    deletable() {
      if (this.deleteItems.length < 1) {
        return true;
      } else {
        return false;
      }
    },
    deleteItems() {
      const items = [];
      if (this.employee.companies && this.employee.companies.length > 0) {
        items.push({
          entity: 'company',
          count: this.employee.companies.length,
        });
      }
      if (this.employee.groups && this.employee.groups.length > 0) {
        items.push({
          entity: 'group',
          count: this.employee.groups.length,
        });
      }
      if (
        this.employee.supplier_employee &&
        this.employee.supplier_employee !== null
      ) {
        items.push({
          entity: 'supplier_employee',
          count: 1,
        });
      }
      if (this.employee.user && this.employee.user !== null) {
        items.push({
          entity: 'user',
          count: 1,
        });
      }

      return items;
    },
  },

  watch: {
    'actions.deleteDialog'(newVal) {
      if (newVal === false && this.employee === null) {
        this.close();
      }
    },
    closeView(newVal) {
      if (newVal === true) {
        this.close();
      }
    },
    employee(newVal) {
      if (newVal === null && this.id !== null) {
        this.message.text = `${this.$tc('entity.employee', 1)} deleted.`;
        this.message.status = 'success';
      }
    },
  },
  created() {
    if (this.editable === true) {
      this.actions.editDialog = true;
    }
  },
  methods: {
    close() {
      if (this.newRecord === true) this.$emit('update:id', this.id);
      this.$emit('update:dialog', false);
    },
  },
};
</script>
