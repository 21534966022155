<template>
  <v-card
    flat
    color="transparent"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <v-fade-transition group hide-on-leave>
      <v-card
        v-if="!dialog.create && !dialog.delete"
        key="list"
        flat
        color="white"
      >
        <ImageCarousel
          :selected="selected"
          :images="images ? images : null"
          class="mb-2"
          @update:selected="(newValue) => (selected = newValue)"
        >
          <v-card
            flat
            color="transparent"
            class="d-flex flex-column"
            width="100%"
            height="100%"
          >
            <v-card-actions v-if="editable" class="mt-auto">
              <v-chip
                v-if="images[selected] && images[selected].is_default"
                fab
                dark
                ripple
                color="success"
                aria-label="Delete"
              >
                <v-icon>mdi-check</v-icon> Default
              </v-chip>
              <v-chip
                v-else
                :loading="loading"
                fab
                dark
                ripple
                color="accent"
                aria-label="Delete"
                @click="
                  selectedId = images[selected].id;
                  updateDefault();
                "
              >
                <v-icon>mdi-image-check</v-icon> Set as default
              </v-chip>
              <v-spacer />
              <v-btn
                fab
                x-small
                dark
                ripple
                color="error"
                aria-label="Delete"
                @click="
                  selectedId = images[selected].id;
                  dialog.delete = true;
                "
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </ImageCarousel>
        <v-expand-transition>
          <v-card
            v-if="editable || (hover && images.length > 1)"
            flat
            color="transparent"
            class="px-2"
          >
            <v-row justify="start" class="pa-2">
              <v-col
                v-for="(image, index) in images"
                :key="image.id"
                cols="2"
                class="pa-0 ma-0"
              >
                <v-card
                  v-if="image.hasOwnProperty('file')"
                  flat
                  :outlined="index === selected ? true : false"
                  :color="index === selected ? 'accent' : ''"
                  ripple
                  class="rounded-0"
                  @click="selected = index"
                >
                  <Thumbnail
                    v-if="image.file && image.file.filePath"
                    :thumb-path="`${image.file.filePath}?updated_at=${image.file.updated_at}`"
                  >
                    <v-card
                      color="transparent"
                      class="d-flex flex-column pa-0 ma-0"
                      width="100%"
                      height="100%"
                    >
                      <v-card-actions v-if="editable" class="mt-auto">
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </Thumbnail>
                  <Thumbnail v-else :thumb-path="`/no_image.jpg`" />
                </v-card>
                <v-card v-else @click="selected = index">
                  <Thumbnail
                    v-if="image.filePath"
                    :thumb-path="`${image.filePath}?updated_at=${image.updated_at}`"
                    @click="selected = index"
                  />
                  <Thumbnail v-else :thumb-path="`/no_image.jpg`" />
                </v-card>
              </v-col>
              <v-col
                v-if="editable"
                cols="3"
                class="pa-0 pl-1 ma-0"
                align-self="center"
              >
                <v-card flat width="100%" height="100%">
                  <v-card-actions class="ma-auto">
                    <v-btn
                      fab
                      dark
                      ripple
                      color="success"
                      aria-label="New"
                      @click="
                        setForm();
                        dialog.create = true;
                      "
                    >
                      <v-icon dark>mdi-image-plus</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
      </v-card>
      <v-card
        v-else
        key="parentForm"
        flat
        :rounded="false"
        color="transparent"
        elevation="12"
        class="mx-2"
      >
        <v-card
          v-if="dialog.edit"
          key="editForm"
          color="warning darken-2"
          class="mt-2"
        >
          <InPageContent :dialog.sync="dialog.edit" overlay form-type="edit">
            <template #title>
              {{
                images[selected] && images[selected].file
                  ? images[selected].file.name
                  : ''
              }}
            </template>
            <Form
              :id="selectedId"
              :editable="true"
              :model="model"
              :dialog.sync="dialog.edit"
              in-page
            />
          </InPageContent>
        </v-card>
        <v-card
          v-else-if="dialog.create"
          key="createForm"
          color="primary"
          class="mt-2"
        >
          <InPageContent
            :dialog.sync="dialog.create"
            overlay
            form-type="create"
          >
            <template #title>
              {{ $tc(`entity.${entity}`, 1) }}
            </template>
            <Form
              :editable="true"
              :model="model"
              :dialog.sync="dialog.create"
              :parent-key="[parentKey]"
              :parent-value="[parentValue]"
              in-page
            />
          </InPageContent>
        </v-card>
        <v-card
          v-else-if="dialog.delete"
          key="deleteForm"
          color="error darken-2"
          class="mt-2"
        >
          <InPageContent
            :dialog.sync="dialog.delete"
            overlay
            form-type="delete"
          >
            <template #title>
              {{
                images[selected] && images[selected].file
                  ? images[selected].file.name
                  : $t('common.deleted')
              }}
            </template>
            <ConfirmDeleteForm
              :id="selectedId"
              :entity-name="entity"
              :dialog.sync="dialog.delete"
            />
          </InPageContent>
        </v-card>
      </v-card>
    </v-fade-transition>
  </v-card>
</template>
<script>
// import PersonImage from '@/models/person_image';
import ConfirmDeleteForm from '@/components/form/ConfirmDeleteForm';
import ImageCarousel from '@/components/display/ImageCarousel';
import Thumbnail from '@/components/display/Thumbnail';
// import Dialog from '@/components/display/Dialog';
import InPageContent from '@/components/display/InPageContent';

export default {
  name: 'ImageGallery',
  components: {
    ConfirmDeleteForm,
    ImageCarousel,
    Thumbnail,
    /* Dialog, */
    InPageContent,
  },
  props: {
    images: {
      type: Array || null,
      required: true,
    },
    entity: {
      type: String,
      default: null,
    },
    model: {
      type: String,
      default: null,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    parentKey: {
      type: String,
      default: null,
    },
    parentValue: {
      type: Number,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    search: '',
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    showSelect: false,
    selected: 0,
    loading: false,
    dialog: {
      create: false,
      edit: false,
      delete: false,
      view: false,
    },
    message: {
      text: '',
      status: '',
    },
    selectedId: null,
    hover: false,
  }),
  computed: {
    ImageModel() {
      return this.$store.$db().model(this.entity);
    },
    dialogClosed() {
      if (
        this.dialog.create === false &&
        this.dialog.edit === false &&
        this.dialog.delete === false &&
        this.dialog.view === false
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    dialogClosed(newVal) {
      if (newVal) {
        // eslint-disable-next-line no-console
        // console.log('Reset Model:' + this.model);
        this.$options.components.Form = () =>
          import(`@/components/view/${this.model}View`);
      }
    },
  },
  methods: {
    setForm() {
      // eslint-disable-next-line no-console
      // console.log('New Form Model:' + this.model);
      this.$options.components.Form = () =>
        import(`@/components/view/${this.model}View`);
    },
    viewItem(event, { item }) {
      // eslint-disable-next-line no-console
      // console.log('ViewItem');
      // eslint-disable-next-line no-console
      // console.log(item);
      this.$router.push(`${this.$route.path}/view/${item.id}`);
    },
    updateDefault() {
      try {
        this.loading = true;
        this.message.text = '';
        this.message.status = '';

        const formData = [];

        this.images.forEach((image, index) => {
          const imageObject = {};
          Object.entries(image).forEach((entry) => {
            const [key, value] = entry;
            if (key.endsWith('_id')) {
              imageObject[key] = value;
            }

            if (key === 'is_default') {
              imageObject[key] = image.id === this.selectedId ? 1 : 0;
            }
          });

          formData.push(imageObject);
        });

        const result = this.ImageModel.api().put(
          `/api/${this.entity}/${this.selectedId}`,
          formData,
          {
            dataKey: 'result',
          }
        );

        this.loading = false;
        if (result.response.status === 200) {
          this.form.success = true;
          this.form.close = true;
          this.message.text = `${this.model} successfully updated.`;
          this.message.status = 'success';
        }
      } catch (error) {
        this.loading = false;
        this.message.text = error.message;
        this.message.status = 'error';
      }
    },
  },
};
</script>
