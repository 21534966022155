var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.overridePermissions === false &&
      (!_vm.$auth.user.permissions[_vm.entity].edit ||
        _vm.$auth.user.permissions[_vm.entity].edit === false)
    )?[_c('v-tooltip',{attrs:{"color":"error","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',_vm._g(_vm._b({staticClass:"d-inline-block",attrs:{"flat":""}},'v-card',attrs,false),on),[_c('v-btn',{staticClass:"pa-0",attrs:{"text":"","small":"","color":_vm.editDialog === false ? 'warning darken-2' : 'error',"disabled":true},on:{"click":function($event){return _vm.toggleEditDialog()}}},[(_vm.editDialog === false)?[_c('v-icon',[_vm._v("mdi-pencil")])]:[_c('v-icon',[_vm._v("mdi-cancel")])]],2)],1)]}}],null,false,416976887)},[_vm._v(" "),_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-lock")]),_vm._v("\n      "+_vm._s(_vm.$t('misc.no_permission_edit', { entity: _vm.$tc(`entity.${_vm.entity}`, 0) }))+"\n    ")],1)]:_c('v-btn',{staticClass:"pa-0",attrs:{"text":"","small":"","color":_vm.editDialog === false ? 'warning darken-2' : 'error'},on:{"click":function($event){return _vm.toggleEditDialog()}}},[(_vm.editDialog === false)?[_c('v-icon',[_vm._v("mdi-pencil")])]:[_c('v-icon',[_vm._v("mdi-cancel")])]],2),_vm._v(" "),_c('v-tooltip',{attrs:{"color":_vm.deletable &&
      _vm.$auth.user.permissions[_vm.entity] &&
      _vm.$auth.user.permissions[_vm.entity].delete
        ? 'success'
        : 'error',"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',_vm._g(_vm._b({staticClass:"d-inline-block",attrs:{"flat":""}},'v-card',attrs,false),on),[_c('v-btn',{staticClass:"pa-0",attrs:{"text":"","small":"","disabled":_vm.editDialog === true ||
            _vm.deletable === false ||
            !_vm.$auth.user.permissions[_vm.entity].delete ||
            (_vm.$auth.user.permissions[_vm.entity].delete &&
              _vm.$auth.user.permissions[_vm.entity].delete === false),"color":"error"},on:{"click":function($event){return _vm.toggleDeleteDialog()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}])},[_vm._v(" "),(
        !_vm.$auth.user.permissions[_vm.entity].delete ||
        _vm.$auth.user.permissions[_vm.entity].delete === false
      )?[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-lock")]),_vm._v("\n      "+_vm._s(_vm.$t('misc.no_permission_delete', {
          entity: _vm.$tc(`entity.${_vm.entity}`, 0),
        }))+"\n    ")]:(_vm.deletable === false)?[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-cancel")]),_vm._v("\n      "+_vm._s(_vm.$t('misc.cannot_delete_has_parents', {
          entity: _vm.$tc(`entity.${_vm.entity}`, 1),
          amount: _vm.deleteItems.length,
        }))+"\n      "),_vm._l((_vm.deleteItems),function(item){return _c('v-card',{key:item.id,staticClass:"pl-8",attrs:{"flat":"","dark":"","color":"transparent"}},[_vm._v("\n        "+_vm._s(_vm.$tc(`entity.${item.entity}`, item.count))+":\n        "+_vm._s(item.count)+"\n      ")])})]:[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-check")]),_vm._v("\n      "+_vm._s(_vm.$t('common.deletable'))+"\n    ")]],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }