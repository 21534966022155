import { Model } from '@vuex-orm/core';
import Address from './address';
import Currency from './currency';
import CountryCurrency from './country_currency';
import Person from './person';

export default class Country extends Model {
  static entity = 'country';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      name: this.string(null).nullable(),
      demonym: this.string(null).nullable(),
      dialling_code: this.string(null).nullable(),
      iso_3166_alpha_2: this.string(null).nullable(),
      iso_3166_alpha_3: this.string(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      currencies: this.belongsToMany(
        Currency,
        CountryCurrency,
        'country_id',
        'currency_id'
      ),
      addresses: this.hasMany(Address, 'country_id'),
      people: this.hasMany(Person, 'country_id'),
    };
  }
}
