<template>
  <v-app>
    <v-navigation-drawer
      v-if="
        $auth.loggedIn &&
        ($auth.hasScope('ROLE_USER') || $auth.hasScope('ROLE_ADMIN'))
      "
      v-model="drawer"
      :mini-variant="miniVariant"
      clipped="clipped"
      color="primary darken-1"
      dark
      app
    >
      <v-list subheader>
        <v-subheader>{{ $tc('entity.payment_request', 0) }}</v-subheader>
        <template v-for="(company, i) in companies">
          <v-list-item
            v-if="$auth.loggedIn && $auth.user.companies[company.code]"
            :key="i"
            :to="'/office/' + company.code + '/payment_requests'"
            nuxt
            exact
          >
            <v-list-item-content>
              <v-list-item-title v-text="company.name" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-divider />
      <v-list v-if="$auth?.user?.groups?.[2]" subheader>
        <v-subheader>{{ $tc('entity.supplier', 0) }}</v-subheader>
        <v-list-item key="suppliers" to="/office/suppliers" nuxt-child exact>
          <v-list-item-action aria-label="supplier-icon">
            <v-icon>mdi-store</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('menu.main.supplier_list')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list v-if="$auth.hasScope('ROLE_ADMIN')" subheader>
        <v-subheader>{{ $tc('menu.main.admin', 0) }}</v-subheader>
        <v-list-item
          key="admin-settings"
          to="/office/admin/settings"
          nuxt-child
          exact
        >
          <v-list-item-action aria-label="admin-settings-icon">
            <v-icon>mdi-database-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('menu.admin.settings')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          key="admin-user-users"
          to="/office/admin/user/users"
          nuxt-child
          exact
        >
          <v-list-item-action aria-label="admin-account-lock-icon">
            <v-icon>mdi-account-lock</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('menu.admin.user_account_settings')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          key="admin-permissions-group_permissions"
          to="/office/admin/permission/group_permissions"
          nuxt-child
          exact
        >
          <v-list-item-action aria-label="admin-account-group-icon">
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('menu.admin.group_permissions')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      fixed
      clipped-left
      clipped-right
      color="primary darken-2"
      dark
      app
    >
      <!-- :bottom="$vuetify.breakpoint.xsOnly ? true : false" -->
      <v-app-bar-nav-icon
        aria-label="nav-btn"
        :disabled="
          !$auth.loggedIn ||
          !($auth.hasScope('ROLE_USER') || $auth.hasScope('ROLE_ADMIN'))
        "
        @click.stop="drawer = !drawer"
      />
      <v-card nuxt to="/" flat class="overflow-x-hidden transparent ml-2">
        <v-toolbar-title class="overflow-x-hidden" v-text="$t('title')" />
        <!-- <v-icon> mdi-menu-down </v-icon> -->
      </v-card>
      <v-spacer />
      <v-card-subtitle
        v-if="
          !$vuetify.breakpoint.xsOnly &&
          $auth.loggedIn &&
          $auth.user.employee.person &&
          $auth.user.employee &&
          $auth.user
        "
        >{{ $auth.user.employee.person.preferred_name }}</v-card-subtitle
      >
      <v-btn
        icon
        aria-label="account-btn"
        @click.stop="rightDrawer = !rightDrawer"
      >
        <v-avatar v-if="$auth.loggedIn" image size="46px" color="white">
          <v-img
            v-if="profile_file && profile_file.file"
            :src="`/file${profile_file.file.path}${profile_file.file.filename}`"
          />
          <v-icon v-else>mdi-account-badge</v-icon>
        </v-avatar>
        <v-avatar v-else image size="36px">
          <v-icon>mdi-account-lock</v-icon>
        </v-avatar>
      </v-btn>
    </v-app-bar>
    <v-main>
      <Content>
        <template #sync>
          <SyncData v-if="$auth.loggedIn" />
        </template>
        <nuxt nuxt-child-key="page" />
      </Content>
    </v-main>

    <v-navigation-drawer
      v-model="rightDrawer"
      right
      :mini-variant="miniVariant"
      clipped="clipped"
      color="primary darken-1"
      dark
      app
    >
      <v-list>
        <v-list-item
          v-if="$auth.loggedIn"
          key="profile"
          nuxt
          exact
          @click="
            rightDrawer = !rightDrawer;
            dialog.editPerson = true;
          "
        >
          <v-list-item-content>
            <v-list-item-title v-text="$tc('entity.person', 1)" />
          </v-list-item-content>
          <v-list-item-action :aria-label="'user_menu.person'">
            <v-icon>mdi-account-box</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          v-if="$auth.loggedIn"
          key="user"
          nuxt
          exact
          @click="
            rightDrawer = !rightDrawer;
            dialog.editUser = true;
          "
        >
          <v-list-item-content>
            <v-list-item-title v-text="$tc('entity.user', 1)" />
          </v-list-item-content>
          <v-list-item-action :aria-label="'user_menu.user'">
            <v-icon>mdi-account-lock</v-icon>
          </v-list-item-action>
        </v-list-item>
        <template v-for="(item, i) in userItems">
          <v-list-item
            v-if="
              $auth.loggedIn &&
              (!item.role ||
                (item.role &&
                  (item.role === $auth.user.role[0] ||
                    item.role === $auth.user.role[1])))
            "
            :key="i"
            :to="item.to"
            nuxt
            exact
          >
            <v-list-item-content>
              <v-list-item-title v-text="$t(item.title)" />
            </v-list-item-content>
            <v-list-item-action :aria-label="item.title">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <Dialog
      v-if="dialog.editPerson === true"
      :dialog.sync="dialog.editPerson"
      :title="`${$tc('entity.person', 1)}`"
      :name="`edit-person-${$auth.user.employee.person_id}`"
      form-type="view"
      :can-download-pdf="false"
      :max-width="$vuetify.breakpoint.mdAndUp ? '800px' : '500px'"
      closable
    >
      <template #title>
        {{ $tc('entity.person', 1) }}
      </template>
      <PersonForm
        :id="$auth.user.employee.person.id"
        :dialog.sync="dialog.editPerson"
        :editable="true"
      />
    </Dialog>
    <Dialog
      v-if="dialog.editUser === true"
      :dialog.sync="dialog.editUser"
      :title="`${$tc('entity.user', 1)}`"
      :name="`edit-user-${$auth.user.id}`"
      form-type="view"
      :can-download-pdf="false"
      :max-width="$vuetify.breakpoint.mdAndUp ? '800px' : '500px'"
      closable
    >
      <template #title>
        {{ $tc('entity.user', 1) }}
      </template>
      <UserForm
        :id="$auth.user.id"
        :dialog.sync="dialog.editUser"
        :editable="true"
      />
    </Dialog>
    <!-- <QrCodeScanner :dialog.sync="dialog.qrCodeScanner" /> -->
  </v-app>
</template>

<script>
import Content from '@/components/display/Content';
import SyncData from '@/components/display/SyncData';
import PersonForm from '@/components/view/PersonView';
import UserForm from '@/components/view/UserView';
import Dialog from '~/components/display/Dialog.vue';
// import QrCodeScanner from '~/components/display/QrCodeScanner.vue';

export default {
  name: 'DefaultLayout',
  components: { Content, SyncData, PersonForm, UserForm, Dialog },
  data() {
    return {
      clipped: false,
      drawer: false,
      fixed: false,
      userItems: [
        {
          icon: 'mdi-logout',
          title: 'button.logout',
          to: '/logout',
        },
      ],
      miniVariant: false,
      rightDrawer: false,
      title: 'PC Palm ERP',
      dialog: {
        editPerson: false,
        editUser: false,
      },
      selectedLocale: '',
      supplierLoggedIn: false,
    };
  },
  computed: {
    Company() {
      return this.$store.$db().model('company');
    },
    /* company() {
      return this.Company.query()
        .whereId(this.form.editId)
        .withAll()
        .with([
          'branches.address.address_type|country',
          'branches.company.company_group|subdivisions',
          'branches.supplier',
          'company_departments.department|company',
          'company_departments.company_department_positions.position',
          'company_departments.company_department_positions.employee_company_department_positions.employee',
        ])
        .first();
    }, */
    companies() {
      return this.Company.query()
        .where('company_group_id', 1)
        .orderBy('name')
        .get();
    },
    profile_file() {
      if (this.$auth && this.$auth.loggedIn) {
        return this.$store
          .$db()
          .model('person_file')
          .query()
          .where('person_id', this.$auth.user.employee.person_id)
          .with('file')
          .orderBy('is_default', 'desc')
          .first();
      } else {
        return false;
      }
    },
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code);
    },
  },
  watch: {
    '$auth.user.employee.companies.length'(newVal) {
      // eslint-disable-next-line no-console
      console.log(`WATCH $auth.user.employee.companies.length: ${newVal}`);
      if (this.$auth.loggedIn === true) {
        this.supplierLoggedIn = newVal < 1;
      }
    },
    '$auth.loggedIn'(newVal) {
      // eslint-disable-next-line no-console
      console.log(`WATCH $auth.loggedIn: ${newVal}`);
      if (newVal === false) {
        if (this.supplierLoggedIn) {
          this.$router.replace(`/login`);
        } else {
          this.$router.replace(`/office/login`);
        }
      }
    },
  },
  created() {
    this.selectedLocale = this.$i18n.locale;
  },
  mounted() {
    if (
      this.$auth.loggedIn === true &&
      this.$auth.user?.employee?.companies?.length < 1
    ) {
      this.supplierLoggedIn = this.$auth.user.employee.companies.length < 1;
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    onLocaleChange(event) {
      this.$i18n.locale = this.selectedLocale;
      // this.$router.replace(this.switchLocalePath(event));
    },
  },
};
</script>
