import { Model } from '@vuex-orm/core';
import GroupPermission from './group_permission';
import EntityCategory from './entity_category';

export default class Sync extends Model {
  static entity = 'sync';

  static primaryKey = 'entity';

  static fields() {
    return {
      // Fields
      entity: this.string(null).nullable(),
      entity_category_id: this.number(null).nullable(),
      count: this.number(null).nullable(),
      last_updated_at: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      entity_category: this.belongsTo(EntityCategory, 'entity_category_id'),
      group_permissions: this.hasMany(GroupPermission, 'sync_entity'),
    };
  }
}
