import { Model } from '@vuex-orm/core';
import Company from './company';

export default class CompanyGroup extends Model {
  static entity = 'company_group';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      name: this.string(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      companies: this.hasMany(Company, 'company_group_id'),
    };
  }
}
