<template>
  <div>
    <v-card>
      <v-btn
        block
        text
        :dark="!disabled"
        height="38px"
        color="accent"
        :disabled="disabled"
        @click="dialog = true"
      >
        <v-icon>mdi-file-document</v-icon>
        <template v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xl">
          {{ $t('button.generate_report') }}
        </template>
        <template v-else>
          {{ $t('button.report') }}
        </template>
      </v-btn>
    </v-card>
    <Dialog
      v-if="dialog === true"
      :dialog.sync="dialog"
      :title="$tc('entity.file', 1)"
      :name="`filePreview`"
      form-type="file-pdf"
      closable
      max-width="800px"
    >
      <template #items>
        <MiniPaginator
          :current-page.sync="currentPage"
          :page-count="pageCount"
          on-toolbar
        />
      </template>
      <!-- <template #email>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click.prevent="emailPdf()">
              <v-icon tile> mdi-email </v-icon>
            </v-btn>
          </template>
          {{ $t('button.email_to', { email }) }}
        </v-tooltip>
      </template> -->
      <template #print>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click.prevent="printPdf()">
              <v-icon tile> mdi-printer </v-icon>
            </v-btn>
          </template>
          {{ $t('button.print') }}
        </v-tooltip>
      </template>
      <template #download>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click.prevent="savePdf()">
              <v-icon tile> mdi-content-save </v-icon>
            </v-btn>
          </template>
          {{ $t('button.download') }}
        </v-tooltip>
      </template>
      <Pdf
        v-if="pdfPreview !== null"
        :src="pdfPreview"
        :page="currentPage"
        transition="fade-transition"
        class="rounded-md"
        @num-pages="pageCount = $event"
        @page-loaded="currentPage = $event"
      />
    </Dialog>
  </div>
</template>

<script>
import { jsPDF as JsPDF } from 'jspdf';
import printJS from 'print-js';
import Pdf from '@teckel/vue-pdf';
import autoTable from 'jspdf-autotable';
import font from '@/static/THSarabunNew-normal.js';
import fontBold from '@/static/THSarabunNew-Bold-normal.js';
import Dialog from '@/components/display/Dialog';
import MiniPaginator from '@/components/display/MiniPaginator';

export default {
  name: 'GeneratePaymentRequestViewerReport',
  components: {
    Pdf,
    Dialog,
    MiniPaginator,
  },
  props: {
    dateRange: {
      type: Array,
      required: true,
    },
    companyCode: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data(app) {
    return {
      doc: null,
      pageX: null,
      pageY: null,
      pageOffsetY: 0,
      loading: false,
      generating: false,
      generated: 0,
      dialog: false,
      message: {
        text: '',
        status: '',
      },
      filename: '',
      pdfPreview: null,
      currentPage: 1,
      pageCount: 0,
    };
  },

  computed: {
    PaymentRequest() {
      return this.$store.$db().model('payment_request');
    },
    payment_requests() {
      if (this.company && this.dateRange.length === 2) {
        return this.PaymentRequest.query()
          .where((paymentRequest) => {
            const createdAt = new Date(paymentRequest.created_at);
            const fromDate = new Date(this.dateRange[0] + ' 00:00:00.000');
            const toDate = new Date(this.dateRange[1] + ' 23:59:59.999');
            return (
              paymentRequest.company_id === this.company.id &&
              createdAt >= fromDate &&
              createdAt <= toDate
            );
          })
          .withAll()
          .with([
            'payment_request_files.document_type|file',
            'supplier.branch.company.address',
            'supplier.company.address.address_type',
            'company.address.address_type',
          ])
          .orderBy('code')
          .get();
      } else if (this.company) {
        return this.PaymentRequest.query()
          .where('company_id', this.company.id)
          .withAll()
          .with([
            'payment_request_files.document_type|file',
            'supplier.branch.company.address',
            'supplier.company.address.address_type',
            'company.address.address_type',
          ])
          .orderBy('code')
          .get();
      } else {
        return null;
      }
    },
    Company() {
      return this.$store.$db().model('company');
    },
    company() {
      return this.Company.query()
        .where('code', this.companyCode)
        .with(['address.address_type'])
        .first();
    },
    paymentRequestsTable() {
      const paymentRequestsArray = [];

      for (const paymentRequest of this.payment_requests) {
        const paymentRequestArray = [];
        // eslint-disable-next-line no-console
        console.log(paymentRequest.created_at);
        // eslint-disable-next-line no-console
        console.log(paymentRequest.payment_due_date);
        paymentRequestArray.push(
          this.$d(new Date(paymentRequest.created_at), 'short', 'th-TH')
        );
        paymentRequestArray.push(paymentRequest.document_code);
        paymentRequestArray.push(paymentRequest.supplier.description);
        let files = '';
        let documentTypes = '';
        for (const [
          i,
          paymentRequestFile,
        ] of paymentRequest.payment_request_files.entries()) {
          files += `${paymentRequestFile.file.filename}`;
          documentTypes += `${paymentRequestFile.document_type.type}`;
          if (i + 1 !== paymentRequest.payment_request_files.length) {
            files += `\n`;
            documentTypes += `\n`;
          }
        }
        paymentRequestArray.push(files);
        paymentRequestArray.push(documentTypes);
        paymentRequestArray.push(
          this.$d(new Date(paymentRequest.payment_due_date), 'short', 'th-TH')
        );
        paymentRequestsArray.push(paymentRequestArray);
      }
      return paymentRequestsArray;
    },
  },

  watch: {
    dialog(newVal) {
      if (newVal === true) {
        this.generatePaymentRequestViewerReport();
      }
    },
  },
  methods: {
    async generatePaymentRequestViewerReport() {
      this.pageOffsetY = 0;
      this.generated = 0;
      const callAddFont = function () {
        this.addFileToVFS('THSarabunNew-normal.ttf', font);
        this.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
      };
      JsPDF.API.events.push(['addFonts', callAddFont]);
      const callAddFontBold = function () {
        this.addFileToVFS('THSarabunNew-Bold-normal.ttf', fontBold);
        this.addFont(
          'THSarabunNew-Bold-normal.ttf',
          'THSarabunNew-Bold',
          'normal'
        );
      };
      JsPDF.API.events.push(['addFonts', callAddFontBold]);

      this.doc = new JsPDF({ putOnlyUsedFonts: true });
      this.doc.setLanguage('th');

      this.pageX = 210;
      this.pageY = 297;

      /* this.doc.text('Hello world! ', 10, 10);

      this.doc.setFont('THSarabunNew-Bold', 'normal', 'normal');
      this.doc.text('สวัสดีชาวโลก! ', 10, 50); */

      /* let newPage = false;
      newPage = await this.addLabels(1, 100, 20, newPage);
      newPage = await this.addLabels(2, 80, 30, newPage);
      await this.addLabels(3, 100, 140, newPage); */
      await this.addTitle();
      await this.addAddress();
      await this.addHeaderTitle();
      await this.addDateRange();
      await this.addTotalPaymentRequests();
      await this.addPaymentRequestTable();

      // this.doc.save('QrCodes.pdf');
      // this.form.fields.generatedPdf = this.doc;
      let filename = `${this.company.code}-${this.$tc(
        'entity.payment_request',
        0
      )}`;
      filename += `-${this.$d(
        new Date(this.dateRange[0]),
        'short',
        'th-TH'
      )}~${this.$d(new Date(this.dateRange[1]), 'short', 'th-TH')}.pdf`;

      this.filename = filename;
      // const string = this.doc.output('datauristring');

      this.doc.setProperties({ title: filename, filename });
      this.pdfPreview = this.doc.output('dataurlstring');
      // const blob = this.doc.output('blob', filename);
      // window.open(URL.createObjectURL(blob), filename);
    },
    addTitle() {
      // size, labelWidth, labelHeight, newPage) {
      const fontSize = 24;

      const paddingX = 20;
      const paddingY = 20;
      const x = paddingX;
      const y = paddingY;

      const labelWidth = this.pageX - paddingX * 2;
      const maxWidth = labelWidth;
      this.doc.setFontSize(fontSize);
      this.doc.setFont('THSarabunNew-Bold', 'normal', 'normal');
      const textOffsetX = labelWidth / 2;
      this.doc.text(this.company.name, x + textOffsetX, y, {
        align: 'center',
        maxWidth,
      });
      const textDimensions = this.doc.getTextDimensions(this.company.name);
      const numberOfLines = Math.ceil(textDimensions.w / labelWidth);
      this.pageOffsetY += textDimensions.h * numberOfLines;
    },
    addAddress() {
      // size, labelWidth, labelHeight, newPage) {
      const fontSize = 16;

      const paddingX = 20;
      const paddingY = 20;
      const x = paddingX;
      const y = paddingY;
      const textOffsetY = 0;
      this.pageOffsetY += textOffsetY;

      const labelWidth = this.pageX - paddingX * 2;
      const maxWidth = labelWidth;
      this.doc.setFontSize(fontSize);
      this.doc.setFont('THSarabunNew-Bold', 'normal', 'normal');
      const textOffsetX = labelWidth / 2;

      this.doc.text(
        this.company.address.fullAddressLine,
        x + textOffsetX,
        y + this.pageOffsetY,
        {
          align: 'center',
          maxWidth,
        }
      );
      /* this.doc.text(
        `this.company.address.fullAddressLine`,
        x + textOffsetX,
        y + this.pageOffsetY,
        {
          align: 'center',
          maxWidth,
        }
      ); */
      const textDimensions = this.doc.getTextDimensions(
        this.company.address.fullAddressLine
      );
      const numberOfLines = Math.ceil(textDimensions.w / labelWidth);
      this.pageOffsetY += textDimensions.h * numberOfLines;
      this.doc.line(
        x,
        y + this.pageOffsetY,
        x + labelWidth,
        y + this.pageOffsetY
      );
    },
    addHeaderTitle() {
      // size, labelWidth, labelHeight, newPage) {
      const fontSize = 24;

      const paddingX = 20;
      const paddingY = 20;
      const x = paddingX;
      const y = paddingY;
      const textOffsetY = 10;
      this.pageOffsetY += textOffsetY;

      const labelWidth = this.pageX - paddingX * 2;
      const maxWidth = labelWidth;
      this.doc.setFontSize(fontSize);
      this.doc.setFont('THSarabunNew-Bold', 'normal', 'normal');
      const textOffsetX = labelWidth / 2;
      this.doc.text(
        this.$t('report.payment_request_viewer.header_title'),
        x + textOffsetX,
        y + this.pageOffsetY,
        {
          align: 'center',
          maxWidth,
        }
      );
      const textDimensions = this.doc.getTextDimensions(
        this.$t('report.payment_request.header_title')
      );
      const numberOfLines = Math.ceil(textDimensions.w / labelWidth);
      this.pageOffsetY += textDimensions.h * numberOfLines;
    },
    addDateRange() {
      // size, labelWidth, labelHeight, newPage) {
      const fontSize = 18;

      const paddingX = 20;
      const paddingY = 20;
      const x = paddingX;
      const y = paddingY;
      const textOffsetY = 3;
      this.pageOffsetY += textOffsetY;

      const labelWidth = this.pageX - paddingX * 2;
      const maxWidth = labelWidth;
      this.doc.setFontSize(fontSize);
      this.doc.setFont('THSarabunNew-Bold', 'normal', 'normal');
      const textOffsetX = labelWidth / 2;
      const text = `${this.$d(
        new Date(this.dateRange[0]),
        'short',
        'th-TH'
      )} ~ ${this.$d(new Date(this.dateRange[1]), 'short', 'th-TH')}`;

      this.doc.text(text, x + textOffsetX, y + this.pageOffsetY, {
        align: 'center',
        maxWidth,
      });
      const textDimensions = this.doc.getTextDimensions(text);
      const numberOfLines = Math.ceil(textDimensions.w / labelWidth);
      this.pageOffsetY += textDimensions.h * numberOfLines;
    },
    addTotalPaymentRequests() {
      // size, labelWidth, labelHeight, newPage) {
      const fontSize = 18;

      const paddingX = 20;
      const paddingY = 20;
      const x = paddingX;
      const y = paddingY;
      const textOffsetY = 3;
      this.pageOffsetY += textOffsetY;

      const labelWidth = this.pageX - paddingX * 2;
      const maxWidth = labelWidth;
      this.doc.setFontSize(fontSize);
      this.doc.setFont('THSarabunNew-Bold', 'normal', 'normal');
      const textOffsetX = labelWidth / 2;
      const text = `${this.$t('misc.total_entity', {
        total: this.payment_requests.length,
        entity: this.$tc(
          'entity.payment_request',
          this.payment_requests.length
        ),
      })}`;

      this.doc.text(text, x + textOffsetX, y + this.pageOffsetY, {
        align: 'center',
        maxWidth,
      });
      const textDimensions = this.doc.getTextDimensions(text);
      const numberOfLines = Math.ceil(textDimensions.w / labelWidth);
      this.pageOffsetY += textDimensions.h * numberOfLines;
    },
    addPaymentRequestTable() {
      // size, labelWidth, labelHeight, newPage) {
      const fontSize = 24;

      const paddingY = 20;
      const y = paddingY;
      /* const paddingY = 20;
      const x = paddingX;
      const y = paddingY;
      const textOffsetY = 15;

      const labelWidth = this.pageX - paddingX * 2;
      const maxWidth = labelWidth; */

      this.doc.setFontSize(fontSize);
      this.doc.setFont('THSarabunNew-Bold', 'normal', 'normal');

      autoTable(this.doc, {
        startY: y + this.pageOffsetY,
        theme: 'striped',
        headStyles: {
          font: 'THSarabunNew-Bold',
          textColor: 255,
        },
        columnStyles: { 4: { overflow: 'ellipsize' } },
        styles: {
          font: 'THSarabunNew',
          fontSize: 16,
          textColor: 20,
          lineColor: 10,
          lineWidth: 0.2,
        },
        head: [
          [
            `${this.$t('data_label.created_at')}`,
            `${this.$t('data_label.document_code')}`,
            `${this.$t('data_label.supplier')}`,
            `${this.$t('data_label.files')}`,
            `${this.$t('data_label.document_type')}`,
            `${this.$t('data_label.payment_due_date')}`,
          ],
        ],
        body: this.paymentRequestsTable,
      });
    },
    savePdf() {
      this.doc.output('save', this.filename);
    },
    printPdf() {
      const pdfBlob = this.doc.output('blob');
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);
      printJS(pdfBlobUrl);
    },
    camelize(text) {
      return text.replace(/^([A-Z])|[\s-_]+(\w)/g, (match, p1, p2, offset) => {
        if (p2) return p2.toUpperCase();
        return p1.toLowerCase();
      });
    },
  },
};
</script>

<style scoped>
.v-label--active {
  max-width: 133% !important;
}
.v-label {
  max-width: 133% !important;
}
</style>
