import { Model } from '@vuex-orm/core';
import PaymentRequest from './payment_request';
import PaymentRequestRequiredAttachment from './payment_request_required_attachment';
import Group from './group';

export default class Step extends Model {
  static entity = 'step';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      step: this.number(null).nullable(),
      status: this.string(null).nullable(),
      instruction: this.string(null).nullable(),
      group_id: this.number(null).nullable(),
      is_approval: this.boolean(),
      is_final: this.boolean(),
      group_id_copy_1: this.boolean(),
      disabled_field: this.string(null).nullable(),
      required_field: this.string(null).nullable(),
      hidden_field: this.string(null).nullable(),
      function: this.string(null).nullable(),
      declined_step: this.number(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      group: this.belongsTo(Group, 'group_id'),
      payment_requests: this.hasMany(PaymentRequest, 'step_id'),
      payment_request_required_attachments: this.hasMany(
        PaymentRequestRequiredAttachment,
        'step_id'
      ),
    };
  }

  get description() {
    return `${this.step} - ${this.status}`;
  }
}
