<template>
  <CrudTable
    :headers="headers"
    :items="items"
    :groups="groups"
    :mini="mini"
    :entity="entity"
    :model="model"
    :parent-key="parentKey"
    :parent-value="parentValue"
    :editable="editable"
  />
</template>

<script>
import CrudTable from '@/components/display/CrudTable.vue';

export default {
  name: 'PaymentRequestTable',
  components: { CrudTable },
  props: {
    items: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    parentKey: {
      type: String,
      default: null,
    },
    parentValue: {
      type: Number,
      default: null,
    },
    canDownloadPdf: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    entity: 'payment_request',
    model: 'PaymentRequest',
  }),
  computed: {
    headers() {
      return {
        small: [
          {
            text: this.$t('data_label.created_at'),
            value: 'created_at',
            align: ' d-none',
            groupable: false,
          },
          {
            text: this.$tc('entity.payment_request', 1),
            value: 'payment_request',
            groupable: false,
          },
          {
            text: this.$t('data_label.document_code'),
            value: 'document_code',
            align: ' d-none',
            groupable: false,
          },
          {
            text: this.$t('data_label.customer'),
            value: 'company.nameWithCompanyGroup',
            align: ' d-none',
            sortable: false,
          },
          {
            text: this.$t('data_label.supplier'),
            value: 'supplier.description',
            align: ' d-none',
            sortable: false,
          },
          {
            text: this.$t('data_label.payment_due_date'),
            value: 'payment_due_date',
            align: ' d-none',
            groupable: false,
          },
          {
            text: this.$t('data_label.status'),
            value: 'step.status',
            align: ' d-none',
            groupable: false,
          },
        ],
        full: [
          {
            text: this.$t('data_label.created_at'),
            value: 'created_at',
            align: ' d-none d-sm-table-cell',
            groupable: false,
          },
          {
            text: this.$tc('entity.payment_request', 1),
            value: 'payment_request',
            groupable: false,
          },
          {
            text: this.$t('data_label.document_code'),
            value: 'document_code',
            align: ' d-none',
            groupable: false,
          },
          {
            text: this.$t('data_label.customer'),
            value: 'company.nameWithCompanyGroup',
            align: ' d-none',
            sortable: false,
          },
          {
            text: this.$t('data_label.supplier'),
            value: 'supplier.description',
            align: ' d-none d-md-table-cell',
            sortable: false,
          },
          {
            text: this.$t('data_label.payment_due_date'),
            value: 'payment_due_date',
            align: ' d-none d-sm-table-cell',
            groupable: false,
          },
          {
            text: this.$t('data_label.status'),
            value: 'step',
            align: ' d-none d-lg-table-cell',
            groupable: false,
          },
          {
            text: '',
            value: 'payment_request_actions',
            align: 'right',
            sortable: false,
            groupable: false,
          },
        ],
      };
    },
    groups() {
      return [
        {
          text: this.$t('data_label.company'),
          value: 'company.name',
        },
        {
          text: this.$t('data_label.payment_type'),
          value: 'payment_type',
        },
        {
          text: this.$t('data_label.step'),
          value: 'step',
        },
        {
          text: this.$t('data_label.supplier'),
          value: 'supplier.name',
        },
        {
          text: this.$t('data_label.supplier_bank_account'),
          value: 'supplier_bank_account',
        },
        {
          text: this.$t('data_label.supplier_employee'),
          value: 'supplier_employee',
        },
      ];
    },
  },
};
</script>
