import { Model } from '@vuex-orm/core';
import Country from './country';
import Currency from './currency';

export default class CountryCurrency extends Model {
  static entity = 'country_currency';

  static primaryKey = ['country_id', 'currency_id'];

  static fields() {
    return {
      // Fields
      country_id: this.number(null).nullable(),
      currency_id: this.number(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      country: this.belongsTo(Country, 'country_id'),
      currency: this.belongsTo(Currency, 'currency_id'),
    };
  }
}
