import { Model } from '@vuex-orm/core';
import Employee from './employee';
import EmployeeGroup from './employee_group';
import GroupPermission from './group_permission';
import Step from './step';

export default class Group extends Model {
  static entity = 'group';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      name: this.string(null).nullable(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      employees: this.belongsToMany(
        Employee,
        EmployeeGroup,
        'group_id',
        'employee_id'
      ),
      group_permissions: this.hasMany(GroupPermission, 'group_id'),
      steps: this.hasMany(Step, 'group_id'),
    };
  }
}
