import { Model } from '@vuex-orm/core';
import DocumentType from './document_type';
import File from './file';
import PaymentRequest from './payment_request';
import PaymentRequestRequiredAttachment from './payment_request_required_attachment';

export default class PaymentRequestFile extends Model {
  static entity = 'payment_request_file';

  static fields() {
    return {
      // Fields
      id: this.number(null).nullable(),
      payment_request_id: this.number(null).nullable(),
      file_id: this.number(null).nullable(),
      document_type_id: this.number(null).nullable(),
      payment_request_required_attachment_id: this.number(null).nullable(),
      code: this.string(null).nullable(),
      attachment_number: this.number(null).nullable(),
      is_checked: this.boolean(),
      is_approved: this.boolean(),
      created_at: this.attr(null),
      updated_at: this.attr(null),

      // Associations
      document_type: this.belongsTo(DocumentType, 'document_type_id'),
      file: this.belongsTo(File, 'file_id'),
      payment_request: this.belongsTo(PaymentRequest, 'payment_request_id'),
      payment_request_required_attachment: this.belongsTo(
        PaymentRequestRequiredAttachment,
        'payment_request_required_attachment_id'
      ),
    };
  }
}
