var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":"","color":"transparent"},on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('v-fade-transition',{attrs:{"group":"","hide-on-leave":""}},[(!_vm.dialog.create && !_vm.dialog.delete)?_c('v-card',{key:"list",attrs:{"flat":"","color":"white"}},[_c('ImageCarousel',{staticClass:"mb-2",attrs:{"selected":_vm.selected,"images":_vm.images ? _vm.images : null},on:{"update:selected":(newValue) => (_vm.selected = newValue)}},[_c('v-card',{staticClass:"d-flex flex-column",attrs:{"flat":"","color":"transparent","width":"100%","height":"100%"}},[(_vm.editable)?_c('v-card-actions',{staticClass:"mt-auto"},[(_vm.images[_vm.selected] && _vm.images[_vm.selected].is_default)?_c('v-chip',{attrs:{"fab":"","dark":"","ripple":"","color":"success","aria-label":"Delete"}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" Default\n            ")],1):_c('v-chip',{attrs:{"loading":_vm.loading,"fab":"","dark":"","ripple":"","color":"accent","aria-label":"Delete"},on:{"click":function($event){_vm.selectedId = _vm.images[_vm.selected].id;
                _vm.updateDefault();}}},[_c('v-icon',[_vm._v("mdi-image-check")]),_vm._v(" Set as default\n            ")],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"fab":"","x-small":"","dark":"","ripple":"","color":"error","aria-label":"Delete"},on:{"click":function($event){_vm.selectedId = _vm.images[_vm.selected].id;
                _vm.dialog.delete = true;}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()],1)],1),_vm._v(" "),_c('v-expand-transition',[(_vm.editable || (_vm.hover && _vm.images.length > 1))?_c('v-card',{staticClass:"px-2",attrs:{"flat":"","color":"transparent"}},[_c('v-row',{staticClass:"pa-2",attrs:{"justify":"start"}},[_vm._l((_vm.images),function(image,index){return _c('v-col',{key:image.id,staticClass:"pa-0 ma-0",attrs:{"cols":"2"}},[(image.hasOwnProperty('file'))?_c('v-card',{staticClass:"rounded-0",attrs:{"flat":"","outlined":index === _vm.selected ? true : false,"color":index === _vm.selected ? 'accent' : '',"ripple":""},on:{"click":function($event){_vm.selected = index}}},[(image.file && image.file.filePath)?_c('Thumbnail',{attrs:{"thumb-path":`${image.file.filePath}?updated_at=${image.file.updated_at}`}},[_c('v-card',{staticClass:"d-flex flex-column pa-0 ma-0",attrs:{"color":"transparent","width":"100%","height":"100%"}},[(_vm.editable)?_c('v-card-actions',{staticClass:"mt-auto"},[_c('v-spacer')],1):_vm._e()],1)],1):_c('Thumbnail',{attrs:{"thumb-path":`/no_image.jpg`}})],1):_c('v-card',{on:{"click":function($event){_vm.selected = index}}},[(image.filePath)?_c('Thumbnail',{attrs:{"thumb-path":`${image.filePath}?updated_at=${image.updated_at}`},on:{"click":function($event){_vm.selected = index}}}):_c('Thumbnail',{attrs:{"thumb-path":`/no_image.jpg`}})],1)],1)}),_vm._v(" "),(_vm.editable)?_c('v-col',{staticClass:"pa-0 pl-1 ma-0",attrs:{"cols":"3","align-self":"center"}},[_c('v-card',{attrs:{"flat":"","width":"100%","height":"100%"}},[_c('v-card-actions',{staticClass:"ma-auto"},[_c('v-btn',{attrs:{"fab":"","dark":"","ripple":"","color":"success","aria-label":"New"},on:{"click":function($event){_vm.setForm();
                      _vm.dialog.create = true;}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-image-plus")])],1)],1)],1)],1):_vm._e()],2)],1):_vm._e()],1)],1):_c('v-card',{key:"parentForm",staticClass:"mx-2",attrs:{"flat":"","rounded":false,"color":"transparent","elevation":"12"}},[(_vm.dialog.edit)?_c('v-card',{key:"editForm",staticClass:"mt-2",attrs:{"color":"warning darken-2"}},[_c('InPageContent',{attrs:{"dialog":_vm.dialog.edit,"overlay":"","form-type":"edit"},on:{"update:dialog":function($event){return _vm.$set(_vm.dialog, "edit", $event)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.images[_vm.selected] && _vm.images[_vm.selected].file
                ? _vm.images[_vm.selected].file.name
                : '')+"\n          ")]},proxy:true}],null,false,3627995719)},[_vm._v(" "),_c('Form',{attrs:{"id":_vm.selectedId,"editable":true,"model":_vm.model,"dialog":_vm.dialog.edit,"in-page":""},on:{"update:dialog":function($event){return _vm.$set(_vm.dialog, "edit", $event)}}})],1)],1):(_vm.dialog.create)?_c('v-card',{key:"createForm",staticClass:"mt-2",attrs:{"color":"primary"}},[_c('InPageContent',{attrs:{"dialog":_vm.dialog.create,"overlay":"","form-type":"create"},on:{"update:dialog":function($event){return _vm.$set(_vm.dialog, "create", $event)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.$tc(`entity.${_vm.entity}`, 1))+"\n          ")]},proxy:true}])},[_vm._v(" "),_c('Form',{attrs:{"editable":true,"model":_vm.model,"dialog":_vm.dialog.create,"parent-key":[_vm.parentKey],"parent-value":[_vm.parentValue],"in-page":""},on:{"update:dialog":function($event){return _vm.$set(_vm.dialog, "create", $event)}}})],1)],1):(_vm.dialog.delete)?_c('v-card',{key:"deleteForm",staticClass:"mt-2",attrs:{"color":"error darken-2"}},[_c('InPageContent',{attrs:{"dialog":_vm.dialog.delete,"overlay":"","form-type":"delete"},on:{"update:dialog":function($event){return _vm.$set(_vm.dialog, "delete", $event)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n            "+_vm._s(_vm.images[_vm.selected] && _vm.images[_vm.selected].file
                ? _vm.images[_vm.selected].file.name
                : _vm.$t('common.deleted'))+"\n          ")]},proxy:true}])},[_vm._v(" "),_c('ConfirmDeleteForm',{attrs:{"id":_vm.selectedId,"entity-name":_vm.entity,"dialog":_vm.dialog.delete},on:{"update:dialog":function($event){return _vm.$set(_vm.dialog, "delete", $event)}}})],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }