<template>
  <v-dialog
    :value="dialog"
    rounded
    :content-class="
      `dialog-${title} ` +
      [
        $vuetify.breakpoint.xsOnly ? 'rounded-b-0 xs-dialog' : 'dialog',
      ].toString()
    "
    style="overflow: hidden"
    :max-width="maxWidth"
    persistent
    transition="dialog-bottom-transition"
    mode="out"
    @input="$emit('input')"
  >
    <!-- <template #activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <v-btn small icon color="error darken-2" v-bind="attrs" v-on="on">
          <v-icon small aria-label="Delete"> mdi-delete </v-icon>
        </v-btn>
      </slot>
    </template> -->
    <v-scale-transition>
      <v-card
        :id="`download-progress-${title}`"
        :height="$vuetify.breakpoint.height"
        style="display: none; position: absolute; z-index: 999"
        flat
      >
        <v-img src="/downloading.gif" height="600px" />
      </v-card>
    </v-scale-transition>
    <Page
      :page-name="title"
      :color="color"
      is-dialog
      :can-download-pdf="canDownloadPdf"
      :closable="closable"
      :form-type="formType"
    >
      <template #title>
        <slot name="title" />
      </template>
      <template #items>
        <slot name="items" />
      </template>
      <template #download>
        <slot name="download" />
      </template>
      <template #email>
        <slot name="email" />
      </template>
      <template #print>
        <slot name="print" />
      </template>
      <template v-if="closable" #close>
        <v-btn icon @click.prevent="close()">
          <v-icon tile class="no-print"> mdi-close </v-icon>
        </v-btn>
      </template>
      <v-card
        flat
        rounded
        :class="[$vuetify.breakpoint.xsOnly ? 'rounded-b-0' : ''].toString()"
      >
        <slot />
      </v-card>
    </Page>
  </v-dialog>
</template>

<script>
import Page from '@/components/display/Page';

export default {
  name: 'Dialog',
  components: { Page },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    formType: {
      type: String,
      default: 'view',
    },
    canDownloadPdf: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    maxWidth: {
      type: String,
      default: '500px',
    },
    closable: {
      type: Boolean,
      default: false,
    },
  },
  /* created() {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      this.close();

      next(false);
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard();
    });
  }, */
  methods: {
    close() {
      // eslint-disable-next-line no-console
      // console.log('close dialog');
      this.$emit(`update:dialog`, false);
      // this.dialog = false;
    },
  },
};
</script>

<style>
.xs-dialog.v-dialog {
  margin: 0;
  position: absolute;
  bottom: 0;
  max-height: 100% !important;
  overflow: hidden;
}

.dialog.v-dialog {
  position: absolute;
  max-height: 100% !important;
  overflow: hidden;
}

.loader {
  margin: auto;
  border: 20px solid #eaf0f6;
  border-radius: 50%;
  border-top: 20px solid #ff7a59;
  width: 200px;
  height: 200px;
  animation: spinner 4s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
