<template>
  <v-card flat class="rounded-b-0">
    <v-carousel
      v-if="images && images.length > 0"
      :value="selected"
      show-arrows-on-hover
      :show-arrows="images.length > 1 ? true : false"
      hide-delimiters
      hide-delimiter-background
      height="200px"
      @change="$emit('update:selected', $event)"
    >
      <template v-for="(image, i) in images">
        <v-carousel-item
          v-if="image.file && image.file.filePath"
          :key="i"
          :src="`/file${image.file.filePath}`"
          aspect-ratio="1"
          contain
        >
          <slot />
        </v-carousel-item>
        <v-carousel-item
          v-else
          :key="i"
          :src="`/no_image.jpg`"
          aspect-ratio="1"
        >
          <slot />
        </v-carousel-item>
      </template>
    </v-carousel>
    <v-img
      v-else
      src="/no_image.jpg"
      width="100%"
      height="200px"
      aspect-ratio="1"
    />
  </v-card>
</template>
<script>
export default {
  name: 'ImageCarousel',
  props: {
    images: {
      type: Array || null,
      default: null,
    },
    selected: {
      type: Number,
      default: null,
    },
  },
  emits: ['update:selected'],

  data: () => ({
    dialog: false,
    index: 1,
  }),

  watch: {
    selected() {
      this.index = this.selected;
    },
  },
};
</script>
